import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './styles/index.css'
import App from './App.connect'
import Amplify from 'aws-amplify'
import * as serviceWorker from './serviceWorker'
import { store } from './stores'

if (process.env.REACT_APP_IS_PRODUCTION === '0') {
  console.log('env', process.env.REACT_APP_ENV_NAME)
}

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_URL,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET_UPLOAD,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
