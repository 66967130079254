import 'date-fns'
import React, { useState, useEffect, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, Select } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { SelectOption, TeamMember, TeamMemberRole } from '@/stores/graphql'
import { useTeamMember, useTeamMemberManage } from '@/hooks/teamsMembers'
import { getTeamMemberRoleLabel } from '@/utils/labels'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'

type Inputs = {
  id: string
  fullName: string
  username: string
  email?: string
  organization: string
  position: string
  role: SelectOption[]
}

export const GeneralTeamsMembersEdit: React.FC = () => {
  const { teamMemberId, teamId } = useParams<{ teamMemberId?: string; teamId: string }>()

  const { team } = useTeam(teamId)

  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const [record, setRecord] = useState<TeamMember | undefined | 'create'>(undefined)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { register, handleSubmit, errors, control, setValue } = useForm<Inputs>()

  const { teamMember, loading } = useTeamMember(teamMemberId)
  const {
    updateTeamMember,
    createTeamMember,
    errors: teamMemberManageErrors,
  } = useTeamMemberManage()

  useEffect(() => {
    setRecord(teamMember)
  }, [teamMember])

  React.useEffect(() => {
    setValue('role', getTeamMemberRoleOptions(get(record, 'role') || []))
  }, [teamMember, setValue, record])

  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)

      if (!teamMemberId) {
        const [created] = await createTeamMember({
          teamId,
          ...data,
          email: data.email || '',
          role: data.role.map((r) => r.value as TeamMemberRole),
        })

        if (created) {
          openSnackbar({ type: 'success', message: '作成しました' })
          history.push(replacePathParams(Paths.TeamMembers, { teamId }))
        }
      } else {
        delete data.email

        const updated = await updateTeamMember({
          ...data,
          role: data.role.map((r) => r.value as TeamMemberRole),
        })
        if (updated) {
          openSnackbar({ type: 'success', message: '更新しました' })
          history.goBack()
        }
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  React.useEffect(() => {
    if (teamMemberManageErrors && teamMemberManageErrors.length > 0) {
      const message = get(teamMemberManageErrors, '[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    }
  }, [teamMemberManageErrors, openSnackbar])

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {teamMemberId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="氏名"
            name="fullName"
            defaultValue={get(record, 'fullName')}
            inputRef={register({
              required: ERRORS.inputRequired('氏名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.fullName && errors.fullName.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="ユーザー名"
            name="username"
            defaultValue={get(record, 'username')}
            inputRef={register({
              required: ERRORS.inputRequired('ユーザー名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.username && errors.username.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            required
            readOnly={teamMemberId ? true : false}
            label="メールアドレス"
            name="email"
            defaultValue={get(record, 'email')}
            inputRef={register({
              required: true,
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ERRORS.inputMail,
              },
            })}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="組織"
            name="organization"
            defaultValue={get(record, 'organization')}
            inputRef={register({})}
            error={errors.organization && errors.organization.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="役職"
            name="position"
            defaultValue={get(record, 'position')}
            inputRef={register({})}
            error={errors.position && errors.position.message}
          />
        </div>

        <div className="mt-6">
          <Controller
            name="role"
            control={control}
            render={({ onChange, name, value }) => (
              <>
                <Select
                  label="権限"
                  name={name}
                  value={value}
                  options={Object.entries(TeamMemberRole).map(([, value]) => ({
                    value,
                    label: getTeamMemberRoleLabel(value),
                  }))}
                  onChange={onChange}
                  isMulti={true}
                />
              </>
            )}
          />
        </div>

        {/* 
        <div className="mt-6">
          <NativeSelect
            label="メールアドレス"
            readOnly
            name="email"
            defaultValue={get(record, 'email')}
            options={Object.entries(TeamStatusUsage).map(([, value]) => ({
              value,
              label: getTeamStatusUsageLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div> */}

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              handleClick={() => history.goBack()}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!teamMemberId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          title={
            <>
              <Link
                className="text-primary underline"
                to={replacePathParams(Paths.TeamMembers, { teamId: team.id })}
              >
                {team.name}
              </Link>
              <span> / チームメンバー{!teamMemberId ? '作成' : '編集'}</span>
            </>
          }
        >
          {loading ? <RenderProgress /> : renderForm()}
        </Panel>
      )}
    </div>
  )
}

const getTeamMemberRoleOptions = (roles: TeamMemberRole[]) => {
  const result: SelectOption[] = []

  roles.sort().forEach((role) => {
    result.push({ value: role, label: getTeamMemberRoleLabel(role) })
  })

  return result
}
