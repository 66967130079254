import React from 'react'
import {
  TeamBuildingReview,
  TeamBuildingReviewMember,
  TeamBuildingReviewSummary,
} from '@/stores/graphql'
import {
  queryGetTeamBuildingReview,
  queryGetTeamBuildingReviewMembers,
  queryGetTeamBuildingReviews,
  queryGetTeamBuildingReviewSummary,
} from './graphql'

export const useTeamBuildingsReviewSummary = (teamId?: string) => {
  const [item, setItem] = React.useState<TeamBuildingReviewSummary | undefined>()
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoaded(false)

    if (!teamId) {
      setLoaded(true)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReviewSummary({ teamId })
      setItem(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    } finally {
      setLoaded(true)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { item, loaded, loading, error, refresh } as const
}

export const useTeamsBuildingReviews = (teamId?: string) => {
  const limit = 1000
  const [items, setItems] = React.useState<TeamBuildingReview[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReviews({ teamId, limit })
      if (response) {
        setItems(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!teamId || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReviews({ teamId, limit, nextToken })
      if (response) {
        setItems([...items, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, items, nextToken])

  return { items, nextToken, loading, error, refresh, more } as const
}

export const useTeamBuildingsReview = (id?: string) => {
  const [item, setItem] = React.useState<TeamBuildingReview | undefined>()
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoaded(false)

    if (!id) {
      setLoaded(true)
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReview({ id })
      setItem(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    } finally {
      setLoaded(true)
    }
    setLoading(false)
  }, [id])

  React.useEffect(() => {
    refresh()
  }, [id, refresh])

  return { item, loaded, loading, error, refresh } as const
}

export const useTeamsBuildingReviewMembers = (teamBuildingReviewId?: string) => {
  const limit = 1000
  const [items, setItems] = React.useState<TeamBuildingReviewMember[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamBuildingReviewId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReviewMembers({ teamBuildingReviewId, limit })
      if (response) {
        setItems(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamBuildingReviewId])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!teamBuildingReviewId || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingReviewMembers({
        teamBuildingReviewId,
        limit,
        nextToken,
      })
      if (response) {
        setItems([...items, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamBuildingReviewId, items, nextToken])

  return { items, nextToken, loading, error, refresh, more } as const
}
