import React, { useState, useMemo, useEffect, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { IAdmin } from '@/stores/admins/interface'
import { SnackbarContext } from '@/components/Snackbar'
import { createAdmin } from '@/stores/admins/api'
import { Panel, InputText, Button } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'

type Inputs = {
  email: string
  temporaryPassword: string
}

export const ManageAdminEdit: React.FC = () => {
  const { adminId } = useParams<{ adminId: string }>()
  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const [record, setRecord] = useState<IAdmin | null | 'create'>(null)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const isFetching = useMemo(() => record === null, [record])
  const isCreate = useMemo(() => record === 'create', [record])

  const fetchAdmin = async (id: string) => {
    console.log('id: ', id)
    // const fetched = await getAdmin({ id: adminId })
    // setRecord(fetched.data)
  }

  useEffect(() => {
    if (adminId) {
      fetchAdmin(adminId)
    } else {
      setRecord('create')
    }
  }, [adminId])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: any) => {
    console.log(data)
    setIsProcessing(true)

    if (isCreate) {
      try {
        const input = {
          email: data.email,
          temporaryPassword: data.temporaryPassword,
        }
        await createAdmin({ input })
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: 'メールアドレスに認証メールを送信しました' })
        history.push(Paths.Admins)
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* {!isCreate && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )} */}

        <div className="mt-6">
          <InputText
            readOnly={!isCreate}
            label="メールアドレス"
            name="email"
            defaultValue={get(record, 'email')}
            inputRef={register({
              required: true,
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ERRORS.inputMail,
              },
            })}
            error={errors.email && errors.email.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            required
            label="仮パスワード"
            name="temporaryPassword"
            defaultValue={''}
            inputRef={register({ required: ERRORS.inputRequired('仮パスワード') })}
            error={errors.temporaryPassword && errors.temporaryPassword.message}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.Admins}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {isCreate ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      <Panel title={`管理 - ${isCreate ? '管理者作成' : '管理者編集'}`}>
        {isFetching ? renderProgress() : renderForm()}
      </Panel>
    </div>
  )
}
