import React from 'react'
import {
  CreateTeamMemberInput,
  TeamMember,
  TeamMemberFilter,
  UpdateTeamMemberInput,
} from '@/stores/graphql'
import {
  mutationCreateTeamMember,
  mutationReSendInvitationEmail,
  mutationUpdateTeamMember,
  queryGetTeamMember,
  queryGetTeamMembers,
} from './graphql'

export const useTeamsMembers = (teamId?: string, filter?: TeamMemberFilter) => {
  const limit = 1000
  const [teamMembers, setTeamMembers] = React.useState<TeamMember[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMembers({ teamId, filter, limit })
      if (response) {
        setTeamMembers(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, filter])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!teamId || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMembers({ teamId, filter, limit, nextToken })
      if (response) {
        setTeamMembers([...teamMembers, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, teamMembers, filter, nextToken])

  return { teamMembers, nextToken, loading, error, refresh, more } as const
}

export const useTeamMember = (teamMemberId?: string) => {
  const [teamMember, setTeamMember] = React.useState<TeamMember | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamMemberId) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamMember({ id: teamMemberId })
      setTeamMember(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamMemberId])

  React.useEffect(() => {
    refresh()
  }, [teamMemberId, refresh])

  return { teamMember, loading, error, refresh } as const
}

export const useTeamMemberManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createTeamMember = React.useCallback(async (input: CreateTeamMemberInput): Promise<
    [TeamMember | undefined, undefined | Error[]]
  > => {
    setLoading(true)
    try {
      const response = await mutationCreateTeamMember({ input })
      return [response, undefined]
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)
      return [undefined, e.errors]
    } finally {
      setLoading(false)
    }
  }, [])

  const updateTeamMember = React.useCallback(async (input: UpdateTeamMemberInput) => {
    setLoading(true)
    try {
      const response = await mutationUpdateTeamMember({ input })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  const reSendInvitation = React.useCallback(async (id: string) => {
    setLoading(true)
    try {
      const response = await mutationReSendInvitationEmail({ id })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    createTeamMember,
    updateTeamMember,
    reSendInvitation,
    errors,
    loading,
  } as const
}
