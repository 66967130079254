import React, { useEffect, useState, useContext } from 'react'
import { SnackbarContext } from '@/components/Snackbar'
import { useParams } from 'react-router-dom'
import { getOnboardingTeam } from '@/stores/teams/api'
import { OnboardingTeam, OnboardingTeamMember } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { get } from 'lodash-es'
import { replacePathParams } from '@/utils/history'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { useOnboardingTeamMemberList } from './hook'
import { getOnboardingTeamMemberRoleColor, getOnboardingTeamMemberRoleLabel } from '@/utils/labels'
import { mutationDeleteOnboardingTeamMember } from './api'
import { format } from 'date-fns'
import { Batch } from '@/components/atoms'

interface ITeamState {
  data: OnboardingTeam | null
  fetching: boolean
}

export const OnboardingTeamsUsers: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  // const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const { items, loading, nextToken, more, refresh } = useOnboardingTeamMemberList(teamId)

  const [team, setTeam] = useState<ITeamState>({
    data: null,
    fetching: false,
  })
  const getTeam = async (id: string) => {
    setTeam({ data: null, fetching: true })
    const { data: team } = await getOnboardingTeam({ id })
    setTeam({ data: team, fetching: false })
  }

  useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])

  const handleDelete = async (id: string) => {
    try {
      await mutationDeleteOnboardingTeamMember({ teamId, userId: id.replace(`${teamId}-`, '') })
      refresh()
      openSnackbar({ type: 'success', message: '削除しました' })
    } catch (e) {
      openSnackbar({ type: 'error', message: get(e, 'errors[0].message', 'エラーが発生しました') })
    }
  }

  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          <Link to={Paths.OnboardingTeams} className="hover:underline">
            オンボーディングチーム一覧
          </Link>
          {team.data?.title && (
            <Link
              to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId: team.data.id })}
              className="hover:underline"
            >
              {team.data.title}
            </Link>
          )}
          <Typography color="primary">ユーザー一覧</Typography>
        </Breadcrumbs>
      </div>

      <IndexLayout
        fetching={loading}
        title={`ユーザー一覧`}
        data={items}
        columns={COLUMNS}
        createPath={replacePathParams(Paths.OnboardingTeamsUsersNew, { teamId })}
        csvImportPath={replacePathParams(Paths.OnboardingTeamsUsersCsv, { teamId })}
        csvExport={{
          fileName: `${format(new Date(), 'yyyy-MM-dd')}-onboarding-user-list`,
          columns: [
            { header: 'ユーザーID', key: 'id' },
            { header: 'メールアドレス', key: 'email' },
            { header: '種別', key: 'role' },
            { header: '参加日', key: 'startedAt' },
          ],
          data: items.reduce((acc: OnboardingTeamMember[], cur) => {
            if (get(cur, 'user.email')) {
              return [
                ...acc,
                {
                  ...cur,
                  email: cur?.user?.email,
                },
              ]
            } else {
              return [...acc, cur]
            }
          }, []),
        }}
        editPath={(id: string) =>
          replacePathParams(Paths.OnboardingTeamsUsersEdit, {
            teamId,
            userId: id.replace(`${teamId}-`, ''),
          })
        }
        handleDelete={handleDelete}
        onClickReadMore={nextToken ? more : undefined}
        // filters={
        //   <div>
        //     <div className="flex px-6 mb-4">
        //       <div className="whitespace-no-wrap">
        //         <TypeFilter
        //           name="分類"
        //           field="category"
        //           types={ONBOARDING_ACTION_CATEGORIES}
        //           handleOnSubmit={onApplyCategoryFilter}
        //         />
        //       </div>
        //     </div>
        //   </div>
        // }
      />
    </>
  )
}

const COLUMNS = [
  {
    label: 'ユーザーID',
    field: 'id',
  },
  {
    label: 'メールアドレス',
    field: (record: OnboardingTeamMember) => get(record, 'user.email') ?? get(record, 'email'),
  },
  {
    label: '役割',
    // eslint-disable-next-line react/display-name
    field: (record: OnboardingTeamMember) => (
      <Batch
        title={getOnboardingTeamMemberRoleLabel(record.role)}
        backgroundColor={getOnboardingTeamMemberRoleColor(record.role)}
      />
    ),
  },
  {
    label: '開始日',
    field: (record: OnboardingTeamMember) =>
      record?.startedAt ? format(new Date(record.startedAt ?? ''), 'yyyy-MM-dd') : '',
  },

  {
    label: '登録状況',
    field: (record: OnboardingTeamMember) => (!get(record, 'user.email') ? '未' : ''),
  },
]
