/** @jsx jsx */

import React, { useState } from 'react'
import { css, Global, jsx } from '@emotion/core'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import { FormItemLayout } from '@/components/atoms'
import { COLORS } from '@/constants'
import moment from 'moment'

interface IProps {
  name: string
  label: string
  required?: boolean
  error?: null | string
  defaultValue?: moment.Moment | null
  onChangeHandler(value: string): void
}

const DatePicker: React.FC<IProps> = ({
  name,
  label,
  error = null,
  required = false,
  defaultValue = null,
  onChangeHandler = () => {},
}) => {
  const [focused, setFocused] = useState(false)
  const [date, setDate] = useState<null | moment.Moment>(defaultValue)

  const onDateChange = (date: moment.Moment | null) => {
    setDate(date)

    if (moment.isMoment(date)) {
      onChangeHandler(date.format('YYYY/MM/DD'))
    } else {
      onChangeHandler('')
    }
  }

  const renderDayContents = (day: moment.Moment) => {
    return (
      <span className="relative z-10 flex items-center justify-center h-full">{day.date()}</span>
    )
  }

  return (
    <FormItemLayout label={label} required={required} error={error}>
      <Global styles={css(globalStyle)} />
      <SingleDatePicker
        noBorder
        id={name}
        date={date}
        focused={focused}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        isOutsideRange={(day: moment.Moment) => false}
        onDateChange={onDateChange}
        renderDayContents={renderDayContents}
        onFocusChange={({ focused }) => setFocused(focused)}
      />
    </FormItemLayout>
  )
}

const globalStyle = `
  .CalendarDay.CalendarDay__default {
    position: relative;
    border-color: transparent;
  }

  .CalendarDay.CalendarDay__default::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${COLORS['primary-light']};
    border-radius: 50%;
    z-index: 2;
  }

  .CalendarDay.CalendarDay__default:not(.CalendarDay__selected):hover {
    background-color: transparent;
  }

  .CalendarDay.CalendarDay__default:not(.CalendarDay__selected):hover::after {
    display: block;
  }

  .CalendarDay.CalendarDay__selected {
    color: #fff;
    position: relative;
    background-color: transparent;
  }

  .CalendarDay.CalendarDay__selected::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${COLORS.primary};
    border-radius: 50%;
    z-index: 2;
  }

  .CalendarDay.CalendarDay__selected::before {
    right: 0;
  }

  .DateInput_input.DateInput_input_1 {
    padding: .5rem .75rem;
    background-color: #F7F7F9;
    border-radius: .25rem;
    font-size: 1rem;
    font-weight: normal;
    border: solid 2px transparent !important;
  }

  .DateInput_input.DateInput_input__focused {
    background-color: #fff;
    border: solid 2px ${COLORS.primary} !important;
  }
`

export default DatePicker
