import { Dispatch } from 'redux'
import { Actions as UserActions } from './stores/user/actions'
import { SignInActionInput, SignOutActionInput } from './stores/user/interface'
import { RootState } from './stores/reducers'
import { connect, ConnectedProps } from 'react-redux'
import App from './App'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (payload: SignInActionInput) => dispatch(UserActions.signIn.started(payload)),
  signOut: (payload: SignOutActionInput) => dispatch(UserActions.signOut.started(payload)),
  getCurrentUser: () => dispatch(UserActions.getCurrentUser.started({})),
})

const mapStateToProps = (state: RootState) => ({
  user: state.user,
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ContainerProps = ConnectedProps<typeof connector>
export default connect(mapStateToProps, mapDispatchToProps)(App)
