import * as React from 'react'

function LogoComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 2"
      xmlns="http://www.w3.org/2000/svg"
      // width={237.669}
      // height={29.62}
      viewBox="0 0 237.669 29.62"
      {...props}
    >
      <g data-name="\u30EC\u30A4\u30E4\u30FC 1">
        <path
          data-name="Path 115"
          d="M69.498 29.463c-8.145 0-15.16-5.526-15.16-14.536S61.353.392 69.498.392a17.416 17.416 0 019.675 2.709.37.37 0 01.165.317v4.141a.386.386 0 01-.217.346.4.4 0 01-.411-.037 13.79 13.79 0 00-8.632-2.925c-5.6 0-9.746 3.715-9.746 9.984s4.143 9.984 9.746 9.984a13.79 13.79 0 008.631-2.926.4.4 0 01.411-.037.386.386 0 01.217.346v4.133a.378.378 0 01-.165.317 17.356 17.356 0 01-9.674 2.719z"
          fill="#1e1717"
        />
        <path
          data-name="Path 116"
          d="M93.399 29.604c-6.591 0-11.441-4.755-11.441-10.923s4.85-10.922 11.441-10.922 11.393 4.8 11.393 10.923-4.848 10.922-11.393 10.922zm0-17.843c-3.672 0-6.073 2.825-6.073 6.921s2.448 6.921 6.073 6.921 6.066-2.778 6.066-6.921-2.44-6.921-6.066-6.921z"
          fill="#1e1717"
        />
        <path
          data-name="Path 117"
          d="M117.922 29.416a10.4 10.4 0 01-10.734-10.781 10.323 10.323 0 0110.734-10.687 12.555 12.555 0 017.117 1.907.377.377 0 01.18.33v3.225a.384.384 0 01-.612.314 9.322 9.322 0 00-5.493-1.632 6.23 6.23 0 00-6.45 6.544c0 4 2.684 6.638 6.45 6.638a9.11 9.11 0 005.493-1.656.392.392 0 01.62.314v3.248a.392.392 0 01-.173.33 12.256 12.256 0 01-7.133 1.907z"
          fill="#1e1717"
        />
        <path
          data-name="Path 118"
          d="M139.057 29.604c-6.591 0-11.441-4.755-11.441-10.923s4.849-10.922 11.441-10.922 11.393 4.8 11.393 10.923-4.849 10.922-11.393 10.922zm0-17.843c-3.672 0-6.073 2.825-6.073 6.921s2.448 6.921 6.073 6.921 6.026-2.778 6.026-6.921-2.4-6.921-6.026-6.921z"
          fill="#1e1717"
        />
        <path
          data-name="Path 119"
          d="M153.993 28.464V1.391a.39.39 0 01.392-.387h4.708a.39.39 0 01.392.387v27.073a.39.39 0 01-.392.387h-4.708a.39.39 0 01-.392-.387z"
          fill="#1e1717"
        />
        <path
          data-name="Path 120"
          d="M180.43 28.483l-.1-1.852a.392.392 0 00-.683-.228 9.416 9.416 0 01-6.96 3.013c-5.493 0-9.557-4.614-9.557-10.734s4.049-10.734 9.557-10.734a9.416 9.416 0 016.96 3.013.392.392 0 00.683-.235l.1-1.844a.4.4 0 01.392-.369h4.182a.392.392 0 01.392.392v19.554a.392.392 0 01-.392.392h-4.182a.4.4 0 01-.392-.369zm-6.05-16.475c-3.2 0-5.791 2.5-5.791 6.685s2.589 6.685 5.791 6.685 5.791-2.5 5.791-6.685S177.582 12 174.38 12z"
          fill="#1e1717"
        />
        <path
          data-name="Path 121"
          d="M202.883 29.416a9.479 9.479 0 01-6.96-2.971.4.4 0 00-.416-.108.388.388 0 00-.267.333l-.1 1.826a.4.4 0 01-.392.364h-4.182a.39.39 0 01-.392-.387V1.391a.39.39 0 01.392-.387h4.708a.39.39 0 01.392.387v9.146a.379.379 0 00.242.358.388.388 0 00.425-.095 9.489 9.489 0 016.583-2.553c5.493 0 9.557 4.55 9.557 10.585s-4.08 10.584-9.59 10.584zm-1.718-17.177c-3.2 0-5.791 2.461-5.791 6.592s2.589 6.592 5.791 6.592 5.791-2.461 5.791-6.592-2.565-6.592-5.79-6.592z"
          fill="#1e1717"
        />
        <path
          data-name="Path 122"
          d="M226.276 29.604c-6.591 0-11.441-4.755-11.441-10.923s4.857-10.922 11.441-10.922 11.393 4.8 11.393 10.923-4.841 10.922-11.393 10.922zm0-17.843c-3.672 0-6.073 2.825-6.073 6.921s2.448 6.921 6.073 6.921 6.026-2.778 6.026-6.921-2.393-6.923-6.026-6.923z"
          fill="#1e1717"
        />
        <path
          data-name="Path 123"
          d="M31.23 15.535a14.909 14.909 0 01-6.739 11.426 15.356 15.356 0 01-4.049-3.923 9.628 9.628 0 005.367-7.588.439.439 0 01.439-.408h4.52a.463.463 0 01.463.494z"
          fill="#f2c81d"
        />
        <path
          data-name="Path 124"
          d="M5.414 14.795c0 5.218 4.582 9.468 10.2 9.468a10.8 10.8 0 004.81-1.123 15.265 15.265 0 004.049 3.871 16.156 16.156 0 01-8.859 2.609C7 29.6 0 22.97 0 14.795S7 0 15.631 0a16.155 16.155 0 018.859 2.617 14.63 14.63 0 016.74 11.272.453.453 0 01-.13.333.466.466 0 01-.333.139h-4.52a.445.445 0 01-.439-.41 9.482 9.482 0 00-5.359-7.494 10.822 10.822 0 00-4.818-1.115C10 5.342 5.414 9.577 5.414 14.795z"
          fill="#f2c81d"
        />
        <path
          data-name="Path 125"
          d="M48.972 14.8c0 8.175-6.991 14.8-15.623 14.8A16.132 16.132 0 0124.49 27a15.265 15.265 0 01-4.053-3.875 14.159 14.159 0 010-16.684 9.482 9.482 0 015.367 7.494.445.445 0 00.439.41h4.52a.466.466 0 00.333-.139.453.453 0 00.13-.333 14.63 14.63 0 00-6.74-11.257A16.076 16.076 0 0133.349 0c8.631 0 15.623 6.635 15.623 14.8z"
          fill="#2eb1ba"
          style={{
            mixBlendMode: 'multiply',
            isolation: 'isolate',
          }}
        />
      </g>
    </svg>
  )
}

const Logo = React.memo(LogoComponent)
export default Logo
