import gql from 'graphql-tag'

export const QueryGetOnboardingTeamList = gql`
  query QueryGetOnboardingTeamList($filter: OnboardingTeamFilter, $first: Int, $after: String) {
    getOnboardingTeamList(filter: $filter, first: $first, after: $after) {
      items {
        id
        title
        managerName
        managerId
        memberCount
        usageStatus
        createdAt
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingTeam = `query GetOnboardingTeam($id: ID!) {
  getOnboardingTeam(id: $id) {
    id
    title
    managerName
    managerId
    memberCount
    createdAt
    usageStatus
  }
}`

export const MutationCreateOnboardingTeamMember = `mutation CreateOnboardingTeamMember($input: OnboardingTeamMemberCreateInput!) {
  createOnboardingTeamMember(input: $input) {
    id
    teamId
    userId
    role
    status
    readTutorialAt
    stoppedAt
  }
}`

export const MutationCreateOnboardingTeam = `mutation CreateOnboardingTeam($input: OnboardingTeamCreateInput!) {
  createOnboardingTeam(input: $input) {
    id
    title
    managerName
    managerId
  }
}`

export const MutationUpdateOnboardingTeam = `mutation UpdateOnboardingTeam($input: OnboardingTeamUpdateInput!) {
  updateOnboardingTeam(input: $input) {
    id
    title
  }
}`

export const QueryGetAdmin = `query GetAdmin($id: ID!) {
  getAdmin(id: $id) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`

export const MutationUpdateAdmin = `mutation UpdateAdmin($input: AdminUpdateInput!) {
  updateAdmin(input: $input) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`

export const MutationDeleteAdmin = `mutation deleteAdmin($id: ID!) {
  deleteAdmin(id: $id) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`
