import 'date-fns'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { TeamStatusAdmin, User } from '@/stores/graphql'
import { Panel, InputText, Button, RenderProgress, NativeSelect } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { useUser, useUserManage } from '@/hooks/users'
import { getTeamStatusAdminLabel } from '@/utils/labels'

type Inputs = {
  id: string
  statusAdmin?: TeamStatusAdmin
  username?: string
  fullName?: string
  phone?: string
  // companyIconUrl: string
  companyName?: string
  companyZipCode?: string
  companyPrefecture?: string
  companyAddress1?: string
  companyAddress2?: string
  companyPhone?: string
  companyManagerName?: string
  contractAt?: string
  onboardingContractAt?: string
  billingCode?: string
}

export const GeneralUsersEdit: React.FC = () => {
  const { userId } = useParams<{ userId?: string }>()
  const history = useHistory()
  const { openSnackbar } = React.useContext(SnackbarContext)
  const [record, setRecord] = React.useState<User | undefined>(undefined)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { user } = useUser(userId)
  const {
    updateUser,
    // errors: updateUserRrrors
  } = useUserManage()

  React.useEffect(() => {
    if (user) {
      setRecord(user)
    }
  }, [user])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    console.log(data)
    setIsProcessing(true)

    if (userId) {
      // const input = {
      //   id: data.id,
      //   title: data.title,
      //   managerName: data.managerName,
      //   managerId: data.managerId,
      // }

      try {
        const [updated, updatedErrors] = await updateUser(data)
        setIsProcessing(false)
        if (updated) {
          openSnackbar({ type: 'success', message: 'ユーザーを編集しました' })
          history.push(Paths.Users)
        } else {
          const message = get(updatedErrors, 'errors[0].message', 'エラーが発生しました')
          openSnackbar({ type: 'error', message })
        }
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {userId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}
        {/* <div className="mt-6">
          <InputText
            required
            readOnly
            label="ニックネーム"
            name="nickname"
            defaultValue={get(record, 'nickname')}
            inputRef={register({
              required: ERRORS.inputRequired('チーム名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.nickname && errors.nickname.message}
          />
        </div> */}
        <div className="mt-6">
          <InputText
            required
            readOnly
            label="メールアドレス"
            name="email"
            defaultValue={get(record, 'email')}
            // inputRef={register({
            //   required: ERRORS.inputRequired('メールアドレス'),
            //   maxLength: {
            //     value: 50,
            //     message: ERRORS.maxCount(50),
            //   },
            // })}
            // error={errors.email && errors.email.message}
          />
        </div>

        {/* <hr className="mt-6 mb-6" />

        <h2 className="font-bold">オンボーディング関連</h2>

        <div className="mt-6">
          <NativeSelect
            label="利用状況"
            readOnly={isProcessing}
            name="onboardingUsageStatus"
            defaultValue={get(record, 'onboardingUsageStatus') || ''}
            options={[
              { value: '', label: '選択してください' },
              ...Object.entries(OnboardingUsageStatus).map(([, value]) => ({
                value,
                label: getOnboardingUsageStatusLabel(value),
              })),
            ]}
            selectRef={register({})}
          />
        </div> */}

        <hr className="mt-6 mb-6" />

        <h2 className="font-bold">チーム関連</h2>

        <div className="mt-6">
          <NativeSelect
            label="管理者状況"
            readOnly={isProcessing}
            name="statusAdmin"
            defaultValue={get(record, 'statusAdmin') || ''}
            options={Object.entries(TeamStatusAdmin).map(([, value]) => ({
              value,
              label: getTeamStatusAdminLabel(value),
            }))}
            selectRef={register({})}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="ユーザー名"
            name="username"
            defaultValue={get(record, 'username')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.username && errors.username.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="氏名"
            name="fullName"
            defaultValue={get(record, 'fullName')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.fullName && errors.fullName.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="電話番号"
            name="phone"
            defaultValue={get(record, 'phone')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.phone && errors.phone.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="会社名"
            name="companyName"
            defaultValue={get(record, 'companyName')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.companyName && errors.companyName.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="郵便番号"
            name="companyZipCode"
            defaultValue={get(record, 'companyZipCode')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.companyZipCode && errors.companyZipCode.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="会社住所"
            name="companyAddress1"
            defaultValue={get(record, 'companyAddress1')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.companyAddress1 && errors.companyAddress1.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="会社電話番号"
            name="companyPhone"
            defaultValue={get(record, 'companyPhone')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.companyPhone && errors.companyPhone.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="請求コード"
            name="billingCode"
            defaultValue={get(record, 'billingCode')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.billingCode && errors.billingCode.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="契約日"
            name="contractAt"
            defaultValue={get(record, 'contractAt')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.contractAt && errors.contractAt.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="オンボーディング契約日"
            name="onboardingContractAt"
            defaultValue={get(record, 'onboardingContractAt')}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.onboardingContractAt && errors.onboardingContractAt.message}
          />
        </div>
        {/* <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="CocolaboID(メールアドレス)"
            name="managerId"
            defaultValue={get(record, 'managerId')}
            inputRef={register({
              required: ERRORS.inputRequired('CocolaboID(メールアドレス)'),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ERRORS.inputMail,
              },
            })}
            error={errors.managerId && errors.managerId.message}
          />
        </div> */}
        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.Users}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!userId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      <Panel title={`ユーザー${!userId ? '作成' : '編集'}`}>
        {!record ? <RenderProgress /> : renderForm()}
      </Panel>
    </div>
  )
}
