import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { filter, map, catchError, exhaustMap } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import { Actions } from './actions'
import { RootState } from '../reducers'
import { Auth } from 'aws-amplify'
import { get } from 'lodash-es'

type Action = ActionType<typeof Actions>

const signInEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(Actions.signIn.started.match),
    exhaustMap(({ payload }) =>
      from(Auth.signIn(payload.user.email, payload.user.password)).pipe(
        map((response) => {
          console.log('signIn response', response)
          const groups = get(response, 'signInUserSession.idToken.payload.cognito:groups', [])

          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            payload.callbacks.forceChangePassword()

            return Actions.signIn.done({
              params: payload,
              result: {
                cognitoUser: response,
                user: null,
              },
            })
          } else {
            // Not admin
            if (!groups.includes('admin')) {
              console.log('Not admin')
              throw new Error()
            }

            payload.callbacks.success()
            return Actions.signIn.done({
              params: payload,
              result: {
                user: {
                  id: response.attributes.sub,
                  email: response.attributes.email,
                  nickname: response.attributes.nickname,
                },
              },
            })
          }
        }),
        catchError((error) => {
          payload.callbacks.error()
          return of(
            Actions.signIn.failed({
              params: payload,
              error,
            })
          )
        })
      )
    )
  )

const signOutEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(Actions.signOut.started.match),
    exhaustMap(({ payload }) =>
      from(Auth.signOut()).pipe(
        map((response) => {
          payload.callbacks.success()
          return Actions.signOut.done({
            params: payload,
            result: { data: {} },
          })
        }),
        catchError((error) => {
          payload.callbacks.error()
          return of(
            Actions.signOut.failed({
              params: payload,
              error,
            })
          )
        })
      )
    )
  )

const getCurrentUserEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(Actions.getCurrentUser.started.match),
    exhaustMap(({ payload }) =>
      from(Auth.currentSession()).pipe(
        map((response: any) => {
          console.log('getCurrentUserEpic response', response)
          const groups = get(response, 'idToken.payload.cognito:groups', [])

          console.log('groups', groups)

          if (!groups.includes('admin')) {
            throw new Error()
          }

          const idToken = response.getIdToken()
          return Actions.getCurrentUser.done({
            params: payload,
            result: {
              user: {
                id: idToken.payload.sub,
                email: idToken.payload.email,
                nickname: idToken.payload.nickname,
              },
            },
          })
        }),
        catchError((error) =>
          of(
            Actions.getCurrentUser.failed({
              params: payload,
              error,
            })
          )
        )
      )
    )
  )

const updateUserAttributes = async ({ email }: { email: string }) => {
  const cognitoUser = await Auth.currentUserPoolUser()
  await Auth.updateUserAttributes(cognitoUser, { email })
  return {}
}

const updateCurrentUserEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(Actions.updateCurrentUser.started.match),
    exhaustMap(({ payload }) =>
      from(updateUserAttributes(payload.attributes)).pipe(
        map((response) => {
          payload.callbacks.success()
          return Actions.updateCurrentUser.done({
            params: payload,
            result: {},
          })
        }),
        catchError((error) => {
          console.error(error)
          payload.callbacks.error()
          return of(
            Actions.updateCurrentUser.failed({
              params: payload,
              error,
            })
          )
        })
      )
    )
  )

const restoreUserEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(Actions.restore.started.match),
    exhaustMap(({ payload }) =>
      from(Auth.forgotPassword(payload.email)).pipe(
        map((response) => {
          payload.successCallback(payload.email)
          return Actions.restore.done({
            params: payload,
            result: {},
          })
        }),
        catchError((error) => {
          payload.errorCallback(error)

          return of(
            Actions.restore.failed({
              params: payload,
              error,
            })
          )
        })
      )
    )
  )

const resetPasswordEpic: Epic<Action, Action, RootState> = (actions$, store) =>
  actions$.pipe(
    filter(Actions.resetPassword.started.match),
    exhaustMap(({ payload }) => {
      return from(
        Auth.forgotPasswordSubmit(payload.data.email, payload.data.code, payload.data.password)
      ).pipe(
        map((res) => {
          payload.successCallback(payload.data.email)
          return Actions.resetPassword.done({
            params: payload,
            result: {},
          })
        }),
        catchError((error) => {
          payload.errorCallback(error)
          return of(
            Actions.resetPassword.failed({
              params: payload,
              error,
            })
          )
        })
      )
    })
  )

export default combineEpics(
  signInEpic,
  signOutEpic,
  getCurrentUserEpic,
  restoreUserEpic,
  resetPasswordEpic,
  updateCurrentUserEpic
)
