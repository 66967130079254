import 'date-fns'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import { CircularProgress, Breadcrumbs, Typography } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { OnboardingTeam, OnboardingTeamMember, OnboardingTeamMemberRole } from '@/stores/graphql'
import { SnackbarContext } from '@/components/Snackbar'
import { getOnboardingTeam } from '@/stores/teams/api'
import { Panel, InputText, Button, NativeSelect } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { replacePathParams } from '@/utils/history'
import {
  mutationCreateOnboardingTeamMember,
  mutationUpdateOnboardingTeamMember,
  queryOnboardingTeamMember,
} from './api'
import { getOnboardingTeamMemberRoleLabel } from '@/utils/labels'
import { format } from 'date-fns'

type Inputs = {
  id: string
  email: string
  role: OnboardingTeamMemberRole
  startedAt: string
}

export const OnboardingTeamsUsersEdit: React.FC = () => {
  const { userId, teamId } = useParams<{ userId?: string; teamId: string }>()
  const history = useHistory()

  const { openSnackbar } = useContext(SnackbarContext)

  const [team, setTeam] = useState<OnboardingTeam | undefined>()
  const getTeam = async (id: string) => {
    const { data } = await getOnboardingTeam({ id })
    setTeam(data)
  }

  useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])

  const [record, setRecord] = useState<OnboardingTeamMember | undefined>()
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const isFetching = useMemo(() => record === null, [record])

  useEffect(() => {
    if (teamId && userId) {
      queryOnboardingTeamMember({ teamId, userId }).then((data) => {
        setRecord(data)
      })
    }
  }, [teamId, userId])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (d: Inputs) => {
    console.log('submit data', d)
    setIsProcessing(true)

    if (!userId) {
      try {
        await mutationCreateOnboardingTeamMember({
          input: {
            teamId,
            email: d.email,
            role: d.role,
            startedAt: (d.startedAt ? new Date(d.startedAt) : new Date()).toISOString(),
          },
        })
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: '作成しました' })
        history.push(replacePathParams(Paths.OnboardingTeamsUsers, { teamId }))
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    } else {
      try {
        await mutationUpdateOnboardingTeamMember({
          userId: d.id.replace(`${teamId}-`, ''),
          input: {
            teamId,
            role: d.role,
          },
        })
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: '更新しました' })
        history.push(replacePathParams(Paths.OnboardingTeamsUsers, { teamId }))
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )

  const renderForm = () => {
    if (userId && !record) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {userId && (
          <div className="mt-6">
            <InputText readOnly label="ID" name="id" defaultValue={userId} inputRef={register()} />
          </div>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly={!!userId || isProcessing}
            label="メールアドレス"
            name="email"
            defaultValue={get(record, 'user.email') ?? get(record, 'email')}
            inputRef={register({
              required: ERRORS.inputRequired('メールアドレス'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.email && errors.email.message}
          />
        </div>

        <div className="mt-6">
          <NativeSelect
            label="役割"
            readOnly={isProcessing}
            name="role"
            defaultValue={get(record, 'role') ?? OnboardingTeamMemberRole.Member}
            options={Object.entries(OnboardingTeamMemberRole).map(([, value]) => ({
              value: value,
              label: getOnboardingTeamMemberRoleLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={!!userId || isProcessing}
            label="開始日"
            name="startedAt"
            defaultValue={record?.startedAt ? format(new Date(record.startedAt), 'yyyy-MM-dd') : ''}
            inputRef={register({
              required: ERRORS.inputRequired('開始日'),
              maxLength: {
                value: 100,
                message: ERRORS.maxCount(100),
              },
            })}
            error={errors.startedAt && errors.startedAt.message}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={
                teamId ? replacePathParams(Paths.OnboardingTeamsUsers, { teamId }) : Paths.DashBoard
              }
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!userId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          <Link to={Paths.OnboardingTeams} className="hover:underline">
            オンボーディングチーム一覧
          </Link>
          {teamId && (
            <Link
              to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId })}
              className="hover:underline"
            >
              {team?.title}
            </Link>
          )}
          <Link
            to={replacePathParams(Paths.OnboardingTeamsUsers, { teamId })}
            className="hover:underline"
          >
            ユーザー一覧
          </Link>
          <Typography color="primary">ユーザー{userId ? '編集' : '作成'}</Typography>
        </Breadcrumbs>
      </div>
      <div className="p-8">
        <Panel>{isFetching ? renderProgress() : renderForm()}</Panel>
      </div>
    </>
  )
}
