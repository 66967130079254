import React from 'react'
import { TeamBuildingReview } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import moment from 'moment'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useParams } from 'react-router-dom'
import {
  useTeamBuildingsReviewSummary,
  useTeamsBuildingReviews,
} from '@/hooks/teamsBuildingsReviews'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '氏名',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingReview) => (
      <span className="whitespace-no-wrap">{record.status}</span>
    ),
  },
  {
    label: '次回予定日',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingReview) => (
      <span className="whitespace-no-wrap">
        {record.nextAt ? moment(record.nextAt).format('YYYY-MM-DD') : ''}
      </span>
    ),
  },

  {
    label: '作成日',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingReview) => (
      <span className="whitespace-no-wrap">{moment(record.createdAt).format('YYYY-MM-DD')}</span>
    ),
  },
]

export const GeneralTeamsBuildingsReview: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  const { team } = useTeam(teamId)
  const { item } = useTeamBuildingsReviewSummary(teamId)
  const { items, nextToken, loading, more } = useTeamsBuildingReviews(teamId)

  return (
    <>
      <div className="m-4 mx-10 bg-white rounded border p-4">
        <div>サマリ</div>
        <div>
          {item?.histories?.map((history, index) => (
            <div key={`t-view-summary-${index}`}>
              <>
                <div>{history.date}</div>
                <div>
                  {history.averages?.map((av, avDex) => (
                    <div key={`av-${avDex}-${av.type}-${av.index}-${index}`}>
                      {av.type}:{av.index}:{av.average}
                    </div>
                  ))}
                </div>
              </>
            </div>
          ))}
        </div>
      </div>

      <IndexLayout
        fetching={loading}
        title={`${team?.name || ''}の振り返り`}
        data={items}
        columns={COLUMNS}
        createPath={
          team ? replacePathParams(Paths.TeamBuildingsReviewNew, { teamId: team.id }) : undefined
        }
        editPath={(reviewId: string) =>
          team
            ? replacePathParams(Paths.TeamBuildingsReviewEdit, { teamId: team.id, reviewId })
            : undefined
        }
        onClickReadMore={nextToken ? more : undefined}
      />
    </>
  )
}
