import React from 'react'
// import { User } from 'pages/onboarding/domain'
import { AdminUpdateUserInput, User, UserFilter } from '@/stores/graphql'
import { queryGetUser, queryGetUsers, mutationUpdateUser } from './graphql'

export const useUsers = (filter?: UserFilter) => {
  const limit = 1000
  const [users, setUsers] = React.useState<User[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetUsers({ filter, limit })
      if (response) {
        setUsers(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [filter])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetUsers({ filter, limit, nextToken })
      if (response) {
        setUsers([...users, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [users, filter, nextToken])

  return { users, nextToken, loading, error, refresh, more } as const
}

export const useUser = (userId?: string) => {
  const [user, setUser] = React.useState<User | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!userId) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetUser({ id: userId })
      setUser(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [userId])

  React.useEffect(() => {
    refresh()
  }, [userId, refresh])

  return { user, loading, error, refresh } as const
}

export const useUserManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  // const createUser = React.useCallback(async (input: AdminCreateTeamInput): Promise<
  //   [Team | undefined, undefined | Error[]]
  // > => {
  //   setLoading(true)
  //   try {
  //     const response = await mutationCreateTeam({ input })
  //     setLoading(false)

  //     return [response, undefined]
  //   } catch (e) {
  //     console.log('e', e)
  //     setErrors(e.errors)
  //     setLoading(false)

  //     return [undefined, e.errors]
  //   }
  // }, [])

  const updateUser = React.useCallback(async (input: AdminUpdateUserInput): Promise<
    [User | undefined, undefined | Error[]]
  > => {
    setLoading(true)
    try {
      const response = await mutationUpdateUser({ input })
      return [response, undefined]
    } catch (e) {
      console.log('e', e)
      setErrors(e)
      return [undefined, e.errors]
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    updateUser,
    errors,
    loading,
  } as const
}
