import React from 'react'
import { Invoice, InvoiceDetail, InvoiceFilter, UpdateInvoiceInput } from '@/stores/graphql'
import {
  queryGetInvoices,
  queryGetInvoice,
  mutationUpdateInvoice,
  queryGetInvoiceDetails,
} from './graphql'

export const useInvoices = (filter?: InvoiceFilter) => {
  const limit = 1000
  const [invoices, setInvoices] = React.useState<Invoice[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetInvoices({ filter, limit })
      if (response) {
        setInvoices(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [filter])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetInvoices({ filter, limit, nextToken })
      if (response) {
        setInvoices([...invoices, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [invoices, filter, nextToken])

  return { invoices, nextToken, loading, error, refresh, more } as const
}

export const useInvoice = (id?: string) => {
  const [invoice, setInvoice] = React.useState<Invoice | undefined>()
  const [invoiceDetails, setInvoiceDetails] = React.useState<InvoiceDetail[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!id) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetInvoice({ id })
      setInvoice(response)

      if (response && response.id) {
        const details = await queryGetInvoiceDetails({ invoiceId: response.id })
        if (details) {
          setInvoiceDetails(details)
        }
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [id])

  React.useEffect(() => {
    refresh()
  }, [id, refresh])

  return { invoice, invoiceDetails, loading, error, refresh } as const
}

export const useInvoiceManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const updateInvoice = React.useCallback(async (input: UpdateInvoiceInput) => {
    setLoading(true)
    try {
      const response = await mutationUpdateInvoice({ input })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    updateInvoice,
    errors,
    loading,
  } as const
}
