import React from 'react'
import {
  Master,
  MasterType,
  RelatedTeamPlan,
  RelatedTeamPlanStatus,
  TeamPlan,
} from '@/stores/graphql'
import { Paths } from '@/constants'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useParams, Link } from 'react-router-dom'
// import { SnackbarContext } from '@/components/Snackbar'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mutationUpdateTeamPlan } from '@/hooks/graphql'
import { Button, Fade, MenuItem, Modal, Select } from '@material-ui/core'
import { useMasters } from '@/hooks/masters'
import { format } from 'date-fns'

export const GeneralTeamsPlans: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  // const { openSnackbar } = useContext(SnackbarContext)

  const { team, refresh } = useTeam(teamId)
  const { masters: teamPlans } = useMasters(MasterType.TeamPlan)

  const currentTeamPlans = team && team.plans ? (JSON.parse(team.plans) as RelatedTeamPlan[]) : []

  // React.useEffect(() => {
  //   if (errors && errors.length > 0) {
  //     const message = get(errors, '[0].message', 'エラーが発生しました')
  //     openSnackbar({ type: 'error', message })
  //   }
  // }, [errors, openSnackbar])

  // 利用関連
  const [open, setOpen] = React.useState(false)
  const [selectedPlanId, setSelectedPlanId] = React.useState<string | undefined>(undefined)
  const [states, setStates] = React.useState({
    price: '',
    limit: '',
  })

  const onPlanChanged = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    for (const p of teamPlans) {
      if (p.id === (event.target.value as string)) {
        setSelectedPlanId(p.id)
        setStates({
          price: `${(p.price ?? 0).toLocaleString()}円`,
          limit: `${p.limit}人`,
        })
      }
    }
  }

  React.useEffect(() => {
    if (open) {
      if (teamPlans.length > 0) {
        const pp = filterTeamPlans(teamPlans, currentTeamPlans)

        if (pp.length > 0) {
          setSelectedPlanId(pp[0].id)
          setStates({
            price: `${(pp[0].price ?? 0).toLocaleString()}円`,
            limit: `${pp[0].limit}人`,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, teamPlans])

  const handleSubmit = async () => {
    if (selectedPlanId && team) {
      const updated = await mutationUpdateTeamPlan({
        input: {
          teamId: team.id,
          planId: selectedPlanId,
          planStatus: RelatedTeamPlanStatus.Enable,
        },
      })
      if (updated) {
        setOpen(false)
        refresh()
      }
    }
  }

  // 利用停止関連
  const [openCancel, setOpenCancel] = React.useState(false)
  const [selectedCancelPlanId, setSelectedCancelPlanId] = React.useState<string | undefined>(
    undefined
  )

  const handleCancelCancelPlan = async () => {
    setOpenCancel(false)
    await new Promise((resolve) => setTimeout(resolve, 500))
    setSelectedCancelPlanId(undefined)
  }

  const handleCancelPlan = async (cancelPlanId?: string) => {
    if (team && cancelPlanId) {
      const updated = await mutationUpdateTeamPlan({
        input: {
          teamId: team.id,
          planId: cancelPlanId,
          planStatus: RelatedTeamPlanStatus.Disable,
        },
      })
      if (updated) {
        refresh()

        setOpenCancel(false)
        await new Promise((resolve) => setTimeout(resolve, 500))
        setSelectedCancelPlanId(undefined)
      }
    }
  }

  return (
    <>
      <div>
        <div className="m-10 bg-white shadow rounded-lg p-6">
          <div className="flex items-center justify-between py-8">
            <h2 className="text-xl font-bold">
              <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
                チーム一覧
              </Link>
              <span>/{`${team?.name || '-----'}/チームメンバー`}</span>
            </h2>
          </div>

          <>
            <div className="text-primary mb-4">現在利用中のプラン</div>
            {(() => {
              return currentTeamPlans
                .filter((pp) => pp.status === RelatedTeamPlanStatus.Enable)
                .map((pp, index) => {
                  const plan = getTeamPlanById(teamPlans, pp.id)
                  if (plan) {
                    return (
                      <div
                        className="flex items-center justify-between pb-4 border-b mb-4"
                        key={`team-plan-item-${index}`}
                      >
                        <div className="flex items-center">
                          <div className="text-sm whitespace-nowrap bg-primary text-white py-1 px-2 mr-4">
                            プラン{index + 1}
                          </div>

                          <div className="">
                            <div>{plan.name}</div>
                            <div className="flex items-center">
                              <div className="mr-4">{plan.price.toLocaleString()}円/月</div>
                            </div>
                          </div>
                        </div>

                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setSelectedCancelPlanId(plan.id)
                            setOpenCancel(true)
                          }}
                        >
                          利用中止
                        </Button>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })
            })()}

            <Button
              color="primary"
              onClick={() => setOpen(true)}
              startIcon={<FontAwesomeIcon className="text-primary" icon={faPlus} />}
            >
              プランを追加する
            </Button>
          </>
        </div>
      </div>

      {/* プラン選択用モーダル */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Fade in={open}>
          <div className={'flex items-center justify-center outline-none h-full'}>
            <div className={'bg-white p-6'} style={{ width: 600 }}>
              <div className={'text-sm pb-4 mb-4 border-b'}>プラン選択</div>

              <Select
                className={'w-full'}
                variant={'outlined'}
                value={selectedPlanId}
                onChange={onPlanChanged}
              >
                {filterTeamPlans(teamPlans, currentTeamPlans).map((p, index) => (
                  <MenuItem key={`team-plan-selector-${index}`} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>

              <div className="text-primary text-sm">月額料金{states.price}</div>
              <div className="text-primary text-sm">利用人数上限{states.limit}</div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  color="default"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => setOpen(false)}
                >
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="ml-4"
                  style={{ color: 'white' }}
                  onClick={handleSubmit}
                  disabled={!selectedPlanId}
                >
                  利用開始
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* プラン選択用モーダル */}
      <Modal
        open={openCancel}
        onClose={() => {
          setOpenCancel(false)
        }}
      >
        <Fade in={openCancel}>
          <div className={'flex items-center justify-center outline-none h-full'}>
            <div className={'bg-white p-6'} style={{ minWidth: 600 }}>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginBottom: 30,
                }}
              >
                {getTeamPlanById(teamPlans, selectedCancelPlanId)?.name || 'プラン'}
                の利用を中止します
              </div>
              <div
                style={{
                  fontSize: 16,
                  marginBottom: 20,
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {() => {
                  const nextMonth = new Date()
                  nextMonth.setMonth(nextMonth.getMonth() + 1)
                  return format(nextMonth, 'yyyy年MM月')
                }}
                {format(new Date(), 'yyyy年MM月')}
                までで利用中のツールが使えなくなります{'\n'}本当に利用中止してもよろしいですか？
              </div>
              <div
                style={{
                  fontSize: 16,
                  marginBottom: 40,
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                }}
              >
                ＊これまでの活動データは引き続き閲覧できます。{'\n'}
                ＊再度プランの利用を開始した場合、続きからではなく新しくはじめることとなります。
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  color="default"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={handleCancelCancelPlan}
                >
                  キャンセル
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={() => handleCancelPlan(selectedCancelPlanId)}
                >
                  利用停止
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export const getTeamPlanById = (plans: Master[], id?: string): TeamPlan | undefined => {
  if (!id) return undefined
  return plans.find((t) => t.id === id) as TeamPlan
}

export const filterTeamPlans = (plans: Master[], relatedTeamPlans: RelatedTeamPlan[]) => {
  return plans
    .filter((p) => p.publicRange !== undefined && p.publicRange !== null && p.publicRange >= 0)
    .filter((plan) => {
      const p = relatedTeamPlans.find((r) => {
        return plan.id === r.id
      })

      return !p || p.status === RelatedTeamPlanStatus.Disable
    })
}
