import { API, GraphQLResult } from '@aws-amplify/api'
import { OnboardingSummary, QueryGetOnboardingSummaryArgs } from '@/stores/graphql'
import gql from 'graphql-tag'

export const queryOnboardingSummary = async (
  args: QueryGetOnboardingSummaryArgs
): Promise<OnboardingSummary | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getOnboardingSummary(
        $teamId: ID!
        $type: OnboardingSummaryType!
        $from: String
        $to: String
        $excludes: [String!]
        $format: OnboardingSummaryFormat
        $refresh: Boolean
      ) {
        getOnboardingSummary(
          teamId: $teamId
          type: $type
          from: $from
          to: $to
          excludes: $excludes
          format: $format
          refresh: $refresh
        ) {
          id
          data
          createdAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getOnboardingSummary: OnboardingSummary }>

  return response?.data?.getOnboardingSummary
}
