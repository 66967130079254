import React from 'react'

import DataTable, { Column } from '@/components/DataTable'
import dayjs from 'dayjs'
import { OnboardingDailySummary } from '@/types/type'
import MiniDataTable, { MiniDataTableValues } from '../atoms/MiniDataTable'

interface IProps {
  onboardingDailySummaryData: OnboardingDailySummary.Data | null | undefined
  fetching: boolean
}

const startedAtFromRecord = ({ startedAt }: any) => dayjs(startedAt).format('YYYY-MM-DD')
const valueOrEmpty = (value: string | number | null | undefined) => {
  return value === null || value === undefined ? '-' : value
}

const USERS_COLUMNS: Column[] = [
  {
    label: '名前',
    field: 'name',
  },
  {
    label: '参加日',
    field: startedAtFromRecord,
  },
  {
    label: '実地回数',
    field: 'targetCount',
  },
  {
    label: '回答率',
    field: 'rate',
  },
  {
    label: '平均',
    field: 'average',
  },
]

const OnbordingDailySummary: React.VFC<IProps> = ({ onboardingDailySummaryData, fetching }) => {
  const { summary, dates, users, excludes } = onboardingDailySummaryData || {
    summary: null,
    dates: null,
    users: null,
    excludes: Array<string>(),
  }
  const dateValues = dates
    ? Object.entries(dates)
        .filter(([day, value]) => value.target && !excludes?.includes(day))
        .map(([day, value]) => {
          return { id: day, day, ...value }
        })
    : []

  const usersValues = users
    ? Object.entries(users).map(([id, value]) => {
        return { id, ...value }
      })
    : []

  const dailyColumns: Column[] = [
    {
      label: '日付',
      field: 'day',
    },
    {
      label: '回答率',
      field: ({ summary }: any) => summary.rate,
    },
    {
      label: '平均',
      field: ({ summary }: any) => summary.average,
    },

    ...usersValues.map((user) => {
      return {
        label: user.name,
        field: ({ users }: any) => {
          return valueOrEmpty(users[user.id]?.value)
        },
      }
    }),
  ]
  const inlineTableData: MiniDataTableValues[] = React.useMemo(() => {
    return summary
      ? [
          {
            label: '解答率',
            value: summary.rate,
          },
          {
            label: '平均',
            value: summary.average,
          },
        ]
      : []
  }, [summary])
  return (
    <div className="py-2">
      <h2 className="text-xl font-bold">デイリー</h2>
      <MiniDataTable values={inlineTableData} />
      <DataTable
        title="ユーザー別"
        columns={USERS_COLUMNS}
        data={usersValues}
        fetching={fetching}
      />
      <DataTable title="詳細" columns={dailyColumns} data={dateValues} fetching={fetching} />
    </div>
  )
}

export default OnbordingDailySummary
