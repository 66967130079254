export const DASHBOARD = '/teams'

//
// Generals
//
export enum Paths {
  DashBoard = '/general/users',
  // Basics
  Root = '/',
  SignIn = '/sign_in',
  SingUp = '/sign_up',
  Restore = '/restore',
  ResetPassword = '/reset_password',
  ForceChangePassword = '/force_change_password',
  Settings = '/settings',

  // Generals
  Users = '/general/users',
  // UsersNew = '/general/users/new',
  UsersEdit = '/general/users/:userId/edit',
  UsersTeams = '/general/users/:userId/teams',

  Teams = '/general/teams',
  TeamsNew = '/general/teams/new',
  TeamsEdit = '/general/teams/:teamId/edit',
  TeamsCsv = '/general/teams/csv',

  TeamPlans = '/general/teams/:teamId/plans',

  TeamMembers = '/general/teams/:teamId/members',
  TeamMembersEdit = '/general/teams/:teamId/members/:teamMemberId/edit',
  TeamMembersNew = '/general/teams/:teamId/new-member',
  TeamMembersCsv = '/general/teams/:teamId/csv',

  Invoices = '/general/invoices',
  InvoicesEdit = '/general/invoices/:invoiceId/edit',
  Errors = 'general/errors',
  ErrorsEdit = '/general/errors/:errorId/edit',

  TeamBuildings = '/general/teams/:teamId/buildings',
  TeamBuildingsEdit = '/general/teams/:teamId/buildings/:teamBuildingId/edit',
  TeamBuildingsActions = '/general/teams/:teamId/buildings/actions',
  TeamBuildingsActionsNew = '/general/teams/:teamId/buildings/actions/new',
  TeamBuildingsActionsEdit = '/general/teams/:teamId/buildings/actions/:teamBuildingActionId/edit',
  TeamBuildingsActionsDates = '/general/teams/:teamId/buildings/actions/:teamBuildingActionId/dates',
  TeamBuildingsActionsDatesNew = '/general/teams/:teamId/buildings/actions/:teamBuildingActionId/dates/new',
  TeamBuildingsActionsDatesEdit = '/general/teams/:teamId/buildings/actions/:teamBuildingActionId/dates/:teamBuildingActionDateId/edit',

  TeamBuildingsReview = '/feneral/teams/:teamId/building/review',
  TeamBuildingsReviewNew = '/feneral/teams/:teamId/building/review/new',
  TeamBuildingsReviewEdit = '/feneral/teams/:teamId/building/review/:reviewId/edit',
  TeamBuildingsReviewMembers = '/feneral/teams/:teamId/building/review/:reviewId/members',

  Companies = '/general/companies',

  // Onboardings
  OnboardingTeamsDashboard = '/onboarding/teams/:teamId/dashboard',
  OnboardingTeams = '/onboarding/teams',
  OnboardingTeamsNew = '/onboarding/teams/new',
  OnboardingTeamsEdit = '/onboarding/teams/:teamId/edit',
  OnboardingTeamsActions = '/onboarding/teams/:teamId/actions',
  OnboardingTeamsActionsNew = '/onboarding/teams/:teamId/actions/new',
  OnboardingTeamsActionsEdit = '/onboarding/teams/:teamId/actions/:actionId/edit',
  OnboardingTeamsUsers = '/onboarding/teams/:teamId/users',
  OnboardingTeamsUsersNew = '/onboarding/teams/:teamId/users/new',
  OnboardingTeamsUsersEdit = '/onboarding/teams/:teamId/users/:userId/edit',
  OnboardingTeamsUsersCsv = '/onboarding/teams/:teamId/users/:userId/csv-import',

  OnboardingActions = '/onboarding/actions',
  OnboardingActionsNew = '/onboarding/actions/new',
  OnboardingActionsEdit = '/onboarding/actions/:actionId/edit',
  OnboardingActionsCsv = '/onboarding/teams/:teamId/actions/csv-import',

  // Manage
  Masters = '/masters',
  MastersNew = '/masters/new',
  MastersEdit = '/masters/:masterId/edit',
  MastersCsv = '/masters/csv',

  Maintain = '/maintain',

  Admins = '/admins',
  AdminsNew = '/admins/new',
  AdminsEdit = '/admins/:adminId/edit',
}
