import React from 'react'
import { Batch, Search } from '@/components/atoms'
import { Link, useParams } from 'react-router-dom'
import { Team, TeamFilter } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { replacePathParams } from '@/utils/history'
import {
  getPaymentMethodLabel,
  getTeamStatusAdminColor,
  getTeamStatusAdminLabel,
  getTeamStatusUsageColor,
  getTeamStatusUsageLabel,
} from '@/utils/labels'
import moment from 'moment'
import { useTeamsByEmail } from '@/hooks/teams'
import { useUser } from '@/hooks/users'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'チーム名',
    field: 'name',
  },
  {
    label: 'メンバー数',
    field: 'countMember',
  },
  {
    label: '決済方法',
    field: (record: Team) => getPaymentMethodLabel(record.paymentMethod),
  },
  {
    label: '管理者状況',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <Batch
        title={getTeamStatusAdminLabel(record.statusAdmin)}
        backgroundColor={getTeamStatusAdminColor(record.statusAdmin)}
      />
    ),
  },
  {
    label: '利用状況',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <Batch
        title={getTeamStatusUsageLabel(record.statusUsage)}
        backgroundColor={getTeamStatusUsageColor(record.statusUsage)}
      />
    ),
  },
  {
    label: '作成日',
    field: (record: Team) => moment(record.createdAt).format('YYYY-MM-DD'),
  },
]

export const GeneralUsersTeams: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()

  const [filter, setFilter] = React.useState<TeamFilter>({
    ownerUserId: userId,
  })

  const { user } = useUser(userId)
  const { teams, loading } = useTeamsByEmail(user?.email)

  const onApplySearch = async (value: string, type: 'name' | 'adminEmail') => {
    const nextFilter = { ...filter }

    switch (type) {
      case 'name':
        if (value) {
          nextFilter.name = value
        } else {
          delete nextFilter.name
        }
        break
      // case 'adminEmail':
      //   if (value) {
      //     nextFilter.adminEmail = value
      //   } else {
      //     delete nextFilter.adminEmail
      //   }
      //   break
    }

    setFilter(nextFilter)
  }

  return (
    <IndexLayout
      fetching={loading}
      title={
        <>
          {user?.fullName ? (
            <>
              <a
                className="underline cursor-pointer text-primary"
                href={replacePathParams(Paths.UsersEdit, { userId: user.id })}
              >
                {user?.fullName}
              </a>
              のチーム一覧
            </>
          ) : (
            ''
          )}
        </>
      }
      data={teams}
      columns={COLUMNS}
      // createPath={Paths.OnboardingActionsNew}
      editPath={(teamId: string) => replacePathParams(Paths.TeamsEdit, { teamId })}
      // handleDelete={handleDelete}
      otherHandlers={(record: Team) => (
        <Link
          className="underline text-primary whitespace-no-wrap"
          to={replacePathParams(Paths.TeamMembers, { teamId: record.id })}
        >
          メンバー一覧
        </Link>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'name')}
                placeholder="チーム名検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
