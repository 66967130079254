import * as React from 'react'
import FormItem from './_layouts/FormItem'
import { DropZoneUploader } from './'

interface IProps {
  label?: null | string
  error?: null | string
  required?: boolean
}

export interface IImage {
  file: Blob | null
  url: string
}

export interface IImperativeMethods {
  previewImages: any[]
  setPreviewImages: React.Dispatch<React.SetStateAction<IImage[]>>
}

// eslint-disable-next-line react/display-name
export const ImageSelect = React.forwardRef<IImperativeMethods, IProps>(
  ({ label = '', error = null, required = false }, ref) => {
    const [previewImages, setPreviewImages] = React.useState<IImage[]>([])
    const onDrop = (addedImages: any) => {
      addedImages.forEach((addedImage: any) => {
        const fileReader = new FileReader()

        fileReader.onloadend = (event: any) => {
          const loadedImage = {
            file: addedImage,
            url: event.target.result,
          }

          setPreviewImages((currentPreviewImages: IImage[]) => [
            ...currentPreviewImages,
            loadedImage,
          ])
        }
        fileReader.readAsDataURL(addedImage)
      })
    }

    const deletePreviewImage = (deleteIndex: number) => {
      setPreviewImages((currentPreviewImages: IImage[]) =>
        currentPreviewImages.filter((image: IImage, index: number) => index !== deleteIndex)
      )
    }

    React.useImperativeHandle(ref, () => ({
      previewImages,
      setPreviewImages,
    }))

    return (
      <FormItem label={label} required={required} error={error}>
        {previewImages.map((image: IImage, index: number) => (
          <div key={index} className="relative w-56 h-56">
            <div
              className="absolute flex items-center justify-center top-0 right-0 w-6 h-6 -mt-3 -mr-3 bg-white border border-neutral-300 rounded-full leading-none text-neutral-600 cursor-pointer shadow"
              onClick={() => deletePreviewImage(index)}
            >
              ×
            </div>
            <img className="w-full h-full object-cover rounded-lg" src={image.url} alt="" />
          </div>
        ))}
        {previewImages.length === 0 && <DropZoneUploader className="h-56" onDrop={onDrop} />}
      </FormItem>
    )
  }
)
