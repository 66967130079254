import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {
  QueryGetOnboardingActionList,
  QueryGetOnboardingAction,
  MutationCreateOnboardingAction,
  MutationUpdateOnboardingAction,
  MutationCreateOnboardingActionsFromMaster,
} from './graphql'
import {
  QueryGetOnboardingActionListArgs,
  OnboardingActionCreateInput,
  OnboardingActionUpdateInput,
} from '../graphql'
import { DEFAULT_PER_PAGE } from '@/constants/api'

export const getOnboardingActionList = async ({
  filter,
  first = DEFAULT_PER_PAGE,
  teamId = null,
  after = null,
}: QueryGetOnboardingActionListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingActionList,
    variables: { filter, teamId, first, after },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('QueryGetOnboardingActionList', response)

  return {
    data: [...response.data.getOnboardingActionList.items],
    nextToken: response?.data?.getOnboardingActionList?.nextToken,
  }
}

export const getOnboardingAction = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingAction,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('QueryGetOnboardingAction', response)

  return {
    data: { ...response.data.getOnboardingAction },
  }
}

export const createOnboardingAction = async (input: OnboardingActionCreateInput) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingAction,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationCreateOnboardingAction response', response)

  return {
    data: { ...response.data.createOnboardingAction },
  }
}

export const createOnboardingActionsFromMaster = async (teamId: string) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingActionsFromMaster,
    variables: { teamId },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationCreateOnboardingActionsFromMaster response', response)

  return {
    data: response.data.createOnboardingActionsFromMaster,
  }
}

export const updateOnboardingAction = async (input: OnboardingActionUpdateInput) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingAction,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationUpdateOnboardingAction response', response)

  return {
    data: { ...response.data.updateOnboardingAction },
  }
}
