import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { CircularProgress } from '@material-ui/core'
import { Button } from '@/components/atoms'
import { isString, isFunction } from 'lodash-es'

export interface Column {
  label: string
  field?: any
  onSort?: any
  thClass?: string
  tdClass?: string
  wrap?: boolean
}
interface IProps {
  fetching: boolean
  title?: string | React.ReactElement
  data?: {
    id: string
    [key: string]: any
  }[]
  columns: Column[]
  headerLink?: React.ReactElement
  filters?: React.ReactElement
  onClickReadMore?(): Promise<void>
}

const DataTable: React.FC<IProps> = ({
  fetching,
  title,
  columns,
  data,
  headerLink,
  filters,
  onClickReadMore,
}) => {
  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )
  return (
    <>
      <div className="mt-5 bg-white shadow rounded-lg">
        {(title || headerLink) && (
          <div className="flex items-center justify-between px-6 py-4">
            {title && <h2 className="text-xl font-bold">{title}</h2>}
            <div className="flex gap-4">{headerLink && headerLink}</div>
          </div>
        )}
        {filters && filters}
        <div className="overflow-scroll">
          <table className="table-auto border-collapse w-full break-all">
            <thead className="display-block border-b border-gray-400">
              <tr className="w-full">
                {columns.map((column: any, i) => (
                  <th
                    key={i}
                    className={classNames(
                      'px-4 py-2 text-left text-xs font-normal text-gray-600 whitespace-no-wrap',
                      column.thClass,
                      isFunction(column.onSort) && 'cursor-pointer'
                    )}
                    onClick={() => {
                      if (isFunction(column.onSort)) {
                        column.onSort()
                      }
                    }}
                  >
                    {column.label}
                    {isFunction(column.onSort) && (
                      <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-400 border-b border-gray-400">
              {data &&
                data.map((record: any) => (
                  <tr key={record.id} className="w-full">
                    {columns.map((column, i) => (
                      <td
                        key={`${record.id}-${i}`}
                        className={classNames([
                          'px-4 py-3 text-sm',
                          column.tdClass,
                          column.field === 'id' && 'truncate',
                        ])}
                        style={{
                          minWidth: 80,
                          whiteSpace: column.wrap ? 'normal' : 'nowrap',
                        }}
                      >
                        {isString(column.field) && record[column.field]}
                        {isFunction(column.field) && column.field(record)}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!fetching && onClickReadMore && (
          <div className="py-6 flex justify-center">
            <Button handleClick={onClickReadMore} buttonType="secondary" size="small">
              もっと見る
            </Button>
          </div>
        )}
        {fetching && renderProgress()}
      </div>
    </>
  )
}

export default DataTable
