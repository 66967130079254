import moment from 'moment'

import { saveAs } from 'file-saver'
import JSZip from 'jszip'
export type CSVs = {
  name: string
  content: string
}[]

export default function csvDownload(records: any[]) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const recordsString = records.map((record: any) => record.join(',')).join('\r\n')
  const blob = new Blob([bom, recordsString], { type: 'text/csv' })

  const downloadLink = document.createElement('a')
  downloadLink.download = `${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`
  downloadLink.href = URL.createObjectURL(blob)
  downloadLink.dataset.downloadurl = ['text/plain', downloadLink.download, downloadLink.href].join(
    ':'
  )
  downloadLink.click()
}
export const csvZipDonwload = (csvs: CSVs, fileName: string, yearMonth: string) => {
  const zip = new JSZip()
  csvs.forEach((csv) => {
    zip.file(`${csv.name}-${yearMonth}.csv`, csv.content)
  })

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${fileName}-${yearMonth}.zip`)
  })
}
