import React from 'react'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Master, MasterType } from '@/stores/graphql'
import { useMasters, useMasterManage } from '@/hooks/masters'
import { replacePathParams } from '@/utils/history'
import {
  getMasterPlanPublicRangeLabel,
  getMasterTypeLabel,
  getTeamToolColor,
  getTeamToolLabel,
} from '@/utils/labels'
import { Batch, NativeSelect } from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { EditableMasterTypeList } from './utils'

export const ManageMasters: React.FC = () => {
  const { openSnackbar } = React.useContext(SnackbarContext)
  const [selectedType, setSelectedType] = React.useState<MasterType>(MasterType.TeamPlan)
  const { masters, nextToken, loading, more, refresh } = useMasters(selectedType)

  const { deleteMaster } = useMasterManage()
  const handleDelete = async (id: string) => {
    if (window.confirm('本当に削除しますか?')) {
      const deleted = await deleteMaster(id)
      if (deleted) {
        openSnackbar({ type: 'success', message: '削除しました' })
        refresh()
      } else {
        openSnackbar({ type: 'error', message: '削除に失敗しました' })
      }
    }
  }

  return (
    <IndexLayout
      fetching={loading}
      title="管理 - マスター"
      data={masters}
      columns={getColumns(selectedType)}
      csvImportPath={replacePathParams(Paths.MastersCsv)}
      createPath={replacePathParams(Paths.MastersNew)}
      editPath={(masterId: string) => replacePathParams(Paths.MastersEdit, { masterId })}
      onClickReadMore={nextToken ? more : undefined}
      handleDelete={handleDelete}
      filters={
        <div className="flex px-6 mb-4">
          <NativeSelect
            label="タイプ"
            name="type"
            defaultValue={selectedType}
            options={EditableMasterTypeList().map(([, value]) => ({
              value,
              label: getMasterTypeLabel(value),
            }))}
            onChangeHandler={(e) => setSelectedType(e.target.value)}
          />
        </div>
      }
    />
  )
}

const DEFAULT_COLUMNS = [{ label: '順番', field: 'sort', thClass: 'w-1/12' }]

const getColumns = (type: MasterType) => {
  switch (type) {
    case MasterType.TeamPlan:
      return [
        ...DEFAULT_COLUMNS,
        { label: 'コード', field: 'code', tdClass: 'whitespace-no-wrap' },
        { label: 'プラン名', field: 'name', thClass: 'w-2/12' },
        { label: '説明', field: 'description', thClass: 'w-5/12' },
        {
          label: 'ツール',
          // eslint-disable-next-line react/display-name
          field: (record: Master) => (
            <div className="flex flex-wrap gap-1">
              {record.tools &&
                record.tools.map((t, index) => (
                  <Batch
                    title={getTeamToolLabel(t)}
                    backgroundColor={getTeamToolColor(t)}
                    key={`master-item-team-plan-${index}`}
                  />
                ))}
            </div>
          ),
        },
        {
          label: '人数',
          // eslint-disable-next-line react/display-name
          field: (record: Master) => <>{record.limit?.toLocaleString() || '0'}</>,
          thClass: 'text-right',
          tdClass: 'whitespace-no-wrap text-right',
        },
        {
          label: '価格',
          // eslint-disable-next-line react/display-name
          field: (record: Master) => <>{record.price?.toLocaleString() || '0'}</>,
          thClass: 'text-right',
          tdClass: 'whitespace-no-wrap text-right',
        },
        {
          label: '公開範囲',
          // eslint-disable-next-line react/display-name
          field: (record: Master) => <>{getMasterPlanPublicRangeLabel(record.publicRange)}</>,
          thClass: 'text-right',
          tdClass: 'whitespace-no-wrap text-right',
        },
      ]

    case MasterType.TeamBuildingQ2Keyword:
      return [...DEFAULT_COLUMNS, { label: 'タイトル', field: 'title' }]
    case MasterType.TeamBuildingQ4Keyword:
      return [...DEFAULT_COLUMNS, { label: 'タイトル', field: 'title' }]
    case MasterType.TeamBuildingQ5Action:
      return [
        ...DEFAULT_COLUMNS,
        { label: 'タイトル', field: 'title' },
        { label: '理由', field: 'reason' },
        { label: '内容', field: 'detail' },
        { label: 'タイミング', field: 'timing' },
      ]
    case MasterType.TeamBuildingReviewIceBreakCategory:
      return [
        ...DEFAULT_COLUMNS,
        { label: 'カテゴリ名', field: 'name' },
        { label: '説明', field: 'description' },
      ]
    case MasterType.TeamBuildingReviewIceBreak:
      return [
        ...DEFAULT_COLUMNS,
        { label: '親ID', field: 'parentId' },
        { label: 'タイトル', field: 'name' },
      ]
    default:
      return [...DEFAULT_COLUMNS]
  }
}
