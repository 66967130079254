import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import {
  Panel,
  InputText,
  Button,
  RenderProgress,
  NativeSelect,
  InputTextArea,
  Select,
} from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { Master, MasterType, SelectOption, TeamTool } from '@/stores/graphql'
import { useMaster, useMasterManage, useMasters } from '@/hooks/masters'
import { replacePathParams } from '@/utils/history'
import { getMasterPlanPublicRangeLabel, getMasterTypeLabel, getTeamToolLabel } from '@/utils/labels'
import { EditableMasterTypeList } from './utils'

type Inputs = {
  type: MasterType
  sort: string
  title: string
  reason: string
  detail: string
  timing: string

  code?: string
  name?: string
  description?: string
  tools?: SelectOption[]
  limit?: string
  price?: string
  publicRange?: SelectOption
  parentId?: SelectOption
}

export const ManageMasterEdit: React.FC = () => {
  const { masterId } = useParams<{ masterId: string }>()
  const history = useHistory()
  const { openSnackbar } = React.useContext(SnackbarContext)
  const [record, setRecord] = React.useState<Master | undefined>(undefined)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { register, handleSubmit, errors, watch, setValue, control } = useForm<Inputs>()
  const selectedType = watch('type', MasterType.TeamPlan)
  const { master, loading } = useMaster(masterId)
  const { createMaster, updateMaster } = useMasterManage()
  React.useEffect(() => {
    setRecord(master)
    if (master) {
      setValue('type', master.type)
      setValue('tools', getToolOptions(get(master, 'tools') || []))
      setValue('publicRange', getPublicRangeOption(master.publicRange))
    }
  }, [master, setValue])

  const onSubmit = async (data: Inputs) => {
    console.log(data)
    setIsProcessing(true)

    const publicRange = Number(data.publicRange?.value) ?? undefined
    const parentId = data.parentId?.value ?? undefined

    if (!masterId) {
      try {
        const created = await createMaster({
          ...data,
          type: data.type,
          sort: Number(data.sort),
          limit: Number(data.limit),
          price: Number(data.price),
          tools: data.tools ? (data.tools.map((option) => option.value) as TeamTool[]) : undefined,
          publicRange,
          parentId,
        })
        setIsProcessing(false)

        if (created) {
          openSnackbar({ type: 'success', message: '作成しました' })
          history.push(Paths.Masters)
        } else {
          openSnackbar({ type: 'error', message: 'エラーが発生しました' })
        }
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    } else {
      // update
      delete data.type
      try {
        const [updated] = await updateMaster({
          ...data,
          id: masterId,
          sort: Number(data.sort),
          limit: Number(data.limit),
          price: Number(data.price),
          tools: data.tools ? (data.tools.map((option) => option.value) as TeamTool[]) : undefined,
          publicRange,
          parentId,
        })
        setIsProcessing(false)

        if (updated) {
          openSnackbar({ type: 'success', message: '更新しました' })
          history.push(Paths.Masters)
        } else {
          openSnackbar({ type: 'error', message: 'エラーが発生しました' })
        }
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const { masters: iceBreakCategories } = useMasters(MasterType.TeamBuildingReviewIceBreakCategory)

  React.useEffect(() => {
    console.log('errors', errors)
  }, [errors])

  const getFields = () => {
    switch (selectedType) {
      case MasterType.TeamPlan:
        return (
          <>
            <div className="mt-6">
              <Controller
                name="publicRange"
                rules={{ required: true }}
                control={control}
                render={({ onChange, name, value }) => (
                  <>
                    <Select
                      label="公開範囲"
                      // readOnly={isProcessing}
                      required
                      name={name}
                      value={value}
                      options={[-1, 0, 1].map((value) => ({
                        value,
                        label: getMasterPlanPublicRangeLabel(value),
                      }))}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </div>

            <div className="mt-6">
              <InputText
                required
                label="コード"
                name="code"
                defaultValue={get(record, 'code')}
                inputRef={register({ required: true })}
                error={errors.code && errors.code.message}
              />
            </div>
            <div className="mt-6">
              <InputText
                required
                label="プラン名"
                name="name"
                defaultValue={get(record, 'name')}
                inputRef={register({ required: true })}
                error={errors.name && errors.name.message}
              />
            </div>

            <div className="mt-6">
              <InputTextArea
                label="説明"
                name="description"
                defaultValue={get(record, 'description')}
                inputRef={register({})}
                error={errors.description && errors.description.message}
              />
            </div>

            <div className="mt-6">
              <Controller
                name="tools"
                rules={{ required: true }}
                control={control}
                render={({ onChange, name, value }) => (
                  <>
                    <Select
                      label="ツール"
                      // readOnly={isProcessing}
                      required
                      name={name}
                      value={value}
                      options={Object.entries(TeamTool).map(([, value]) => ({
                        value,
                        label: getTeamToolLabel(value),
                      }))}
                      onChange={onChange}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                  </>
                )}
              />
            </div>

            <div className="mt-6">
              <InputText
                required
                type="number"
                label="人数（目安であり、実際に制限はかかりません）"
                name="limit"
                defaultValue={get(record, 'limit')}
                inputRef={register({ required: true })}
                error={errors.limit && errors.limit.message}
              />
            </div>

            <div className="mt-6">
              <InputText
                required
                type="number"
                label="価格"
                name="price"
                defaultValue={get(record, 'price')}
                inputRef={register({ required: true })}
                error={errors.price && errors.price.message}
              />
            </div>
            {/* { label: 'コード', field: 'code' },
        { label: 'プラン名', field: 'name' },
        { label: '説明', field: 'description' },
        {
          label: 'ツール',
          // eslint-disable-next-line react/display-name
          field: (record: Master) => (
            <>
              {record.tools &&
                record.tools.map((t, index) => (
                  <div className="flex gap-2" key={`master-item-team-plan-${index}`}>
                    {t}
                  </div>
                ))}
            </>
          ),
        },
        { label: '人数', field: 'limit' },
        { label: '価格', field: 'price' }, */}
          </>
        )

      case MasterType.TeamBuildingQ2Keyword:
        return (
          <>
            <div className="mt-6">
              <InputText
                required
                label="タイトル"
                name="title"
                defaultValue={get(record, 'title')}
                inputRef={register({ required: true })}
                error={errors.title && errors.title.message}
              />
            </div>
          </>
        )

      case MasterType.TeamBuildingQ4Keyword:
        return (
          <>
            <div className="mt-6">
              <InputText
                required
                label="タイトル"
                name="title"
                defaultValue={get(record, 'title')}
                inputRef={register({ required: true })}
                error={errors.title && errors.title.message}
              />
            </div>
          </>
        )

      case MasterType.TeamBuildingQ5Action:
        return (
          <>
            <div className="mt-6">
              <InputText
                required
                label="タイトル"
                name="title"
                defaultValue={get(record, 'title')}
                inputRef={register({ required: true })}
                error={errors.title && errors.title.message}
              />
            </div>
            <div className="mt-6">
              <InputTextArea
                label="理由"
                name="reason"
                defaultValue={get(record, 'reason')}
                inputRef={register({})}
              />
            </div>
            <div className="mt-6">
              <InputText
                label="内容"
                name="detail"
                defaultValue={get(record, 'detail')}
                inputRef={register({})}
              />
            </div>
            <div className="mt-6">
              <InputText
                label="タイミング"
                name="timing"
                defaultValue={get(record, 'timing')}
                inputRef={register({})}
              />
            </div>
          </>
        )
      case MasterType.TeamBuildingReviewIceBreakCategory:
        return (
          <>
            <div className="mt-6">
              <InputText
                required
                label="カテゴリ名"
                name="name"
                defaultValue={get(record, 'name')}
                inputRef={register({ required: true })}
                error={errors.name && errors.name.message}
              />
            </div>
            <div className="mt-6">
              <InputTextArea
                label="説明"
                name="description"
                defaultValue={get(record, 'description')}
                inputRef={register({})}
                error={errors.description && errors.description.message}
              />
            </div>
          </>
        )
      case MasterType.TeamBuildingReviewIceBreak:
        return (
          <>
            <div className="mt-6">
              <div className="hidden">
                <InputText
                  name="parentType"
                  defaultValue={MasterType.TeamBuildingReviewIceBreakCategory}
                  inputRef={register({ required: true })}
                />
              </div>

              <Controller
                name="parentId"
                rules={{ required: true }}
                defaultValue={get(record, 'parentId')}
                control={control}
                render={({ onChange, name, value }) => (
                  <>
                    <Select
                      label="カテゴリ"
                      // readOnly={isProcessing}
                      required
                      name={name}
                      value={value}
                      options={iceBreakCategories.map((m) => ({
                        value: m.id,
                        label: m.name,
                      }))}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </div>
            <div className="mt-6">
              <InputText
                required
                label="タイトル"
                name="name"
                defaultValue={get(record, 'name')}
                inputRef={register({ required: true })}
                error={errors.name && errors.name.message}
              />
            </div>
            <div className="mt-6">
              <InputTextArea
                label="説明"
                name="description"
                defaultValue={get(record, 'description')}
                inputRef={register({})}
                error={errors.description && errors.description.message}
              />
            </div>
          </>
        )

      default:
        return <></>
    }
  }

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedType}
        {masterId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <NativeSelect
            label="タイプ"
            readOnly={isProcessing || !!masterId}
            name="type"
            defaultValue={get(record, 'type') || ''}
            options={EditableMasterTypeList().map(([, value]) => ({
              value,
              label: getMasterTypeLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            label="順番"
            name="sort"
            type="number"
            defaultValue={get(record, 'sort') || '0'}
            inputRef={register({ required: true })}
            error={errors.sort && errors.sort.message}
          />
        </div>

        {getFields()}

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.Masters}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!masterId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      <Panel
        title={
          <>
            <Link className="text-primary underline" to={replacePathParams(Paths.Masters)}>
              マスター一覧
            </Link>
            <span> / マスター{!masterId ? '作成' : '編集'}</span>
          </>
        }
      >
        {loading ? <RenderProgress /> : renderForm()}
      </Panel>
    </div>
  )
}

const getToolOptions = (teamTools: TeamTool[]) => {
  const result: SelectOption[] = []

  for (const value of teamTools) {
    result.push({
      value,
      label: getTeamToolLabel(value),
    })
  }

  return result
}

const getPublicRangeOption = (publicRange?: number | null) => {
  if (publicRange) {
    return {
      value: publicRange.toString(),
      label: getMasterPlanPublicRangeLabel(publicRange),
    }
  } else {
    return {
      value: (-1).toString(),
      label: getMasterPlanPublicRangeLabel(-1),
    }
  }
}
