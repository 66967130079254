import * as React from 'react'
import FormItem from './_layouts/FormItem'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface IOption {
  value: string
  label: string
}
interface IProps {
  name: string
  options: readonly IOption[]
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  readOnly?: boolean
  selectRef?: any
  onChangeHandler?: (event: any) => void
  onBlurHandler?: (event: any) => void
  multiple?: boolean
  disabled?: boolean
}

const Select: React.FC<IProps> = ({
  name,
  options,
  defaultValue,
  selectRef,
  label = '',
  error = null,
  required = false,
  readOnly = false,
  onChangeHandler = () => {},
  onBlurHandler = () => {},
  multiple = false,
  disabled = false,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <label className="relative block">
        <select
          className={classNames([
            'appearance-none m-0 bg-transparent outline-none text-current',
            'relative z-1 w-full py-1 px-3 pr-10 leading-8 rounded-md border-2 border-transparent',
            'focus:shadow-none focus:border-2 focus:border-primary',
            readOnly && 'text-neutral-500 pointer-events-none',
          ])}
          id={name}
          name={name}
          required={required}
          defaultValue={defaultValue}
          ref={selectRef || null}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          multiple={multiple}
          disabled={disabled}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={readOnly && defaultValue !== option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        {/* for focus style of select*/}
        <div className="absolute inset-0 rounded-md border border-neutral-300 pointer-events-none" />
        <div className="absolute right-0 inset-y-0 flex items-center mr-4">
          <FontAwesomeIcon className="text-sm" icon={faChevronDown} />
        </div>
      </label>
    </FormItem>
  )
}

export default Select
