export const QueryGetAdminList = `query GetOnboardingAdminList($after: String) {
  getOnboardingAdminList(after: $after) {
    items {
      email
      emailVerified
      sub
      createdAt
      updatedAt
    }
    nextToken
  }
}`

export const MutationCreateAdmin = `mutation CreateOnboardingAdmin($input: OnboardingAdminCreateInput) {
  createOnboardingAdmin(input: $input)
}`
