import { MasterType } from '@/stores/graphql'

const excludeEditableMasterType = [MasterType.Maintain]

export const EditableMasterTypeList = () => {
  return Object.entries(MasterType).filter(([, type]) => {
    if (excludeEditableMasterType.includes(type)) {
      return false
    }
    return true
  })
}
