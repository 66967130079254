import React, { useContext } from 'react'
import { TeamBuildingAction } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import moment from 'moment'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useParams } from 'react-router-dom'
import { SnackbarContext } from '@/components/Snackbar'
import {
  useTeamBuildingActionManage,
  useTeamsBuildingsActions,
} from '@/hooks/teamsBuildingsActions'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '責任者',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingAction) => (
      <span className="whitespace-no-wrap">{record.assignedTeamMemberName}</span>
    ),
  },
  {
    label: 'アクション名',
    field: 'name',
  },
  {
    label: '理由',
    field: 'reason',
  },
  {
    label: '内容',
    field: 'content',
  },
  {
    label: '推奨タイミング',
    field: 'timing',
  },
  {
    label: '編集可否',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingAction) => (
      <span className="whitespace-no-wrap">{record.editable > 0 ? '可' : '不'}</span>
    ),
  },
  {
    label: '作成日',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingAction) => (
      <span className="whitespace-no-wrap">{moment(record.createdAt).format('YYYY-MM-DD')}</span>
    ),
  },
]

export const GeneralTeamsBuildingsActions: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  const { openSnackbar } = useContext(SnackbarContext)

  const { team } = useTeam(teamId)
  const { records, nextToken, loading, more, refresh } = useTeamsBuildingsActions(teamId)
  const { deleteTeamBuildingAction } = useTeamBuildingActionManage()

  const handleDelete = async (id: string) => {
    if (window.confirm('本当に削除しますか?')) {
      const deleted = await deleteTeamBuildingAction(id)
      if (deleted) {
        openSnackbar({ type: 'success', message: '削除しました' })
        refresh()
      } else {
        openSnackbar({ type: 'error', message: '削除に失敗しました' })
      }
    }
  }

  return (
    <IndexLayout
      fetching={loading}
      title={`${team?.name || ''} - チームビルディング - アクション一覧`}
      data={records}
      columns={COLUMNS}
      createPath={
        team ? replacePathParams(Paths.TeamBuildingsActionsNew, { teamId: team.id }) : undefined
      }
      editPath={(teamBuildingActionId: string) =>
        team
          ? replacePathParams(Paths.TeamBuildingsActionsEdit, {
              teamId: team.id,
              teamBuildingActionId,
            })
          : undefined
      }
      handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
    />
  )
}
