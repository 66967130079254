import React from 'react'

import DataTable, { Column } from '@/components/DataTable'
import dayjs from 'dayjs'
import { OnboardingActionsSummary } from '@/types/type'
import MiniDataTable, { MiniDataTableValues } from '../atoms/MiniDataTable'

interface IProps {
  onboardingUsersSummaryData: OnboardingActionsSummary.Data | null | undefined
  fetching: boolean
}
const startedAtFromRecord = ({ startedAt }: any) => dayjs(startedAt).format('YYYY-MM-DD')
const valueOrEmpty = (value: string | number | null | undefined) => {
  return value === null || value === undefined ? '-' : value
}

const answersField = (usersSummary: OnboardingActionsSummary.UsersSummary) => {
  const values = [
    { label: '回答A', value: usersSummary.answer1 },
    { label: '回答B', value: usersSummary.answer2 },
    { label: '回答C', value: usersSummary.answer3 },
    { label: '回答D', value: usersSummary.answer4 },
    { label: '回答E', value: usersSummary.answer5 },
  ]
  return <MiniDataTable values={values} inline />
}

const likeField = (usersSummary: OnboardingActionsSummary.UsersSummary) => {
  const values = [
    { label: 'した数', value: usersSummary.like },
    { label: 'された数', value: usersSummary.liked },
  ]
  return <MiniDataTable values={values} inline />
}
const commentField = (usersSummary: OnboardingActionsSummary.UsersSummary) => {
  const values = [
    { label: 'した数', value: usersSummary.comment },
    { label: 'された数', value: usersSummary.commented },
  ]
  return <MiniDataTable values={values} inline />
}

const USERS_COLUMNS: Column[] = [
  { label: '名前', field: 'name' },
  { label: '参加日', field: startedAtFromRecord },

  { label: '回答', field: answersField },

  { label: '対象アクション数', field: 'targetCount' },
  { label: '達成数', field: 'answerCount' },
  { label: '全体達成率', field: 'totalRate' },
  { label: '達成率', field: 'rate' },
  { label: '平均', field: 'average' },
  { label: 'お気に入り', field: likeField },
  { label: 'コメント', field: commentField },
]

const OnbordingActionsSummary: React.VFC<IProps> = ({ onboardingUsersSummaryData, fetching }) => {
  const { actions, users, summary } = onboardingUsersSummaryData || {
    actions: null,
    users: null,
    summary: null,
  }

  const actionsValue = actions
    ? Object.entries(actions).map(([day, value]) => {
        return { id: day, day, ...value }
      })
    : []

  const usersValues = users
    ? Object.entries(users).map(([id, value]) => {
        return { id, ...value }
      })
    : []

  const actionColumns: Column[] = React.useMemo(
    () => [
      { label: '名前', field: 'name' },
      { label: '達成率', field: ({ summary }: any) => summary.rate },
      { label: '平均', field: ({ summary }: any) => summary.average },
      { label: '回答数', field: ({ summary }: any) => summary.answerCount },

      ...usersValues.map((user) => {
        return {
          label: user.name,
          field: ({ users }: any) => {
            return valueOrEmpty(users[user.id]?.value)
          },
        }
      }),
    ],
    [usersValues]
  )
  const inlineTableData: MiniDataTableValues[] = React.useMemo(() => {
    return summary
      ? [
          {
            label: '対象アクション数',
            value: summary.targetCount,
          },
          {
            label: '達成数',
            value: summary.answerCount,
          },
          {
            label: '達成率',
            value: summary.rate,
          },
        ]
      : []
  }, [summary])

  return (
    <div className="py-2">
      <h2 className="text-xl font-bold">アクション</h2>
      <MiniDataTable values={inlineTableData} />
      <DataTable
        title="ユーザー別"
        columns={USERS_COLUMNS}
        data={usersValues}
        fetching={fetching}
      />
      <DataTable title="詳細" columns={actionColumns} data={actionsValue} fetching={fetching} />
    </div>
  )
}

export default OnbordingActionsSummary
