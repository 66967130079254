import classNames from 'classnames'
import * as React from 'react'
import FormItem from './_layouts/FormItem'

interface IProps {
  name: string
  defaultValue?: string | number | readonly string[] | null
  readOnly?: boolean
  label?: null | string
  placeholder?: string
  error?: null | string
  required?: boolean
  inputRef?: any
}

const InputTextArea: React.FC<IProps> = ({
  name,
  defaultValue,
  inputRef,
  error = null,
  label = '',
  required = false,
  placeholder = '',
  readOnly = false,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <textarea
        className={classNames([
          'block w-full h-40 px-3 py-2 bg-neutral-100 rounded outline-none',
          'border-2 border-transparent',
          !readOnly && 'focus:border-2 focus:border-primary focus:bg-white',
          readOnly && 'text-neutral-500 cursor-default',
        ])}
        id={name}
        name={name}
        placeholder={placeholder}
        required={required ? true : false}
        defaultValue={defaultValue ?? ''}
        ref={inputRef || null}
        readOnly={readOnly ?? false}
      />
    </FormItem>
  )
}

export default InputTextArea
