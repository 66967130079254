import React from 'react'
import { TeamBuildingReviewMember } from '@/stores/graphql'
import IndexLayout from '@/layouts/index/index.component'
import moment from 'moment'
import { useTeam } from '@/hooks/teams'
import { useParams } from 'react-router-dom'
import { useTeamsBuildingReviewMembers } from '@/hooks/teamsBuildingsReviews'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '回答',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingReviewMember) => (
      <span className="whitespace-no-wrap">
        {record.answers.map((a, index) => (
          <div key={`a-key-${index}`}>
            {a.type}: {a.value}
          </div>
        ))}
      </span>
    ),
  },

  {
    label: '作成日',
    // eslint-disable-next-line react/display-name
    field: (record: TeamBuildingReviewMember) => (
      <span className="whitespace-no-wrap">{moment(record.createdAt).format('YYYY-MM-DD')}</span>
    ),
  },
]

export const GeneralTeamsBuildingsReviewMembers: React.FC = () => {
  const { teamId, reviewId } = useParams<{ teamId: string; reviewId: string }>()

  const { team } = useTeam(teamId)
  const { items, nextToken, loading, more } = useTeamsBuildingReviewMembers(reviewId)

  return (
    <IndexLayout
      fetching={loading}
      title={`${team?.name || ''}の振り返り 回答一覧`}
      data={items}
      columns={COLUMNS}
      onClickReadMore={nextToken ? more : undefined}
    />
  )
}
