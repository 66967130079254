import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Paths } from '@/constants'
import { connector, ContainerProps } from './index.container'

const PrivateRoute: React.FC<RouteComponentProps & ContainerProps> = ({
  user,
  getCurrentUser,
  history,
  children,
}) => {
  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  useEffect(() => {
    if (
      // Fetch failed
      user.error ||
      // Sign out
      (user.fetched && !user.error && !user.data)
    ) {
      if (
        // Auth unnecessary
        // 別の方法ないか
        [Paths.Restore, Paths.ResetPassword].includes(window.location.pathname as Paths)
      ) {
        return
      }
      history.push(Paths.SignIn)
    }
  }, [user, history])

  return user.data ? <>{children}</> : null
}

export default withRouter(connector(PrivateRoute))
