import gql from 'graphql-tag'
import { API, GraphQLResult } from '@aws-amplify/api'
import {
  Invoice,
  InvoiceConnection,
  InvoiceDetail,
  Master,
  MasterConnection,
  MutationAdminCreateTeamArgs,
  MutationAdminUpdateInvoiceArgs,
  MutationAdminUpdateUserArgs,
  MutationCreateMasterArgs,
  MutationCreateTeamBuildingActionArgs,
  MutationCreateTeamBuildingActionDateArgs,
  MutationCreateTeamBuildingReviewArgs,
  MutationCreateTeamMemberArgs,
  MutationDeleteMasterArgs,
  MutationDeleteTeamBuildingActionArgs,
  MutationDeleteTeamBuildingActionDateArgs,
  MutationDeleteTeamBuildingArgs,
  MutationDeleteTeamMemberArgs,
  MutationReSendInvitationEmailArgs,
  MutationUpdateMasterArgs,
  MutationUpdateTeamArgs,
  MutationUpdateTeamBuildingActionArgs,
  MutationUpdateTeamBuildingActionDateArgs,
  MutationUpdateTeamBuildingArgs,
  MutationUpdateTeamBuildingReviewArgs,
  MutationUpdateTeamMemberArgs,
  MutationUpdateTeamPlanArgs,
  QueryAdminGetInvoiceArgs,
  QueryAdminGetInvoiceDetailsArgs,
  QueryAdminGetInvoicesArgs,
  QueryAdminGetTeamsArgs,
  QueryAdminGetTeamsByEmailArgs,
  QueryAdminGetUsersArgs,
  QueryGetMasterArgs,
  QueryGetMastersArgs,
  QueryGetTeamArgs,
  QueryGetTeamBuildingActionArgs,
  QueryGetTeamBuildingActionDateArgs,
  QueryGetTeamBuildingActionDatesArgs,
  QueryGetTeamBuildingActionsArgs,
  QueryGetTeamBuildingArgs,
  QueryGetTeamBuildingReviewArgs,
  QueryGetTeamBuildingReviewMembersArgs,
  QueryGetTeamBuildingReviewsArgs,
  QueryGetTeamBuildingReviewSummaryArgs,
  QueryGetTeamMemberArgs,
  QueryGetTeamMembersArgs,
  QueryGetUserArgs,
  Team,
  TeamBuilding,
  TeamBuildingAction,
  TeamBuildingActionConnection,
  TeamBuildingActionDate,
  TeamBuildingActionDateConnection,
  TeamBuildingReview,
  TeamBuildingReviewConnection,
  TeamBuildingReviewMemberConnection,
  TeamBuildingReviewSummary,
  TeamConnection,
  TeamMember,
  TeamMemberConnection,
  User,
  UserConnection,
} from '@/stores/graphql'

export const queryGetUsers = async (args: QueryAdminGetUsersArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetUsers($filter: UserFilter, $limit: Int, $nextToken: String) {
        adminGetUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            nickname
            fullName
            username
            email
            statusAdmin

            phone
            companyName
            companyPhone
            companyZipCode
            companyPrefecture
            companyAddress1
            companyAddress2
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetUsers: UserConnection }>

  return response.data?.adminGetUsers
}

export const queryGetUser = async (args: QueryGetUserArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getUser($id: ID!) {
        getUser(id: $id) {
          id
          nickname
          email
          onboardingUsageStatus
          # Team infos ====>
          statusAdmin
          username
          fullName
          phone
          companyIconUrl
          companyName
          companyZipCode
          companyPrefecture
          companyAddress1
          companyAddress2
          companyPhone
          companyManagerName
          contractAt
          onboardingContractAt
          billingCode
          # <==== Team infos
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getUser: User }>

  return response.data?.getUser
}

export const mutationUpdateUser = async (args: MutationAdminUpdateUserArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation adminUpdateUser($input: AdminUpdateUserInput!) {
        adminUpdateUser(input: $input) {
          id
          nickname
          email
          # Team infos ====>
          statusAdmin
          username
          fullName
          phone
          companyIconUrl
          companyName
          companyZipCode
          companyPrefecture
          companyAddress1
          companyAddress2
          companyPhone
          companyManagerName
          contractAt
          onboardingContractAt
          billingCode
          # <==== Team infos
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminUpdateUser: User }>

  return response.data?.adminUpdateUser
}

export const queryGetTeams = async (args: QueryAdminGetTeamsArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetTeams($filter: TeamFilter, $limit: Int, $nextToken: String) {
        adminGetTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name

            ownerUserId
            ownerUsername
            ownerFullName

            # leaderUserId
            # leaderUserEmail
            # paymentLast4Digits
            # paymentToken
            # teamPlanId
            # teamContractId
            countMember
            # teamPlan
            # teamContract

            # Status
            statusAdmin
            paymentMethod
            statusUsage
            plans
            userLimit

            createdAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetTeams: TeamConnection }>

  return response.data?.adminGetTeams
}

export const queryGetTeamsByEmail = async (args: QueryAdminGetTeamsByEmailArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetTeamsByEmail($email: ID!) {
        adminGetTeamsByEmail(email: $email) {
          items {
            id
            name

            ownerUserId
            ownerUsername
            ownerFullName

            countMember

            # Status
            statusAdmin
            paymentMethod
            statusUsage
            plans
            userLimit

            createdAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetTeamsByEmail: TeamConnection }>

  return response.data?.adminGetTeamsByEmail
}

export const queryGetTeam = async (args: QueryGetTeamArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetTeam($id: ID!) {
        adminGetTeam(id: $id) {
          id
          name

          ownerUserId
          ownerUsername
          ownerFullName

          countMember

          paymentMethod
          statusUsage
          plans

          userLimit
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetTeam: Team }>

  return response.data?.adminGetTeam
}

export const mutationCreateTeam = async (args: MutationAdminCreateTeamArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation adminCreateTeam($input: AdminCreateTeamInput!) {
        adminCreateTeam(input: $input) {
          id
          name

          countMember

          paymentMethod
          statusUsage
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminCreateTeam: Team }>

  return response.data?.adminCreateTeam
}

export const mutationUpdateTeam = async (args: MutationUpdateTeamArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation adminUpdateTeam($input: AdminUpdateTeamInput!) {
        adminUpdateTeam(input: $input) {
          id
          name

          countMember

          paymentMethod
          statusUsage
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminUpdateTeam: Team }>

  return response.data?.adminUpdateTeam
}

// export const queryGetOnboardingOwnTeamMemberList = async (
//   props: QueryGetOnboardingOwnTeamMemberListRequest
// ) => {
//   const response: any = await API.graphql({
//     query: QueryGetOnboardingOwnTeamMemberList,
//     variables: { ...props },
//   })

//   return response.data as QueryGetOnboardingOwnTeamMemberListResponse
// }

export const queryGetTeamMembers = async (args: QueryGetTeamMembersArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamMembers(
        $teamId: ID!
        $filter: TeamMemberFilter
        $limit: Int
        $nextToken: String
      ) {
        getTeamMembers(teamId: $teamId, filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            userId
            # teamUserId
            role
            fullName
            username
            organization
            position
            email
            statusUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamMembers: TeamMemberConnection }>

  return response.data?.getTeamMembers
}

export const queryGetTeamMember = async (args: QueryGetTeamMemberArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamMember($id: ID!) {
        getTeamMember(id: $id) {
          id
          teamId
          # userId
          # teamUserId
          role
          fullName
          username
          organization
          position
          email
          statusUsage
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamMember: TeamMember }>

  return response.data?.getTeamMember
}

export const mutationCreateTeamMember = async (args: MutationCreateTeamMemberArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamMember($input: CreateTeamMemberInput!) {
        createTeamMember(input: $input) {
          id
          teamId
          # userId
          # teamUserId
          role
          fullName
          username
          organization
          position
          email
          statusUsage
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createTeamMember: TeamMember }>

  return response.data?.createTeamMember
}

export const mutationUpdateTeamMember = async (args: MutationUpdateTeamMemberArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamMember($input: UpdateTeamMemberInput!) {
        updateTeamMember(input: $input) {
          id
          teamId
          # userId
          # teamUserId
          role
          fullName
          username
          organization
          position
          email
          statusUsage
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamMember: TeamMember }>

  return response.data?.updateTeamMember
}

export const mutationDeleteTeamMember = async (args: MutationDeleteTeamMemberArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteTeamMember($id: ID!) {
        deleteTeamMember(id: $id)
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteTeamMember: boolean }>

  return response.data?.deleteTeamMember
}

export const mutationUpdateTeamPlan = async (
  args: MutationUpdateTeamPlanArgs
): Promise<Team | undefined> => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamPlan($input: UpdateTeamPlanInput!) {
        updateTeamPlan(input: $input) {
          id
          name
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamPlan: Team }>

  return response.data?.updateTeamPlan
}

export const mutationReSendInvitationEmail = async (args: MutationReSendInvitationEmailArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation reSendInvitationEmail($id: ID!) {
        reSendInvitationEmail(id: $id)
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ reSendInvitationEmail: boolean }>

  return response.data?.reSendInvitationEmail
}

export const queryGetInvoices = async (args: QueryAdminGetInvoicesArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetInvoices(
        $filter: InvoiceFilter
        $order: InvoiceOrder
        $limit: Int
        $nextToken: String
      ) {
        adminGetInvoices(filter: $filter, order: $order, limit: $limit, nextToken: $nextToken) {
          items {
            id
            userId
            userEmail
            userPhone
            userFullName
            username

            amount
            cost
            discount

            status
            date

            user {
              companyName
            }

            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetInvoices: InvoiceConnection }>

  return response.data?.adminGetInvoices
}

export const queryGetInvoice = async (args: QueryAdminGetInvoiceArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetInvoice($id: ID!) {
        adminGetInvoice(id: $id) {
          id
          type
          userId
          userEmail
          userPhone
          userFullName
          username

          amount
          cost
          discount

          status
          date

          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetInvoice: Invoice }>

  return response.data?.adminGetInvoice
}

export const queryGetInvoiceDetails = async (args: QueryAdminGetInvoiceDetailsArgs) => {
  const response = (await API.graphql({
    query: gql`
      query adminGetInvoiceDetails($invoiceId: ID!) {
        adminGetInvoiceDetails(invoiceId: $invoiceId) {
          id
          invoiceId
          teamId
          teamName
          method
          discountType
          cost
          discount
          plans
          dummyHash
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminGetInvoiceDetails: InvoiceDetail[] }>

  return response.data?.adminGetInvoiceDetails
}

export const mutationUpdateInvoice = async (args: MutationAdminUpdateInvoiceArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation adminUpdateInvoice($input: UpdateInvoiceInput!) {
        adminUpdateInvoice(input: $input) {
          id
          type
          userId
          userEmail
          userPhone
          userFullName
          username

          cost
          discount
          status
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ adminUpdateInvoice: Invoice }>

  return response.data?.adminUpdateInvoice
}

export const queryGetTeamBuilding = async (args: QueryGetTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuilding($id: ID!) {
        getTeamBuilding(id: $id) {
          id
          status
          q1PresenNumber
          q1PresenUserIds
          resultQ1
          resultQ2
          resultQ3
          resultQ4
          endAt
          createdAt
          updatedAt

          anketStartAt
          anketSpan
          anketLimit
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuilding: TeamBuilding }>

  return response.data?.getTeamBuilding
}

export const mutationUpdateTeamBuilding = async (args: MutationUpdateTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuilding($input: UpdateTeamBuildingInput!) {
        updateTeamBuilding(input: $input) {
          id
          status
          q1PresenNumber
          q1PresenUserIds
          resultQ1
          resultQ2
          resultQ3
          resultQ4
          endAt
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamBuilding: TeamBuilding }>

  return response.data?.updateTeamBuilding
}

export const mutationDeleteTeamBuilding = async (args: MutationDeleteTeamBuildingArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteTeamBuilding($id: ID!) {
        deleteTeamBuilding(id: $id) {
          id
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteTeamBuilding: TeamBuilding }>

  return response.data?.deleteTeamBuilding
}

// Masters
export const queryGetMasters = async (args: QueryGetMastersArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getMasters($type: MasterType!, $limit: Int, $nextToken: String) {
        getMasters(type: $type, limit: $limit, nextToken: $nextToken) {
          items {
            id
            type
            sort
            title
            reason
            detail
            timing
            code
            name
            description
            businessName
            businessDescription
            tools
            limit
            price
            publicRange
            parentType
            parentId
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getMasters: MasterConnection }>

  return response.data?.getMasters
}

export const queryGetMaster = async (args: QueryGetMasterArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getMaster($id: ID!) {
        getMaster(id: $id) {
          id
          type
          sort
          title
          reason
          detail
          timing
          code
          name
          description
          businessName
          businessDescription
          tools
          limit
          price
          publicRange
          parentType
          parentId
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getMaster: Master }>

  return response.data?.getMaster
}

export const mutationCreateMaster = async (args: MutationCreateMasterArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation createMaster($input: CreateMasterInput!) {
        createMaster(input: $input) {
          id
          type
          sort
          title
          reason
          detail
          timing
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createMaster: Master }>

  return response.data?.createMaster
}

export const mutationUpdateMaster = async (args: MutationUpdateMasterArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateMaster($input: UpdateMasterInput!) {
        updateMaster(input: $input) {
          id
          type
          sort
          title
          reason
          detail
          timing
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateMaster: Master }>

  return response.data?.updateMaster
}

export const mutationDeleteMaster = async (args: MutationDeleteMasterArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteMaster($id: ID!) {
        deleteMaster(id: $id) {
          id
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteMaster: boolean }>

  return response.data?.deleteMaster
}

//
//
//
export const queryGetTeamBuildingActions = async (args: QueryGetTeamBuildingActionsArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingActions($teamId: ID!, $limit: Int, $nextToken: String) {
        getTeamBuildingActions(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            assignedTeamMemberId
            assignedTeamMemberName
            name
            reason
            content
            timing
            editable
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingActions: TeamBuildingActionConnection }>

  return response.data?.getTeamBuildingActions
}

export const queryGetTeamBuildingAction = async (args: QueryGetTeamBuildingActionArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingAction($id: ID!) {
        getTeamBuildingAction(id: $id) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingAction: TeamBuildingAction }>

  return response.data?.getTeamBuildingAction
}

export const mutationCreateTeamBuildingAction = async (
  args: MutationCreateTeamBuildingActionArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamBuildingAction($input: CreateTeamBuildingActionInput!) {
        createTeamBuildingAction(input: $input) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createTeamBuildingAction: TeamBuildingAction }>

  return response.data?.createTeamBuildingAction
}

export const mutationUpdateTeamBuildingAction = async (
  args: MutationUpdateTeamBuildingActionArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingAction($input: UpdateTeamBuildingActionInput!) {
        updateTeamBuildingAction(input: $input) {
          id
          teamId
          assignedTeamMemberId
          assignedTeamMemberName
          name
          reason
          content
          timing
          editable
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamBuildingAction: TeamBuildingAction }>

  return response.data?.updateTeamBuildingAction
}

export const mutationDeleteTeamBuildingAction = async (
  args: MutationDeleteTeamBuildingActionArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteTeamBuildingAction($id: ID!) {
        deleteTeamBuildingAction(id: $id) {
          id
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteTeamBuildingAction: TeamBuildingAction }>

  return response.data?.deleteTeamBuildingAction
}

//
//
export const queryGetTeamBuildingActionDates = async (
  args: QueryGetTeamBuildingActionDatesArgs
) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingActionDates(
        $id: ID!
        $index: IndexGetTeamBuildingActionDates!
        $limit: Int
        $nextToken: String
      ) {
        getTeamBuildingActionDates(id: $id, index: $index, limit: $limit, nextToken: $nextToken) {
          items {
            id
            teamId
            teamBuildingActionId
            name
            assignedTeamMemberId
            assignedTeamMemberName
            date
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingActionDates: TeamBuildingActionDateConnection }>

  return response.data?.getTeamBuildingActionDates
}

export const queryGetTeamBuildingActionDate = async (args: QueryGetTeamBuildingActionDateArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingActionDate($id: ID!) {
        getTeamBuildingActionDate(id: $id) {
          id
          teamId
          teamBuildingActionId
          name
          assignedTeamMemberId
          assignedTeamMemberName
          date
          status
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingActionDate: TeamBuildingActionDate }>

  return response.data?.getTeamBuildingActionDate
}

export const mutationCreateTeamBuildingActionDate = async (
  args: MutationCreateTeamBuildingActionDateArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamBuildingActionDate($input: CreateTeamBuildingActionDateInput!) {
        createTeamBuildingActionDate(input: $input) {
          id
          teamId
          teamBuildingActionId
          name
          assignedTeamMemberId
          assignedTeamMemberName
          date
          status
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createTeamBuildingActionDate: TeamBuildingActionDate }>

  return response.data?.createTeamBuildingActionDate
}

export const mutationUpdateTeamBuildingActionDate = async (
  args: MutationUpdateTeamBuildingActionDateArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingActionDate($input: UpdateTeamBuildingActionDateInput!) {
        updateTeamBuildingActionDate(input: $input) {
          id
          teamId
          teamBuildingActionId
          name
          assignedTeamMemberId
          assignedTeamMemberName
          date
          status
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamBuildingActionDate: TeamBuildingActionDate }>

  return response.data?.updateTeamBuildingActionDate
}

export const mutationDeleteTeamBuildingActionDate = async (
  args: MutationDeleteTeamBuildingActionDateArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteTeamBuildingActionDate($id: ID!) {
        deleteTeamBuildingActionDate(id: $id) {
          id
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteTeamBuildingActionDate: TeamBuildingActionDate }>

  return response.data?.deleteTeamBuildingActionDate
}

// 振り返り
export const queryGetTeamBuildingReviewSummary = async (
  args: QueryGetTeamBuildingReviewSummaryArgs
) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingReviewSummary($teamId: ID!) {
        getTeamBuildingReviewSummary(teamId: $teamId) {
          id
          histories {
            date
            averages {
              type
              index
              average
            }
            createdAt
          }
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingReviewSummary: TeamBuildingReviewSummary }>

  return response.data?.getTeamBuildingReviewSummary
}

export const queryGetTeamBuildingReviews = async (args: QueryGetTeamBuildingReviewsArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingReviews($teamId: ID!, $limit: Int, $nextToken: String) {
        getTeamBuildingReviews(teamId: $teamId, limit: $limit, nextToken: $nextToken) {
          items {
            id
            hash
            date
            teamId
            status
            iceBreak
            iceBreakNumber
            iceBreakUserIds
            iceBreakMinute
            # answers {

            # }
            nextAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingReviews: TeamBuildingReviewConnection }>

  return response.data?.getTeamBuildingReviews
}

export const queryGetTeamBuildingReview = async (args: QueryGetTeamBuildingReviewArgs) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingReview($id: ID!) {
        getTeamBuildingReview(id: $id) {
          id
          hash
          date
          teamId
          status
          iceBreak
          iceBreakNumber
          iceBreakUserIds
          iceBreakMinute
          # answers {

          # }
          nextAt
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingReview: TeamBuildingReview }>

  return response.data?.getTeamBuildingReview
}

export const mutationCreateTeamBuildingReview = async (
  args: MutationCreateTeamBuildingReviewArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation createTeamBuildingReview($input: CreateTeamBuildingReviewInput!) {
        createTeamBuildingReview(input: $input) {
          id
          hash
          date
          teamId
          status
          iceBreak
          iceBreakNumber
          iceBreakUserIds
          iceBreakMinute
          nextAt
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createTeamBuildingReview: TeamBuilding }>

  return response.data?.createTeamBuildingReview
}

export const mutationUpdateTeamBuildingReview = async (
  args: MutationUpdateTeamBuildingReviewArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation updateTeamBuildingReview($input: UpdateTeamBuildingReviewInput!) {
        updateTeamBuildingReview(input: $input) {
          id
          hash
          date
          teamId
          status
          iceBreak
          iceBreakNumber
          iceBreakUserIds
          iceBreakMinute
          nextAt
          createdAt
          updatedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateTeamBuildingReview: TeamBuilding }>

  return response.data?.updateTeamBuildingReview
}
export const queryGetTeamBuildingReviewMembers = async (
  args: QueryGetTeamBuildingReviewMembersArgs
) => {
  const response = (await API.graphql({
    query: gql`
      query getTeamBuildingReviewMembers(
        $teamBuildingReviewId: ID!
        $limit: Int
        $nextToken: String
      ) {
        getTeamBuildingReviewMembers(
          teamBuildingReviewId: $teamBuildingReviewId
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            teamBuildingReviewId
            hash
            date
            teamId
            userId
            teamMemberId
            answers {
              type
              index
              value
            }
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getTeamBuildingReviewMembers: TeamBuildingReviewMemberConnection }>

  return response.data?.getTeamBuildingReviewMembers
}
