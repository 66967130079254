import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Panel, InputText, Button } from '@/components/atoms'
import { useForm } from 'react-hook-form'
import { connector, ContainerProps } from './resetPassword.container'
import { IResetPassword } from '@/stores/user/interface'
import { Paths } from '@/constants'

type Props = RouteComponentProps & ContainerProps
const ResetPassword: React.FC<Props> = (props) => {
  const [processing, setProcessing] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')
    const code = params.get('code')

    if (email) {
      setValue('email', email)
    } else {
      props.history.push('/')
    }
    if (code) {
      setValue('code', code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, errors, setValue, getValues } = useForm<IResetPassword>()
  const onSubmit = async (data: IResetPassword) => {
    setProcessing(true)
    props.resetPassword(data, onSubmitSuccess, onSubmitError)
  }

  const onSubmitSuccess = (email: string) => {
    props.history.push(`${Paths.SignIn}?email=${email}`)
  }

  const onSubmitError = (error: Error) => {
    setProcessing(false)
    setErrorMsg(error.message)
  }

  return (
    <Panel className="w-104">
      <h1 className="text-2xl text-center">パスワード再設定</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <InputText
            defaultValue=""
            name="code"
            placeholder="6桁の数字を入力"
            inputRef={register({
              required: '確認コードが入力されていません',
            })}
            label="確認コード"
            error={errors.code ? errors.code.message : '6桁の数字を入力'}
          />
        </div>
        <div className="mt-6">
          <InputText
            defaultValue=""
            name="password"
            inputRef={register({
              required: '新しいパスワードが入力されていません',
              pattern: {
                value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                message: '半角英数字8〜24文字で入力してください',
              },
            })}
            label="新しいパスワード"
            type="password"
            error={errors.password ? errors.password.message : '半角英数字8文字以上'}
          />
        </div>
        <div className="mt-6">
          <InputText
            defaultValue=""
            name="password_confirm"
            inputRef={register({
              required: '新しいパスワード(再入力)が入力されていません',
              pattern: {
                value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                message: '半角英数字8〜24文字で入力してください',
              },
              validate: {
                same: (value) => value === getValues('password') || 'パスワードが一致しません。',
              },
            })}
            label="新しいパスワード(再入力)"
            type="password"
            error={errors.password_confirm ? errors.password_confirm.message : null}
          />
        </div>

        {errorMsg && (
          <div style={{ padding: '16px 16px 0', fontSize: 12, color: 'red' }}>{errorMsg}</div>
        )}

        <div className="mt-12">
          <Button full type="submit" buttonType="primary" disabled={processing}>
            再設定
          </Button>
        </div>
      </form>
    </Panel>
  )
}

export default withRouter(connector(ResetPassword))
