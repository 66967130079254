import classNames from 'classnames'
import IconClose from '@/icon/IconClose'
import React, { useState, createContext } from 'react'

type Type = 'success' | 'info' | 'error'

interface Props {
  open: boolean
  message?: string
  type?: Type
  handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string): void
}

export interface IConfig {
  size?: string
  title: null | string
  body: null | React.ReactElement
}

export const ModalContext = createContext({
  openModal: (config: IConfig) => {},
})

const initialState = {
  size: 'medium',
  title: null,
  body: null,
} as const

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [config, setConfig] = useState<IConfig>(initialState)

  const openModal = (config: IConfig) => {
    console.log('openModal', config)
    setConfig(config)
    setIsOpen(true)
  }

  const closeModal = () => {
    setConfig(initialState)
    setIsOpen(false)
  }

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      <div
        className={classNames(
          'fixed inset-0 flex items-center justify-center z-60 transition-all',
          isOpen ? 'duration-150 opacity-100 visible' : 'opacity-0 invisible'
        )}
      >
        <div className="absolute inset-0 bg-black opacity-25" onClick={closeModal} />
        <div
          className={classNames([
            'relative w-full mx-4 p-10 bg-white rounded-md',
            config.size === 'medium' && 'max-w-165',
          ])}
        >
          <div
            className="absolute top-0 right-0 mt-6 mr-6 text-3xl text-neutral-700 cursor-pointer"
            onClick={closeModal}
          >
            <IconClose />
          </div>
          <div>
            {config.title && (
              <div className="text-xl font-bold mb-5 text-center">{config.title}</div>
            )}
            <div>{config.body}</div>
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  )
}

export default ModalProvider
