import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumbs, Tooltip, Typography } from '@material-ui/core'
import { Button, Panel } from '@/components/atoms'
import { useParams } from 'react-router-dom'

import { Paths } from '@/constants'
import { Link } from 'react-router-dom'
import { getOnboardingTeam } from '@/stores/teams/api'
import { replacePathParams } from '@/utils/history'
import { OnboardingTeam } from '@/stores/graphql'
import { SelectYearMonth } from '@/components/SelectYearMonth'

import OnbordingDailySummary from '@/components/Dashboard/OnbordingDailySummary'
import OnbordingActionsSummary from '@/components/Dashboard/OnbordingActionsSummary'
import OnbordingPostSummary from '@/components/Dashboard/OnbordingPostSummary'
import { SnackbarContext } from '@/components/Snackbar'
import {
  getOnboardingActionSummary,
  getOnboardingActionSummaryCSV,
  getOnboardingDailySummary,
  getOnboardingDailySummaryCSV,
  getOnboardingPostSummary,
  getOnboardingPostSummaryCSV,
} from './hook'
import dayjs from 'dayjs'
import { csvZipDonwload, CSVs } from '@/utils/csvDownload'

interface ITeamState {
  data: OnboardingTeam | null
  fetching: boolean
}
interface IOnboadingSummary {
  data: any | null | undefined
  errors: any
  fetching: boolean
}
const INIT_SUMMARY_DATA = {
  data: null,
  errors: null,
  fetching: false,
}
export const OnboardingTeamsDashboard: React.FC = () => {
  const [yearMonth, setYearMonth] = useState<string>()
  const { teamId } = useParams<{ teamId: string }>()
  const [team, setTeam] = useState<ITeamState>({
    data: null,
    fetching: false,
  })

  const [dailySummaryData, setDailySummaryData] = useState<IOnboadingSummary>(INIT_SUMMARY_DATA)
  const [actionSummaryData, setActionSummaryData] = useState<IOnboadingSummary>(INIT_SUMMARY_DATA)
  const [postSummaryData, setPostSummaryData] = useState<IOnboadingSummary>(INIT_SUMMARY_DATA)
  const [summaryFetching, setSummaryFetching] = useState<boolean>(false)

  const { openSnackbar } = useContext(SnackbarContext)

  const getTeam = async (id: string) => {
    setTeam({ data: null, fetching: true })
    const { data: team } = await getOnboardingTeam({ id })
    setTeam({ data: team, fetching: false })
  }
  useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])
  const showErrors = React.useCallback(
    (errors: any[]) => {
      errors.forEach(({ message }: any) => openSnackbar({ type: 'error', message }))
    },
    [openSnackbar]
  )

  const getSummaryCSV = React.useCallback(async () => {
    if (!teamId || !yearMonth) {
      return
    }
    const current = dayjs(yearMonth).tz('Asia/Tokyo')
    const args = {
      teamId,
      from: current.format('YYYY-MM-01'),
      to: current.format(`YYYY-MM-${current.daysInMonth()}`),
      excludes: [],
      refresh: current.format('YYYY-MM') === dayjs().format('YYYY-MM'),
    }
    const { data: dailyCSV, errors: dailyCSVErrors } = await getOnboardingDailySummaryCSV(args)
    if (dailyCSVErrors?.length > 0) {
      showErrors(dailyCSVErrors)
    }

    const { data: actionsCSV, errors: actionsCSVErrors } = await getOnboardingActionSummaryCSV(args)
    if (actionsCSVErrors?.length > 0) {
      showErrors(actionsCSVErrors)
    }

    const { data: timelineCSV, errors: timelineCSVErrors } = await getOnboardingPostSummaryCSV(args)
    if (timelineCSVErrors?.length > 0) {
      showErrors(timelineCSVErrors)
    }

    if (!dailyCSV || !actionsCSV || !timelineCSV) {
      return
    }
    const csvs: CSVs = [
      {
        name: 'daily',
        content: dailyCSV,
      },
      {
        name: 'actions',
        content: actionsCSV,
      },
      {
        name: 'timeline',
        content: timelineCSV,
      },
    ]
    csvZipDonwload(csvs, 'onboarding-summary', yearMonth)
  }, [teamId, yearMonth, showErrors])

  const getSummary = React.useCallback(
    (refresh: boolean) => {
      if (!teamId || !yearMonth) {
        return
      }

      const onFetched = (
        result: IOnboadingSummary,
        set: React.Dispatch<React.SetStateAction<IOnboadingSummary>>
      ) => {
        if (result.errors?.length > 0) {
          showErrors(result.errors)
        }
        set(result)
      }
      const current = dayjs(yearMonth).tz('Asia/Tokyo')

      const args = {
        teamId,
        from: current.format('YYYY-MM-01'),
        to: current.format(`YYYY-MM-${current.daysInMonth()}`),
        excludes: [],
        refresh,
      }

      setSummaryFetching(true)
      setDailySummaryData({ data: null, fetching: true, errors: null })
      setActionSummaryData({ data: null, fetching: true, errors: null })
      setPostSummaryData({ data: null, fetching: true, errors: null })
      getOnboardingDailySummary(args, (result) => onFetched(result, setDailySummaryData))
      getOnboardingActionSummary(args, (result) => onFetched(result, setActionSummaryData))
      getOnboardingPostSummary(args, (result) => onFetched(result, setPostSummaryData))
    },
    [teamId, yearMonth, showErrors]
  )

  useEffect(() => {
    getSummary(false)
    // eslint-disable-next-line
  }, [yearMonth])

  useEffect(() => {
    const fetched =
      dailySummaryData.fetching || actionSummaryData.fetching || postSummaryData.fetching
    setSummaryFetching(fetched)
  }, [dailySummaryData.fetching, actionSummaryData.fetching, postSummaryData.fetching])
  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          {teamId && (
            <Link to={Paths.OnboardingTeams} className="hover:underline">
              オンボーディングチーム一覧
            </Link>
          )}
          {team.data && (
            <Link
              to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId })}
              className="hover:underline"
            >
              {team.data.title}
            </Link>
          )}
          <Typography color="primary">ダッシュボード</Typography>
        </Breadcrumbs>
        <div className="p-8">
          <div className="flex items-center justify-between flex-wrap">
            <SelectYearMonth onChange={setYearMonth} />

            <div className="flex gap-2">
              <Tooltip title="最新データを再取得します">
                <div className="w-24">
                  <Button
                    className="mr-2 "
                    size="x-small"
                    disabled={summaryFetching}
                    handleClick={() => getSummary(true)}
                  >
                    再取得
                  </Button>
                </div>
              </Tooltip>
              <Tooltip title="表示中のデータをCSVで出力します">
                <div className="w-24">
                  <Button
                    size="x-small"
                    buttonType="secondary"
                    disabled={summaryFetching}
                    handleClick={getSummaryCSV}
                  >
                    CSVで出力
                  </Button>
                </div>
              </Tooltip>
            </div>
          </div>
          <Panel className="my-2" wrapperFull contentFull>
            <OnbordingDailySummary
              onboardingDailySummaryData={dailySummaryData.data}
              fetching={dailySummaryData.fetching}
            />
          </Panel>
          <Panel className="my-2" wrapperFull contentFull>
            <OnbordingActionsSummary
              onboardingUsersSummaryData={actionSummaryData.data}
              fetching={actionSummaryData.fetching}
            />
          </Panel>
          <Panel className="my-2" wrapperFull contentFull>
            <OnbordingPostSummary
              onboardingPostSummaryData={postSummaryData.data}
              fetching={postSummaryData.fetching}
            />
          </Panel>
        </div>
      </div>
    </>
  )
}
