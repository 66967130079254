import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

type Props = {
  filePath: string
  text: string
}

export const DownloadLink: React.FC<Props> = ({ filePath, text }) => (
  <div>
    <a download href={filePath} className="text-sm text-primary cursor-pointer underline">
      <FontAwesomeIcon className="text-sm mr-1" icon={faFileDownload} />
      {text}
    </a>
  </div>
)
