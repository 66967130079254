import React from 'react'
import {
  CreateTeamBuildingActionInput,
  TeamBuildingAction,
  UpdateTeamBuildingActionInput,
} from '@/stores/graphql'
import {
  mutationCreateTeamBuildingAction,
  mutationDeleteTeamBuildingAction,
  mutationUpdateTeamBuildingAction,
  queryGetTeamBuildingAction,
  queryGetTeamBuildingActions,
} from './graphql'

export const useTeamsBuildingsActions = (teamId?: string) => {
  const limit = 1000
  const [records, setRecords] = React.useState<TeamBuildingAction[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActions({ teamId, limit })
      if (response) {
        setRecords(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!teamId || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActions({ teamId, limit, nextToken })
      if (response) {
        setRecords([...records, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, records, nextToken])

  return { records, nextToken, loading, error, refresh, more } as const
}

export const useTeamBuildingsAction = (id?: string) => {
  const [record, setRecord] = React.useState<TeamBuildingAction | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!id) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingAction({ id })
      setRecord(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [id])

  React.useEffect(() => {
    refresh()
  }, [id, refresh])

  return { record, loading, error, refresh } as const
}

export const useTeamBuildingActionManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createTeamBuildingAction = React.useCallback(
    async (
      input: CreateTeamBuildingActionInput
    ): Promise<[TeamBuildingAction | undefined, undefined | Error[]]> => {
      setLoading(true)
      try {
        const response = await mutationCreateTeamBuildingAction({ input })
        setLoading(false)

        return [response, undefined]
      } catch (e) {
        console.log('e', e)
        setErrors(e.errors)
        setLoading(false)

        return [undefined, e.errors]
      }
    },
    []
  )

  const updateTeamBuildingAction = React.useCallback(
    async (input: UpdateTeamBuildingActionInput) => {
      setLoading(true)
      try {
        const response = await mutationUpdateTeamBuildingAction({ input })

        return [response, undefined]
      } catch (e) {
        console.log('e', e)
        setErrors(e)

        return [undefined, e.errors]
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const deleteTeamBuildingAction = React.useCallback(async (id: string) => {
    setLoading(true)
    try {
      const response = await mutationDeleteTeamBuildingAction({ id })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    createTeamBuildingAction,
    updateTeamBuildingAction,
    deleteTeamBuildingAction,
    errors,
    loading,
  } as const
}
