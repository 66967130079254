import 'date-fns'
import React, { useState, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, Select } from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { TeamBuildingStatus, SelectOption } from '@/stores/graphql'
import { getTeamBuildingStatusLabel } from '@/utils/labels'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useTeamBuilding, useTeamBuildingManage } from '@/hooks/teamsBuildings'
import { mutationDeleteTeamBuilding } from '@/hooks/graphql'

type Inputs = {
  id: string
  status: SelectOption
  anketStartAt: string
  anketSpan: number
  anketLimit: number
}

export const GeneralTeamsBuildingsEdit: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  const { team } = useTeam(teamId)

  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { register, handleSubmit, control, setValue } = useForm<Inputs>()

  const { teamBuilding, loading } = useTeamBuilding(teamId)
  React.useEffect(() => {
    if (teamBuilding) {
      const value = get(teamBuilding, 'status')
      setValue('status', { value, label: getTeamBuildingStatusLabel(value) })
    }
  }, [teamBuilding, setValue])

  const { updateTeamBuilding, errors } = useTeamBuildingManage()

  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)

      const updated = await updateTeamBuilding({
        ...data,
        status: data.status.value as TeamBuildingStatus,
      })
      if (updated) {
        openSnackbar({ type: 'success', message: '更新しました' })
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  React.useEffect(() => {
    if (errors && errors.length > 0) {
      const message = get(errors, '[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    }
  }, [errors, openSnackbar])

  const [hiddenCommand, setHiddenCommand] = React.useState(false)
  const keyDownFunction = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.metaKey && e.ctrlKey && e.keyCode === 40) {
        setHiddenCommand(!hiddenCommand)
      }
    },
    [hiddenCommand]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', keyDownFunction, false)

    return () => {
      document.removeEventListener('keydown', keyDownFunction)
    }
  }, [keyDownFunction])

  const handleDelete = async () => {
    if (window.confirm('本当に削除しますか?')) {
      const deleted = await mutationDeleteTeamBuilding({
        id: teamId,
      })

      if (deleted) {
        openSnackbar({ type: 'success', message: '削除しました' })
        window.location.reload()
      } else {
        openSnackbar({ type: 'error', message: '削除に失敗しました' })
      }
    }
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <InputText readOnly label="ID" name="id" defaultValue={teamId} inputRef={register()} />
        </div>

        <div className="mt-6">
          <Controller
            name="status"
            control={control}
            render={({ onChange, name, value }) => (
              <>
                <Select
                  label="ステータス"
                  name={name}
                  value={value}
                  options={Object.entries(TeamBuildingStatus).map(([, value]) => ({
                    value,
                    label: getTeamBuildingStatusLabel(value),
                  }))}
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>

        <div className="mt-6">
          <InputText
            label="キックオフ完了日"
            name="anketStartAt"
            defaultValue={teamBuilding?.anketStartAt}
            type={'text'}
            inputRef={register()}
          />
        </div>

        {/* TBチェックの分離にともないコメントアウト */}

        {/* <div className="mt-6">
          <InputText
            label="アンケート実施間隔（X週間）"
            name="anketSpan"
            defaultValue={teamBuilding?.anketSpan || 2}
            type={'number'}
            inputProps={{ min: 2 }}
            inputRef={register()}
          />
        </div>

        <div className="mt-6">
          <InputText
            label="アンケート実施回数（X回）"
            name="anketLimit"
            defaultValue={teamBuilding?.anketLimit}
            type={'number'}
            inputRef={register()}
          />
        </div> */}

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              handleClick={() => history.goBack()}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!teamId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          title={
            <>
              <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
                チーム一覧
              </Link>
              &nbsp;/&nbsp;
              {team.name}
              <span>&nbsp;/&nbsp;チームビルディング編集</span>
            </>
          }
        >
          <div className="text-right ">
            <Link
              className="text-primary underline "
              to={replacePathParams(Paths.TeamBuildingsActions, { teamId })}
            >
              アクション一覧
            </Link>
          </div>

          {loading ? <RenderProgress /> : renderForm()}
        </Panel>
      )}

      {hiddenCommand && (
        <div className="flex justify-center p-4">
          <div onClick={handleDelete} className="text-red font-bold underline cursor-pointer">
            削除する
          </div>
        </div>
      )}
    </div>
  )
}
