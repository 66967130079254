import React, { useEffect, useContext } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Paths } from '@/constants'
import { Logo } from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button } from '@/components/atoms'
import { connector, ContainerProps } from './index.container'

type Inputs = {
  email: string
  password: string
}

const SignIn: React.FC<RouteComponentProps & ContainerProps> = ({ user, signIn, history }) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const { register, handleSubmit, errors } = useForm<Inputs>()
  const onSubmit = async (data: Inputs) => {
    const callbacks = {
      forceChangePassword: () => {
        history.push(Paths.ForceChangePassword)
      },
      success: () => {
        openSnackbar({ type: 'success', message: 'ログインしました' })
      },
      error: () => {
        openSnackbar({
          type: 'error',
          message: 'メールアドレスまたはパスワードが違います',
        })
      },
    }
    signIn({ user: data, callbacks })
  }

  // Redirect
  useEffect(() => {
    if (user.data) {
      history.push(Paths.DashBoard)
    }
  }, [user, history])

  return (
    <Panel className="w-104">
      <div className="flex justify-center items-center flex-col">
        <div className="w-64">
          <Logo />
        </div>
        <h1 className="mt-4 text-center">管理画面</h1>
      </div>
      <div className="mt-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <InputText
              defaultValue=""
              name="email"
              type="email"
              inputRef={register({
                required: 'メールアドレスを入力してください',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '無効なメールアドレスです',
                },
              })}
              label="メールアドレス"
              error={errors.email ? errors.email.message : null}
            />
          </div>
          <div className="mt-6">
            <InputText
              defaultValue=""
              name="password"
              inputRef={register({
                required: 'パスワードを入力してください',
                // pattern: {
                //   value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                //   message: '半角英数字8〜24文字で入力してください',
                // },
              })}
              label="パスワード"
              type="password"
              error={errors.password ? errors.password.message : null}
            />
            <Link className="inline-block text-xs mt-2 text-primary" to={Paths.Restore}>
              パスワードをお忘れの場合
            </Link>
          </div>
          <div className="mt-6">
            <Button type="submit" buttonType="primary" full>
              ログイン
            </Button>
          </div>
        </form>
      </div>
    </Panel>
  )
}

export default withRouter(connector(SignIn))
