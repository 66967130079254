/** @jsx jsx */
import * as React from 'react'
import { jsx } from '@emotion/core'
import FormItem from '../_layouts/FormItem'
import CheckBox from './CheckBox'

interface IProps {
  name: string
  value: string
  checked?: boolean
  defaultChecked: boolean
  label?: null | string
  error?: null | string
  required?: boolean
  readOnly?: boolean
  someChecked?: boolean
  inputRef?: any
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = ({
  name,
  value,
  inputRef,
  checked,
  defaultChecked,
  readOnly = false,
  error = null,
  label = '',
  required = false,
  someChecked = false,
  onChangeHandler = () => {},
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <CheckBox
        readOnly={readOnly}
        name={name}
        value={value}
        inputRef={inputRef}
        checked={checked}
        defaultChecked={defaultChecked}
        someChecked={someChecked}
        onChangeHandler={onChangeHandler}
      />
    </FormItem>
  )
}

export default InputText
