import React from 'react'
import {
  CreateTeamBuildingActionDateInput,
  TeamBuildingActionDate,
  UpdateTeamBuildingActionDateInput,
  IndexGetTeamBuildingActionDates,
} from '@/stores/graphql'
import {
  mutationCreateTeamBuildingActionDate,
  mutationDeleteTeamBuildingActionDate,
  mutationUpdateTeamBuildingActionDate,
  queryGetTeamBuildingActionDate,
  queryGetTeamBuildingActionDates,
} from './graphql'

export const useTeamsBuildingsActionsDates = (
  index: IndexGetTeamBuildingActionDates,
  id?: string
) => {
  const limit = 1000
  const [records, setRecords] = React.useState<TeamBuildingActionDate[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!id) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActionDates({ id, index, limit })
      if (response) {
        setRecords(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [index, id])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!id || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActionDates({ id, index, limit, nextToken })
      if (response) {
        setRecords([...records, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [index, id, records, nextToken])

  return { records, nextToken, loading, error, refresh, more } as const
}

export const useTeamBuildingsActionDate = (id?: string) => {
  const [record, setRecord] = React.useState<TeamBuildingActionDate | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!id) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuildingActionDate({ id })
      setRecord(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [id])

  React.useEffect(() => {
    refresh()
  }, [id, refresh])

  return { record, loading, error, refresh } as const
}

export const useTeamBuildingActionDateManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createTeamBuildingActionDate = React.useCallback(
    async (
      input: CreateTeamBuildingActionDateInput
    ): Promise<[TeamBuildingActionDate | undefined, undefined | Error[]]> => {
      setLoading(true)
      try {
        const response = await mutationCreateTeamBuildingActionDate({ input })
        setLoading(false)

        return [response, undefined]
      } catch (e) {
        console.log('e', e)
        setErrors(e.errors)
        setLoading(false)

        return [undefined, e.errors]
      }
    },
    []
  )

  const updateTeamBuildingActionDate = React.useCallback(
    async (input: UpdateTeamBuildingActionDateInput) => {
      setLoading(true)
      try {
        const response = await mutationUpdateTeamBuildingActionDate({ input })

        return [response, undefined]
      } catch (e) {
        console.log('e', e)
        setErrors(e)

        return [undefined, e.errors]
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const deleteTeamBuildingActionDate = React.useCallback(async (id: string) => {
    setLoading(true)
    try {
      const response = await mutationDeleteTeamBuildingActionDate({ id })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    createTeamBuildingActionDate,
    updateTeamBuildingActionDate,
    deleteTeamBuildingActionDate,
    errors,
    loading,
  } as const
}
