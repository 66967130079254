import React from 'react'
import { TeamBuilding, UpdateTeamBuildingInput } from '@/stores/graphql'
import { mutationUpdateTeamBuilding, queryGetTeamBuilding } from './graphql'

export const useTeamBuilding = (teamId?: string) => {
  const [teamBuilding, setTeamBuilding] = React.useState<TeamBuilding | undefined>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeamBuilding({ id: teamId })
      setTeamBuilding(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { teamBuilding, loading, error, refresh } as const
}

export const useTeamBuildingManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const updateTeamBuilding = React.useCallback(async (input: UpdateTeamBuildingInput) => {
    setLoading(true)
    try {
      const response = await mutationUpdateTeamBuilding({ input })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)
    }
    setLoading(false)
  }, [])

  return {
    updateTeamBuilding,
    errors,
    loading,
  } as const
}
