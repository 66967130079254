import React, { useState } from 'react'
import { User } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Batch, Search } from '@/components/atoms'
import { replacePathParams } from '@/utils/history'
import { useUsers } from '@/hooks/users'
import { Link } from 'react-router-dom'
import { getTeamStatusAdminColor, getTeamStatusAdminLabel } from '@/utils/labels'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'ユーザー情報',
    // eslint-disable-next-line react/display-name
    field: (record: User) => (
      <div className="font-mono text-xs">
        <div className="flex mb-2">
          <dl className="mr-4">
            <dt className="font-bold">氏名</dt>
            <dd>{record.fullName}</dd>
          </dl>
          <dl>
            <dt className="font-bold">ユーザー名</dt>
            <dd>{record.username}</dd>
          </dl>
        </div>

        <dl>
          <dt className="font-bold">メールアドレス</dt>
          <dd>{record.email}</dd>
        </dl>
      </div>
    ),
  },
  {
    label: '会社情報',
    // eslint-disable-next-line react/display-name
    field: (record: User) => (
      <div className="font-mono text-xs">
        <div className="flex mb-2">
          <dl className="mr-4">
            <dt className="font-bold">社名</dt>
            <dd>{record.companyName}</dd>
          </dl>
          <dl>
            <dt className="font-bold">連絡先</dt>
            <dd>{record.companyPhone}</dd>
          </dl>
        </div>

        <dl>
          <dt className="font-bold">住所</dt>
          <dd>{record.companyZipCode}</dd>
          <dd>{record.companyPrefecture}</dd>
          <dd>{record.companyAddress1}</dd>
          <dd>{record.companyAddress2}</dd>
        </dl>
      </div>
    ),
  },
  {
    label: '管理者状況',
    // eslint-disable-next-line react/display-name
    field: (record: User) => (
      <Batch
        title={getTeamStatusAdminLabel(record.statusAdmin)}
        backgroundColor={getTeamStatusAdminColor(record.statusAdmin)}
      />
    ),
  },
]

export const GeneralCompanies: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [filter, setFilter] = useState<{
    companyName?: string
    email?: string
  }>({})

  const { users, nextToken, loading, more } = useUsers()

  return (
    <IndexLayout
      fetching={loading}
      title="法人"
      data={users
        .filter((u) => u.statusAdmin)
        .filter((u) => {
          if (filter.companyName || filter.email) {
            if (
              filter.companyName &&
              u.companyName &&
              u.companyName.indexOf(filter.companyName) > -1
            ) {
              return true
            }
            if (filter.email && u.email.indexOf(filter.email) > -1) {
              return true
            }
            return false
          } else {
            return true
          }
        })}
      columns={COLUMNS}
      // createPath={Paths.OnboardingTeamsNew}
      editPath={(userId: string) => replacePathParams(Paths.UsersEdit, { userId })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
      otherHandlers={(record: User) => (
        <Link
          className="underline text-primary whitespace-no-wrap"
          to={replacePathParams(Paths.UsersTeams, { userId: record.id })}
        >
          チーム一覧
        </Link>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => setFilter({ ...filter, companyName: value })}
                placeholder="法人名検索"
              />
            </div>
            <div className="ml-2">
              <Search
                handleSearch={(value: string) => setFilter({ ...filter, email: value })}
                placeholder="メールアドレス検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
