import React, { useEffect, useMemo, useState } from 'react'

import MONTH from '@/constants/month'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Select } from '@/components/atoms'
import { IOption } from './atoms/Select'

interface IProps {
  year?: string
  month?: string
  onChange(yearMonth: string): void
}

const getOption = (value: string, unit: string = '') => {
  return {
    value,
    label: `${value}${unit}`,
  }
}
const MIN_YEAR = 2021

export const SelectYearMonth: React.VFC<IProps> = ({ year, month, onChange }) => {
  // initialize

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const current = useMemo(() => dayjs().tz('Asia/Tokyo'), [])
  const diff = useMemo(() => current.year() - MIN_YEAR, [current])

  const years = useMemo(() => {
    const base = new Array(diff + 1).fill(MIN_YEAR)
    return base.map((y, i) => {
      return getOption(y + i, '年')
    })
  }, [diff])

  const [selectedYear, setSelectedYear] = useState<IOption>(
    getOption(year || current.year().toString(), '年')
  )
  const [selectedMonth, setSelectedMonth] = useState<IOption>(
    getOption(month || (current.month() + 1).toString(), '月')
  )

  const updateYear = React.useCallback((option: IOption) => {
    setSelectedYear(option)
  }, [])

  const updateMonth = React.useCallback((option: IOption) => {
    setSelectedMonth(option)
  }, [])

  useEffect(() => {
    onChange(`${selectedYear.value}-${selectedMonth.value}`)
  }, [onChange, selectedYear, selectedMonth])

  return (
    <div className="flex py-2">
      <div className="w-40">
        <Select
          name="year"
          placeholder="年を選択"
          options={years}
          value={selectedYear}
          onChange={updateYear}
        />
      </div>
      <div className="w-32 ml-2">
        <Select
          name="month"
          placeholder="月を選択"
          options={MONTH.map((value) => getOption(value, '月'))}
          value={selectedMonth}
          onChange={updateMonth}
        />
      </div>
    </div>
  )
}

