import React from 'react'
export type MiniDataTableValues = {
  label: string
  value: number | string
}

type MiniDataTableProps = {
  values: MiniDataTableValues[]
  title?: string
  inline?: boolean
}

const MiniDataTable: React.VFC<MiniDataTableProps> = ({ title, values, inline }) => {
  const wrapperClassName = inline ? '' : 'mt-5 bg-white shadow rounded-lg'
  return (
    <div className={wrapperClassName}>
      {title && <h3 className="px-6 py-2 text-lg font-bold">{title}</h3>}
      <div className={`flex${inline ? '' : ' p-4'}`}>
        {values.map(({ label, value }) => (
          <dl className="pr-4" key={label}>
            <dt className="pb-2 text-gray-600">{label}</dt>
            <dd className={inline ? 'text-center' : ''}>{value}</dd>
          </dl>
        ))}
      </div>
    </div>
  )
}

export default MiniDataTable
