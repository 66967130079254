import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import 'date-fns'
import { get } from 'lodash-es'
import { parse } from 'papaparse'
import { useForm } from 'react-hook-form'
import {
  faCheck,
  faExclamationCircle,
  faSpinner,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { Panel, Button, DropZoneUploader } from '@/components/atoms'
import { Paths } from '@/constants'
import { useMasterManage } from '@/hooks/masters'
import { Column, CsvDataList } from '@/layouts/csvList/index.component'
import { SelectOption, MasterType } from '@/stores/graphql'
import { replacePathParams } from '@/utils/history'
import { getMasterTypeLabel } from '@/utils/labels'

enum CSVStatus {
  processing = 'Processing',
  done = 'Done',
  error = 'Error',
}

interface CSVTeamMember {
  status?: CSVStatus
  error?: string

  type: MasterType
  sort: number
  title: string
  reason?: string
  detail?: string
  timing?: string
}

const COLUMNS: Column[] = [
  {
    label: '結果',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <div>
        {record.status === CSVStatus.processing ? (
          <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.done ? (
          <FontAwesomeIcon className="text-primary" icon={faCheck} />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.error ? (
          <Tooltip title={record.error || 'エラー'}>
            <div>
              <FontAwesomeIcon className="text-red" icon={faExclamationCircle} />
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    ),
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    label: 'タイプ',
    field: (record: CSVTeamMember) => getMasterTypeLabel(record.type),
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'タイトル',
    field: 'title',
    tdClass: 'whitespace-no-wrap',
  },
]

type Inputs = {
  id: string
  fullName: string
  username: string
  email?: string
  organization: string
  position: string
  role: SelectOption[]
}

export const ManageMasterCsv: React.FC = () => {
  const history = useHistory()
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { handleSubmit } = useForm<Inputs>()

  const { createMaster } = useMasterManage()

  const onDrop = (files: FileList | null) => {
    if (files && files.length > 0) {
      parse(files[0], {
        complete: updateData,
        header: true,
      })
    }
  }

  const [csvData, setCsvData] = React.useState<CSVTeamMember[]>([])
  const updateData = (result: any) => {
    const data = result.data as CSVTeamMember[]
    setCsvData(data)
  }

  const handleDelete = (index: number) => {
    const targetData = Array.from(csvData)
    targetData.splice(index, 1)
    setCsvData(targetData)
  }

  const onSubmit = async () => {
    setIsProcessing(true)

    for (const [index, d] of csvData.entries()) {
      const t = Array.from(csvData)
      t[index].status = CSVStatus.processing
      setCsvData(t)

      if (d.type && d.title) {
        const [created, createdErrors] = await createMaster({
          type: d.type,
          sort: d.sort,
          title: d.title,
          reason: d.reason,
          detail: d.detail,
          timing: d.timing,
        })

        if (created) {
          t[index].status = CSVStatus.done
          setCsvData(t)
        } else {
          t[index].status = CSVStatus.error
          t[index].error = get(createdErrors, '[0].message', 'エラーが発生しました')
          setCsvData(t)
        }
      } else {
        t[index].status = CSVStatus.error
        t[index].error = '情報が不足しています'

        setCsvData(t)
      }
    }

    setIsProcessing(false)
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DropZoneUploader className="h-32" onDrop={onDrop} type="csv" />

      <div className="h-56 mt-4 pt-4">
        {csvData.length > 0 ? (
          <div className="h-full border border-gray-400 p-1 overflow-scroll">
            <CsvDataList columns={COLUMNS} data={csvData} handleDelete={handleDelete} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-sm font-bold text-neutral-600">
            データはありません
          </div>
        )}
      </div>

      {isProcessing ? (
        <div className="flex justify-center mt-10 mb-10">
          <CircularProgress />
        </div>
      ) : (
        <div className="relative flex justify-center mt-10 gap-4">
          <Button
            className="absolute left-0"
            buttonType="muted"
            type="button"
            fit={true}
            handleClick={() => history.goBack()}
          >
            キャンセル
          </Button>
          <Button buttonType="primary" type="submit" fit={false} disabled={csvData.length === 0}>
            インポート
          </Button>
        </div>
      )}
    </form>
  )

  return (
    <div className="p-8">
      <Panel
        wrapperFull
        contentFull
        title={
          <>
            <Link className="text-primary underline" to={replacePathParams(Paths.Masters)}>
              マスター一覧
            </Link>
            <span> / マスター CSV一括追加</span>
          </>
        }
        titleRight={<CSVTemplate />}
      >
        {renderForm()}
      </Panel>
    </div>
  )
}

const CSVTemplate: React.FC = () => {
  return (
    <a
      className="text-sm text-primary cursor-pointer underline"
      href="/csv-templates/add-master.csv"
      download
    >
      <FontAwesomeIcon className="text-sm mr-1" icon={faFileDownload} />
      CSVテンプレート
    </a>
  )
}
