/** @jsx jsx */

import moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import { css, Global, jsx } from '@emotion/core'
import { DayPickerRangeController } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import { COLORS } from '@/constants'
import FilterLayout from '@/components/atoms/_layouts/Filter'

interface IProps {
  name: string
  field: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

interface ISelectedDate {
  startDate: null | moment.Moment
  endDate: null | moment.Moment
}

const initialParams = new URLSearchParams(window.location.search)
const getInitialValue = () => ({
  startDate: initialParams.has('start_date') ? moment(initialParams.get('start_date')) : null,
  endDate: initialParams.has('end_date') ? moment(initialParams.get('end_date')) : null,
})

const useDateRange = ({ label, handleOnSubmit, setPanelIsOpen }: any) => {
  const [labelName, setLabelName] = React.useState(label)
  const [selectedDate, setSelectedDate] = React.useState<ISelectedDate>(getInitialValue())

  const hasValue = React.useCallback(
    () => moment.isMoment(selectedDate.startDate) && moment.isMoment(selectedDate.endDate),
    [selectedDate]
  )

  const onDateRangePickerChange = React.useCallback((dates: ISelectedDate) => {
    const updatedSelectedDate = {
      startDate: dates.startDate,
      endDate: dates.endDate,
    }
    setSelectedDate(updatedSelectedDate)
  }, [])

  const onClear = React.useCallback(() => {
    setSelectedDate({ startDate: null, endDate: null })
  }, [])

  const onApply = React.useCallback(() => {
    const hasStartDate = moment.isMoment(selectedDate.startDate)
    const hasEndDate = moment.isMoment(selectedDate.endDate)

    if (hasStartDate && !hasEndDate) {
      setSelectedDate({
        startDate: moment(selectedDate.startDate),
        endDate: moment(selectedDate.startDate).add(1, 'days'),
      })
    }

    const filterValue: any = {
      start_date: moment.isMoment(selectedDate.startDate)
        ? selectedDate.startDate.format('YYYY-MM-DD')
        : null,
      end_date: moment.isMoment(selectedDate.endDate)
        ? selectedDate.endDate.format('YYYY-MM-DD')
        : hasStartDate
        ? moment(selectedDate.startDate).add(1, 'days').format('YYYY-MM-DD')
        : null,
    }

    handleOnSubmit(filterValue)
    setPanelIsOpen(false)
  }, [selectedDate, handleOnSubmit, setPanelIsOpen])

  React.useEffect(() => {
    if (moment.isMoment(selectedDate.startDate) && moment.isMoment(selectedDate.endDate)) {
      setLabelName(
        `${label} : ${selectedDate.startDate.format('YYYY/M/D')} ~ ${selectedDate.endDate.format(
          'YYYY/M/D'
        )}`
      )
    } else if (moment.isMoment(selectedDate.startDate) && !moment.isMoment(selectedDate.endDate)) {
      setLabelName(`${label} : ${selectedDate.startDate.format('YYYY/M/D')} ~ `)
    } else {
      setLabelName(label)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  return {
    hasValue,
    labelName,
    onApply,
    onClear,
    onDateRangePickerChange,
    selectedDate,
  }
}

const DateRangeFilter: React.FC<IProps> = (props) => {
  const { handleOnSubmit, setIsFilterPanelOpen } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const [focusedInput, setFocusedInput] = React.useState<null | 'startDate' | 'endDate'>(
    'startDate'
  )
  const isMobile = matchMedia(`(max-width: ${768}px)`).matches
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof setIsFilterPanelOpen === 'function') {
        setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  )
  const {
    hasValue,
    labelName,
    onApply,
    onClear,
    onDateRangePickerChange,
    selectedDate,
  } = useDateRange({ label: props.name, handleOnSubmit, setPanelIsOpen })

  const renderDayContents = (day: moment.Moment) => {
    return (
      <span className="relative z-10 flex items-center justify-center h-full">{day.date()}</span>
    )
  }

  return (
    <FilterLayout hasValue={hasValue()} label={labelName} onClear={onClear} onApply={onApply}>
      <Global styles={css(globalStyle)} />
      <DayPickerRangeController
        monthFormat="YYYY年M月"
        noBorder={true}
        startDate={selectedDate.startDate}
        endDate={selectedDate.endDate}
        onDatesChange={onDateRangePickerChange}
        focusedInput={focusedInput}
        onFocusChange={(currentFocusedInput: null | 'startDate' | 'endDate') =>
          setFocusedInput(currentFocusedInput || 'startDate')
        }
        enableOutsideDays={false}
        numberOfMonths={isMobile ? 1 : 2}
        hideKeyboardShortcutsPanel={true}
        initialVisibleMonth={null}
        renderDayContents={renderDayContents}
      />
    </FilterLayout>
  )
}

const globalStyle = `
  .CalendarDay.CalendarDay__default {
    position: relative;
    border-color: transparent;
  }

  .CalendarDay.CalendarDay__default::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${COLORS['primary-light']};
    border-radius: 50%;
    z-index: 2;
  }

  .CalendarDay.CalendarDay__default:not(.CalendarDay__selected):hover {
    background-color: transparent;
  }

  .CalendarDay.CalendarDay__default:not(.CalendarDay__selected):hover::after {
    display: block;
  }

  .CalendarDay.CalendarDay__selected {
    position: relative;
    background-color: transparent;
  }

  .CalendarDay.CalendarDay__selected_start::after,
  .CalendarDay.CalendarDay__selected_end::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${COLORS.primary};
    border-radius: 50%;
    z-index: 2;
  }

  .CalendarDay.CalendarDay__selected_start::before,
  .CalendarDay.CalendarDay__selected_end::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 50%;
    background-color: ${COLORS['primary-light']};
    z-index: 1;
  }

  .CalendarDay.CalendarDay__selected_start::before {
    right: 0;
  }

  .CalendarDay.CalendarDay__selected_end::before {
    left: -1px;
  }

  .CalendarDay.CalendarDay__selected_span,
  .CalendarDay.CalendarDay__hovered_span {
    background-color: ${COLORS['primary-light']};
    border-color: ${COLORS['primary-light']};
    color: #333;
  }
`

export default DateRangeFilter
