import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import 'moment/locale/ja'
import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import 'react-dates/initialize'

interface IProps {
  hasValue: boolean
  label: string
  onClear(): void
  onApply(): void
}

const FilterLayout: React.FC<IProps> = ({ hasValue, label, children, onClear, onApply }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onClickOutside = () => {
    if (isOpen) {
      onApply()
      setIsOpen(false)
    }
  }

  return (
    <div className="inline-block relative">
      <OutsideClickHandler onOutsideClick={() => onClickOutside()}>
        <div
          className={`
            flex items-center text-sm px-4 mx-1 h-9 border rounded-full cursor-pointer
            ${
              isOpen || hasValue
                ? 'bg-primary-light border-primary'
                : 'bg-neutral-100 border-neutral-100'
            }
          `}
          onClick={() => setIsOpen(!isOpen)}
        >
          {label}
          {!hasValue && (
            <FontAwesomeIcon className="inline-block ml-2 text-neutral-600" icon={faChevronDown} />
          )}
        </div>
        {isOpen && (
          <div className="absolute left-0 bttom-0 mt-2 mx-1 bg-white border border-neutral-300 rounded-xlg shadow-lg">
            {children}
            <div className="flex justify-between p-4 border-t border-neutral-300">
              <div
                className={classNames('text-neutral-500 font-bold cursor-pointer', {
                  disabled: !hasValue,
                })}
                onClick={onClear}
              >
                クリア
              </div>
              <div className="text-primary font-bold cursor-pointer" onClick={onApply}>
                適用
              </div>
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  )
}

export default FilterLayout
