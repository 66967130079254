import React, { useState, createContext } from 'react'
import MaterialSnackbar from '@material-ui/core/Snackbar'

type Type = 'success' | 'info' | 'error'

interface Props {
  open: boolean
  message?: string
  type?: Type
  handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string): void
}

export interface SnackbarConfig {
  type: Type
  message: string
}

export const SnackbarContext = createContext({
  openSnackbar: (config: SnackbarConfig) => {},
})

const Snackbar: React.FC<Props> = ({
  open = false,
  message = '',
  type = 'success',
  handleClose,
}) => (
  <MaterialSnackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
  >
    <div
      className={`
      p-4 rounded shadow font-bold
      ${type === 'success' && `bg-green text-white`}
      ${type === 'info' && `bg-blue text-white`}
      ${type === 'error' && `bg-red text-white`}
    `}
    >
      <span>{message}</span>
    </div>
  </MaterialSnackbar>
)

const SnackbarProvider = (props: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<Type>('success')
  const [message, setMessage] = useState<string>('')

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const openSnackbar = (config: SnackbarConfig) => {
    setType(config.type)
    setMessage(config.message)
    setOpen(true)
  }

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {props.children}
      <Snackbar open={open} message={message} type={type} handleClose={handleClose} />
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
