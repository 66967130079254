import React from 'react'
import { CreateMasterInput, Master, MasterType, UpdateMasterInput } from '@/stores/graphql'
import {
  mutationCreateMaster,
  mutationDeleteMaster,
  mutationUpdateMaster,
  queryGetMaster,
  queryGetMasters,
} from './graphql'

export const useMasters = (type?: MasterType) => {
  const limit = 1000
  const [masters, setMasters] = React.useState<Master[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!type) {
      return
    }

    setLoading(true)
    setError(undefined)
    setMasters([])

    try {
      const response = await queryGetMasters({ type, limit })
      console.log('response', response)
      if (response) {
        setMasters(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [type])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!type || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMasters({ type, limit, nextToken })
      if (response) {
        setMasters([...masters, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [masters, type, nextToken])

  return { masters, nextToken, loading, error, refresh, more } as const
}

export const useMaster = (id?: string) => {
  const [master, setMaster] = React.useState<Master | undefined>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!id) {
      setLoading(false)
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetMaster({ id })
      setMaster(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [id])

  React.useEffect(() => {
    refresh()
  }, [id, refresh])

  return { master, loading, error, refresh } as const
}

export const useMasterManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createMaster = React.useCallback(async (input: CreateMasterInput): Promise<
    [Master | undefined, undefined | Error[]]
  > => {
    setLoading(true)
    try {
      const response = await mutationCreateMaster({ input })

      return [response, undefined]
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)

      return [undefined, e.errors]
    } finally {
      setLoading(false)
    }
  }, [])

  const updateMaster = React.useCallback(async (input: UpdateMasterInput): Promise<
    [Master | undefined, undefined | Error[]]
  > => {
    setLoading(true)
    try {
      const response = await mutationUpdateMaster({ input })
      return [response, undefined]
    } catch (e) {
      console.log('e', e)
      setErrors(e)

      return [undefined, e.errors]
    } finally {
      setLoading(false)
    }
  }, [])

  const deleteMaster = React.useCallback(async (id: string) => {
    setLoading(true)
    try {
      const response = await mutationDeleteMaster({ id })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    createMaster,
    updateMaster,
    deleteMaster,
    errors,
    loading,
  } as const
}
