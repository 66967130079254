import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { isString, isFunction } from 'lodash-es'

export interface Column {
  label: string
  field?: any
  onSort?: any
  thClass?: string
  tdClass?: string
}
interface IProps {
  columns: Column[]
  data?: any[]
  handleDelete?(id: number): void
}

export const CsvDataList: React.FC<IProps> = ({ columns, data, handleDelete }) => {
  return (
    <table className="border-collapse w-full break-all">
      <thead className="border-b border-gray-400">
        <tr className="w-full">
          {columns.map((column: any, i) => (
            <th
              key={i}
              className={classNames(
                'px-4 py-2 text-left text-xs font-normal text-gray-600 whitespace-no-wrap',
                column.thClass
              )}
            >
              {column.label}
            </th>
          ))}
          {handleDelete && (
            <th className="px-4 py-2 text-left text-xs font-normal text-gray-600">操作</th>
          )}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-400 border-b border-gray-400">
        {data &&
          data.map((record: any, index: number) => (
            <tr key={`_${index}`} className="w-full">
              {columns.map((column, i) => (
                <td
                  key={`${record.id}-${i}`}
                  className={classNames([
                    'px-4 py-3 text-sm',
                    column.tdClass,
                    column.field === 'id' && 'block truncate',
                  ])}
                  style={{
                    maxWidth: column.field === 'id' ? 150 : 'auto',
                  }}
                >
                  {isString(column.field) && record[column.field]}
                  {isFunction(column.field) && column.field(record)}
                </td>
              ))}
              {handleDelete && (
                <td className="w-24 px-4 py-3 text-sm">
                  <div className="flex items-center gap-2">
                    {handleDelete && (
                      <div
                        onClick={() => handleDelete(index)}
                        className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-primary hover:bg-opacity-10"
                      >
                        <FontAwesomeIcon className="text-primary" icon={faTrashAlt} />
                      </div>
                    )}
                  </div>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
