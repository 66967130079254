import 'date-fns'
import React from 'react'
import { CircularProgress } from '@material-ui/core'

export const RenderProgress: React.FC = () => {
  return (
    <div className="flex justify-center p-32">
      <CircularProgress />
    </div>
  )
}
