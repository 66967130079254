import { OnboardingActionCategory } from '@/stores/graphql'

const ONBOARDING_ACTION_CATEGORIES = [
  {
    id: OnboardingActionCategory.Category1,
    name: 'チーム理解行動',
  },
  {
    id: OnboardingActionCategory.Category2,
    name: '関係構築行動',
  },
  {
    id: OnboardingActionCategory.Category3,
    name: '社会人自律行動',
  },
  {
    id: OnboardingActionCategory.Category4,
    name: '自己成長行動',
  },
  {
    id: OnboardingActionCategory.Category5,
    name: '意味づけ行動',
  },
]

export default ONBOARDING_ACTION_CATEGORIES
