import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { RootStateType } from '@/stores'
import { Actions as UserActions } from '@/stores/user/actions'
import { SignInActionInput } from '@/stores/user/interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (payload: SignInActionInput) => dispatch(UserActions.signIn.started(payload)),
})

const mapStateToProps = (state: RootStateType) => ({
  user: state.user,
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
