import React, { useState } from 'react'
import { User, UserFilter } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Batch, Search } from '@/components/atoms'
import { replacePathParams } from '@/utils/history'
import { useUsers } from '@/hooks/users'
import { Link } from 'react-router-dom'
import { getTeamStatusAdminColor, getTeamStatusAdminLabel } from '@/utils/labels'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'ニックネーム',
    field: 'nickname',
  },
  {
    label: '氏名',
    field: 'fullName',
  },
  {
    label: 'ユーザー名',
    field: 'username',
  },
  {
    label: 'メールアドレス',
    field: 'email',
  },
  {
    label: '管理者状況',
    // eslint-disable-next-line react/display-name
    field: (record: User) => (
      <Batch
        title={getTeamStatusAdminLabel(record.statusAdmin)}
        backgroundColor={getTeamStatusAdminColor(record.statusAdmin)}
      />
    ),
  },
]

export const GeneralUsers: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [filter, setFilter] = useState<UserFilter>({})

  const { users, nextToken, loading, more } = useUsers(filter)

  const onApplySearch = async (value: string, type: 'name' | 'email') => {
    const nextFilter = { ...filter }

    switch (type) {
      case 'name':
        if (value) {
          nextFilter.name = value
        } else {
          delete nextFilter.name
        }
        break
      case 'email':
        if (value) {
          nextFilter.email = value
        } else {
          delete nextFilter.email
        }
        break
    }

    setFilter(nextFilter)
  }

  return (
    <IndexLayout
      fetching={loading}
      title="ユーザー"
      data={users}
      columns={COLUMNS}
      // createPath={Paths.OnboardingTeamsNew}
      editPath={(userId: string) => replacePathParams(Paths.UsersEdit, { userId })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
      otherHandlers={(record: User) => (
        <Link
          className="underline text-primary whitespace-no-wrap"
          to={replacePathParams(Paths.UsersTeams, { userId: record.id })}
        >
          チーム一覧
        </Link>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'name')}
                placeholder="ユーザー名検索"
              />
            </div>
            <div className="ml-2">
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'email')}
                placeholder="メールアドレス検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
