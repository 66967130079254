import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, InputTextArea, Select } from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { Master, MasterType, SelectOption } from '@/stores/graphql'
import { useMaster, useMasterManage } from '@/hooks/masters'
import { getMaintainPublicRangeLabel } from '@/utils/labels'

type Inputs = {
  publicRange?: SelectOption
  title: string
  timing: string
  description?: string
}

export const ManageMaintain: React.FC = () => {
  const masterId = MasterType.Maintain

  const { openSnackbar } = React.useContext(SnackbarContext)
  const [record, setRecord] = React.useState<Master | undefined>(undefined)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { register, handleSubmit, errors, watch, setValue, control } = useForm<Inputs>()
  const isMaintainMode = watch('publicRange')?.value === '1' ? true : false

  const { master, loading } = useMaster(masterId)
  const { updateMaster } = useMasterManage()
  React.useEffect(() => {
    setRecord(master)
    if (master) {
      setValue('publicRange', getPublicRangeOption(master.publicRange))
    }
  }, [master, setValue])

  const onSubmit = async (data: Inputs) => {
    setIsProcessing(true)

    const publicRange = Number(data.publicRange?.value) ?? undefined
    try {
      // update
      const [updated, error] = await updateMaster({
        id: masterId,
        type: MasterType.Maintain,
        sort: 0,
        ...data,
        publicRange,
      })
      setIsProcessing(false)

      if (updated) {
        openSnackbar({ type: 'success', message: '更新しました' })
      } else {
        openSnackbar({
          type: 'error',
          message: `エラーが発生しました [${get(error, '[0].message')}]`,
        })
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    }
  }

  return (
    <div className="p-8">
      <Panel
        title={
          <>
            <span>メンテナンス</span>
          </>
        }
      >
        {loading ? (
          <RenderProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6">
              <Controller
                name="publicRange"
                rules={{ required: true }}
                defaultValue={{
                  value: get(record, 'publicRange') ?? 0,
                  label: getMaintainPublicRangeLabel(get(record, 'publicRange') ?? 0),
                }}
                control={control}
                render={({ onChange, name, value }) => (
                  <>
                    <Select
                      label="公開状況"
                      // readOnly={isProcessing}
                      required
                      name={name}
                      value={value}
                      options={[0, 1].map((value) => ({
                        value: value.toString(),
                        label: getMaintainPublicRangeLabel(value),
                      }))}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </div>

            <div className="mt-6">
              <InputText
                required={isMaintainMode}
                label="タイトル"
                name="title"
                defaultValue={get(record, 'title')}
                readOnly={!isMaintainMode}
                placeholder="ただいまメンテナンス中です"
                inputRef={register({ required: isMaintainMode })}
                error={errors.title && errors.title.message}
              />
            </div>

            <div className="mt-6">
              <InputText
                required={isMaintainMode}
                label="メンテナンス日時"
                name="timing"
                defaultValue={get(record, 'timing')}
                readOnly={!isMaintainMode}
                placeholder="2022年3月25日 22:00〜23:30"
                inputRef={register({ required: isMaintainMode })}
                error={errors.timing && errors.timing.message}
              />
            </div>

            <div className="mt-6">
              <InputTextArea
                required={isMaintainMode}
                label="説明"
                name="description"
                defaultValue={get(record, 'description')}
                readOnly={!isMaintainMode}
                placeholder={`ご利用の皆様にはご迷惑をおかけし、申し訳ございません。\nメンテナンス終了までしばらくお待ちください。`}
                inputRef={register({})}
                error={errors.description && errors.description.message}
              />
            </div>

            {isProcessing ? (
              <div className="flex justify-center mt-10 mb-10">
                <CircularProgress />
              </div>
            ) : (
              <div className="relative flex justify-center mt-10 gap-4">
                <Button
                  className="absolute left-0"
                  buttonType="muted"
                  type="button"
                  fit={true}
                  link={Paths.Masters}
                >
                  キャンセル
                </Button>
                <Button buttonType="primary" type="submit" fit={false}>
                  更新
                </Button>
              </div>
            )}
          </form>
        )}
      </Panel>
    </div>
  )
}

const getPublicRangeOption = (publicRange?: number | null) => {
  if (publicRange) {
    return {
      value: publicRange.toString(),
      label: getMaintainPublicRangeLabel(publicRange),
    }
  } else {
    return {
      value: (0).toString(),
      label: getMaintainPublicRangeLabel(0),
    }
  }
}
