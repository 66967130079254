import React from 'react'

import DataTable, { Column } from '@/components/DataTable'
import dayjs from 'dayjs'
import { OnboardingPostSummary } from '@/types/type'
import MiniDataTable, { MiniDataTableValues } from '../atoms/MiniDataTable'

interface IProps {
  onboardingPostSummaryData: OnboardingPostSummary.Data | null | undefined
  fetching: boolean
}
const startedAtFromRecord = ({ startedAt }: any) => dayjs(startedAt).format('YYYY-MM-DD')
const valueOrEmpty = (value: string | number | null | undefined) => {
  return value === null || value === undefined ? '-' : value
}

const OnbordingPostSummary: React.VFC<IProps> = ({ onboardingPostSummaryData, fetching }) => {
  const { summary, users } = onboardingPostSummaryData || { summary: null, users: null }

  const usersValues = users
    ? Object.entries(users).map(([id, value]) => {
        return { id, ...value }
      })
    : []
  const postColumns: Column[] = React.useMemo(() => {
    return summary
      ? [
          {
            label: '名前',
            field: 'name',
          },
          {
            label: '参加日',
            field: startedAtFromRecord,
          },
          {
            label: 'お気に入りした数',
            field: ({ summary }: OnboardingPostSummary.UsersSummary) => {
              return valueOrEmpty(summary.like)
            },
          },
          {
            label: 'お気に入りされた数',
            field: ({ summary }: OnboardingPostSummary.UsersSummary) => {
              return valueOrEmpty(summary.liked)
            },
          },
          {
            label: 'コメントした数',
            field: ({ summary }: OnboardingPostSummary.UsersSummary) => {
              return valueOrEmpty(summary.comment)
            },
          },
          {
            label: 'コメントされた数',
            field: ({ summary }: OnboardingPostSummary.UsersSummary) => {
              return valueOrEmpty(summary.commented)
            },
          },
          {
            label: 'シェア数',
            field: ({ summary }: OnboardingPostSummary.UsersSummary) => {
              return valueOrEmpty(summary.share)
            },
          },
        ]
      : []
  }, [summary])

  const inlineTableValues: MiniDataTableValues[] = React.useMemo(() => {
    return summary
      ? [
          {
            label: 'お気に入りした数',
            value: summary.like,
          },
          {
            label: 'お気に入りされた数',
            value: summary.liked,
          },
          {
            label: 'コメントした数',
            value: summary.comment,
          },
          {
            label: 'コメントされた数',
            value: summary.commented,
          },
          {
            label: 'シェア数',
            value: summary.share,
          },
        ]
      : []
  }, [summary])
  return (
    <div className="py-2">
      <h2 className="text-xl font-bold">タイムライン</h2>

      <MiniDataTable values={inlineTableValues} />

      <DataTable title="ユーザー別" columns={postColumns} data={usersValues} fetching={fetching} />
    </div>
  )
}

export default OnbordingPostSummary
