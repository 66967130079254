/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import FilterLayout from '@/components/atoms/_layouts/Filter'

interface IType {
  id: number | string
  name: string
}

interface IProps {
  field: string
  name: string
  types: IType[]
  setIsFilterPanelOpen?: (isOpen: boolean) => void
  handleOnSubmit: (filterValue: any) => void
}

const initialParams = new URLSearchParams(window.location.search)

const TypeFilter: React.FC<IProps> = (props) => {
  const getInitialValue = () =>
    initialParams.has(`${props.field}[]`) ? initialParams.getAll(`${props.field}[]`) : []
  const [isOpen, setIsOpen] = React.useState(false)
  const [types, setTypes] = React.useState(getInitialValue())
  const [labelName, setLabelName] = React.useState(props.name)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [props, isOpen]
  )

  const onChangeHandler = (event: any) => {
    const value = event.target.value

    if (event.target.checked) {
      if (!types.includes(value)) {
        setTypes([...types, value])
      }
    } else {
      if (types.includes(value)) {
        setTypes(types.filter((type) => type !== value))
      }
    }
  }

  React.useEffect(() => {
    if (types.length) {
      const updatedLabelName = props.types
        .map((type) => (types.includes(String(type.id)) ? type.name : null))
        .filter((type) => type !== null)
        .join(', ')
      setLabelName(`${props.name} : ${updatedLabelName}`)
    } else {
      setLabelName(props.name)
    }
  }, [types, props])

  const hasValue = () => !!types.length
  const onClear = () => {
    setTypes([])
  }
  const onApply = React.useCallback(() => {
    props.handleOnSubmit({ [props.field]: types })
    setPanelIsOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, setPanelIsOpen, props.handleOnSubmit])

  return (
    <FilterLayout hasValue={hasValue()} label={labelName} onClear={onClear} onApply={onApply}>
      <div
        className="px-4"
        css={css`
          min-width: 200px;
          max-height: 480px;
          overflow: scroll;
        `}
      >
        {props.types.map((type) => (
          <label
            key={type.id}
            className="flex items-center py-2 cursor-pointer"
            css={css`
              input + .checkbox {
                .filled {
                  display: none;
                }
                .empty {
                  display: block;
                }
              }
              input:checked + .checkbox {
                .filled {
                  display: block;
                }
                .empty {
                  display: none;
                }
              }
            `}
          >
            <input
              className="hidden"
              type="checkbox"
              name={props.field}
              value={String(type.id)}
              onChange={onChangeHandler}
              checked={types.includes(String(type.id))}
            />
            <span className="checkbox">
              <FontAwesomeIcon className="filled text-lg text-primary" icon={faCheckSquare} />
              <FontAwesomeIcon className="empty text-lg text-neutral-600" icon={faSquare} />
            </span>
            <span className="ml-2">{type.name}</span>
          </label>
        ))}
      </div>
    </FilterLayout>
  )
}

export default TypeFilter
