import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Panel, InputText, Button } from '@/components/atoms'
import { useForm } from 'react-hook-form'
import { connector, ContainerProps } from './restore.container'
import { Paths } from '@/constants'

type Inputs = {
  email: string
}

const Restore: React.FC<RouteComponentProps & ContainerProps> = (props) => {
  const [processing, setProcessing] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    setProcessing(true)
    props.restore(data.email, onSubmitSuccess, onSubmitError)
  }

  const onSubmitSuccess = (email: string) => {
    props.history.push(`${Paths.ResetPassword}?email=${encodeURIComponent(email)}`)
  }

  const onSubmitError = (error: Error) => {
    setErrorMsg(error.message)
    setProcessing(false)
  }

  return (
    <Panel className="w-104">
      <h1 className="text-2xl text-center">パスワード再発行</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <InputText
            defaultValue=""
            name="email"
            type="email"
            inputRef={register({
              required: 'メールアドレスを入力してください',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: '無効なメールアドレスです',
              },
            })}
            label="メールアドレス"
            error={errors.email ? errors.email.message : null}
          />
        </div>

        {errorMsg && (
          <div style={{ padding: '16px 16px 0', fontSize: 12, color: 'red' }}>{errorMsg}</div>
        )}

        <div className="mt-12">
          <Button type="submit" buttonType="primary" full disabled={processing}>
            送信
          </Button>
        </div>
      </form>
    </Panel>
  )
}

export default withRouter(connector(Restore))
