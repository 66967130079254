import { Dispatch } from 'redux'
import { Actions as UserActions } from '@/stores/user/actions'
import { SignOutActionInput } from '@/stores/user/interface'
import { RootState } from '@/stores/reducers'
import { connect, ConnectedProps } from 'react-redux'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: (payload: SignOutActionInput) => dispatch(UserActions.signOut.started(payload)),
})

const mapStateToProps = (state: RootState) => ({
  user: state.user,
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
