import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash-es'
import { TypeFilter } from '@/components/atoms'
import { getOnboardingActionList } from '@/stores/actions/api'
import { getOnboardingTeamList } from '@/stores/teams/api'
import { OnboardingAction, OnboardingTeam, OnboardingActionFilter } from '@/stores/graphql'
import usePrevious from '@/utils/usePrevious'
import { ONBOARDING_ACTION_CATEGORIES, Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { replacePathParams } from '@/utils/history'

const COLUMNS = [
  {
    label: 'アクションID',
    field: 'id',
  },
  {
    label: 'チーム',
    field: (record: OnboardingAction) =>
      record.teamId === 'MASTER' ? 'MASTER' : record.onboardingTeam?.title,
  },
  {
    label: '分類',
    field: (record: OnboardingAction) =>
      ONBOARDING_ACTION_CATEGORIES.find(({ id }) => id === record.category)?.name || '',
  },
  {
    label: 'タイトル',
    field: 'mission',
  },
  // {
  //   label: '重要度',
  //   field: 'importance',
  // },
  {
    label: '日数',
    field: 'period',
  },
  {
    label: '期限',
    field: 'deadline',
  },
]

interface IRecords {
  data: OnboardingAction[]
  fetching: boolean
}

export const OnboardingActions: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [records, setRecords] = useState<IRecords>({
    data: [],
    fetching: false,
  })
  const [nextToken, setNextToken] = useState<null | string>(null)
  const [filter, setFilter] = useState<OnboardingActionFilter>({})
  const prevFilter = usePrevious(filter)

  const [teamOptions, setTeamOptions] = useState<any>([])

  const getTeamOptions = React.useCallback(async () => {
    const { data } = await getOnboardingTeamList({ first: -1, after: nextToken })
    const options = data.map((team: OnboardingTeam) => ({
      id: team.id,
      name: team.title,
    }))
    setTeamOptions([
      {
        id: 'MASTER',
        name: 'MASTER',
      },
      ...options,
    ])
  }, [nextToken])

  useEffect(() => {
    getTeamOptions()
  }, [getTeamOptions])

  const getData = React.useCallback(
    async (filter: OnboardingActionFilter, token: null | string = null, replaceRecords = false) => {
      console.log('getData args', { token, filter, replaceRecords })
      setRecords((current: any) => ({
        data: replaceRecords ? [] : [...current.data],
        fetching: true,
      }))

      const { data, nextToken: newNextToken } = await getOnboardingActionList({
        filter,
        after: token,
      })

      console.log('getData', { data, newNextToken })
      setRecords({ data: replaceRecords ? data : [...records.data, ...data], fetching: false })
      setNextToken(newNextToken)
    },
    [records.data]
  )

  // const handleDelete = async (id: string) => {
  //   // try {
  //   //   await deleteAdmin({ id })
  //   //   setRecords((current: IRecords) => ({
  //   //     ...current,
  //   //     data: current.data.filter((item: any) => item.id !== id),
  //   //   }))
  //   //   openSnackbar({ type: 'success', message: 'アドミンを削除しました' })
  //   // } catch {
  //   //   openSnackbar({ type: 'error', message: 'エラーが発生しました' })
  //   // }
  // }

  const onApplyCategoryFilter = async (value: any) => {
    const { category = [] } = value
    const hasValue = !!category.length
    const nextFilter = { ...filter }
    if (hasValue) {
      nextFilter.category = [...category]
    } else {
      delete nextFilter.category
    }
    setFilter(nextFilter)
  }

  const onApplyTeamIdFilter = async (value: any) => {
    const { teamId = [] } = value
    const hasValue = !!teamId.length
    const nextFilter = { ...filter }
    if (hasValue) {
      nextFilter.teamId = [...teamId]
    } else {
      delete nextFilter.teamId
    }
    setFilter(nextFilter)
  }

  useEffect(() => {
    const isFilterUpdated = !isEqual(filter, prevFilter)

    console.log('useEffect [filter]', {
      filter,
      prevFilter,
      isFilterUpdated,
    })

    if (isFilterUpdated) {
      getData(filter, null, true)
    }
  }, [filter, getData, prevFilter])

  const onClickReadMore = React.useCallback(async () => {
    getData(filter, nextToken, false)
  }, [filter, nextToken, getData])

  return (
    <IndexLayout
      fetching={records.fetching}
      title="オンボーディング - アクション"
      data={records.data}
      columns={COLUMNS}
      createPath={Paths.OnboardingActionsNew}
      editPath={(id: string) => replacePathParams(Paths.OnboardingActionsEdit, { actionId: id })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? onClickReadMore : undefined}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div className="whitespace-no-wrap">
              <TypeFilter
                name="分類"
                field="category"
                types={ONBOARDING_ACTION_CATEGORIES}
                handleOnSubmit={onApplyCategoryFilter}
              />
            </div>
            {teamOptions.length > 0 && (
              <div className="ml-2 whitespace-no-wrap">
                <TypeFilter
                  name="チーム"
                  field="teamId"
                  types={teamOptions}
                  handleOnSubmit={onApplyTeamIdFilter}
                />
              </div>
            )}
          </div>
        </div>
      }
    />
  )
}
