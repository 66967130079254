import * as React from 'react'
import ReactSelect from 'react-select'
import FormItem from './_layouts/FormItem'
import { COLORS } from '@/constants'

export interface IOption {
  value: string
  label: string
}

interface IProps {
  name: string
  value?: null | IOption | string
  defaultValue?: any
  isMulti?: boolean
  placeholder?: string
  label?: null | string
  error?: null | string
  required?: boolean
  options: any[]
  loading?: boolean
  onChange?: (value: any) => void
  isDisabled?: boolean
  closeMenuOnSelect?: boolean
}

const customStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 44,
      transition: 'none',
      boxShadow: `0 0 0 0px ${state.isFocused ? COLORS.primary : 'transparent'}`,
      backgroundColor: `${state.isFocused ? COLORS['neutral-100'] : '#fff'}`,
      border: `1px solid #e2e8f0`,
    }
  },
  option: (provided: any, { isDisabled, isFocused, isSelected }: any) => {
    return {
      ...provided,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? COLORS['primary']
        : isFocused
        ? COLORS['primary-light']
        : null,
    }
  },
}

export const Select: React.FC<IProps> = ({
  name,
  value = null,
  isMulti = false,
  error = null,
  label = '',
  placeholder = '選択してください',
  required = false,
  onChange = () => {},
  options = [],
  loading = false,
  isDisabled = false,
  closeMenuOnSelect = true,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <ReactSelect
        closeMenuOnSelect={closeMenuOnSelect}
        value={value}
        name={name}
        isMulti={isMulti}
        styles={customStyles}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        isLoading={loading}
        isDisabled={isDisabled}
      />
    </FormItem>
  )
}
