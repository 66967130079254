import { API, GraphQLResult } from '@aws-amplify/api'
import { MutationDeleteOnboardingActionArgs, OnboardingAction } from '@/stores/graphql'
import gql from 'graphql-tag'

export const mutationDeleteOnboardingAction = async (args: MutationDeleteOnboardingActionArgs) => {
  const response = (await API.graphql({
    query: gql`
      mutation mutationDeleteOnboardingAction($id: ID!) {
        deleteOnboardingAction(id: $id) {
          id
          teamId
          mission
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteOnboardingAction: OnboardingAction }>

  return {
    data: { ...response?.data?.deleteOnboardingAction },
  }
}
