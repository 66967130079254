import {
  OnboardingSummaryFormat,
  OnboardingSummaryType,
  QueryGetOnboardingSummaryArgs,
} from '@/stores/graphql'
import { OnboardingSummaryUsersValue } from '@/types/onboardingSummary'
import {
  OnboardingActionsSummary,
  OnboardingDailySummary,
  OnboardingPostSummary,
} from '@/types/type'
import { queryOnboardingSummary } from './api'

type Args = Omit<QueryGetOnboardingSummaryArgs, 'type'>

type OnFinish = (res: OnboadingSummary) => void

export type OnboadingSummary = {
  data: any | null | undefined
  errors: any
  fetching: boolean
}

// for CSV
const getCSV = async (args: QueryGetOnboardingSummaryArgs) => {
  return await queryOnboardingSummary({
    ...args,
    format: OnboardingSummaryFormat.Csv,
  })
    .then((res) => {
      if (res) {
        const { data } = res
        return { data: data ? JSON.parse(data) : null, fetching: false, errors: null }
      }
      return { data: null, fetching: false, errors: null }
    })
    .catch(({ data, errors }) => {
      return { data, fetching: false, errors }
    })
}

//参考：https://qiita.com/t-iguchi/items/b15a0d592cb485d53f9a
const json2csv = (json: any[]) => {
  const header = Object.keys(json[0]) + '\n'

  const body = json
    .map((d) =>
      Object.keys(d)
        .map((key) => d[key])
        .join(',')
    )
    .join('\n')

  return header + body
}

const joinCSVs = (csvs: { csv: any; label: string }[]) => {
  return csvs.map(({ csv, label }) => `${label},${'\n'}${csv}`).join('\n,\n')
}

const getNameFromID = (values: any[]) => {
  return values.reduce((prev, value) => {
    return { ...prev, [value.id]: value.name }
  }, {})
}
const getUsersData = (users: OnboardingSummaryUsersValue, names: { id: string; name: string }) => {
  return Object.entries(names).reduce((prev, [id, name]) => {
    return { ...prev, [name]: users[id]?.value || '' }
  }, {})
}

export const getOnboardingDailySummaryCSV = async (args: Args) => {
  const { data, fetching, errors } = await getCSV({
    ...args,
    type: OnboardingSummaryType.Daily,
  })
  if (!data) {
    return { data: null, fetching, errors }
  }

  const { dates, users, summary, excludes }: OnboardingDailySummary.Data = data
  const usersValues = users
    ? Object.entries(users).map(([id, user]) => {
        return { id, ...user }
      })
    : []
  const dateValues = Object.entries(dates)
    .filter(([day, value]) => value.target && !excludes?.includes(day))
    .map(([day, value]) => {
      return {
        day,
        ...{ ...value.summary, ...getUsersData(value.users, getNameFromID(usersValues)) },
      }
    })
  const mergeCSVs = [
    {
      csv: json2csv([summary]),
      label: '全体',
    },
    {
      csv: json2csv(usersValues),
      label: 'ユーザー別',
    },
    {
      csv: json2csv(dateValues),
      label: '日別',
    },
  ]
  return { data: joinCSVs(mergeCSVs), fetching, errors }
}

export const getOnboardingActionSummaryCSV = async (args: Args) => {
  const { data, fetching, errors } = await getCSV({
    ...args,
    type: OnboardingSummaryType.Action,
  })
  if (!data) {
    return { data: null, fetching, errors }
  }

  const { actions, users, summary }: OnboardingActionsSummary.Data = data
  const usersValues = users
    ? Object.entries(users).map(([id, user]) => {
        return { id, ...user }
      })
    : []
  const actionsValues = Object.entries(actions).map(([id, value]) => {
    return {
      id,
      name: value.name,
      ...{
        ...value.summary,
        ...getUsersData(value.users, getNameFromID(usersValues)),
      },
    }
  })
  const mergeCSVs = [
    {
      csv: json2csv([summary]),
      label: '全体',
    },
    {
      csv: json2csv(usersValues),
      label: 'ユーザー別',
    },
    {
      csv: json2csv(actionsValues),
      label: 'アクション別',
    },
  ]
  return { data: joinCSVs(mergeCSVs), fetching, errors }
}

export const getOnboardingPostSummaryCSV = async (args: Args) => {
  const { data, fetching, errors } = await getCSV({
    ...args,
    type: OnboardingSummaryType.Post,
  })
  if (!data) {
    return { data: null, fetching, errors }
  }

  const { users, summary }: OnboardingPostSummary.Data = data
  const usersValues = users
    ? Object.entries(users).map(([id, user]) => {
        return { id, ...{ name: user.name, startedAt: user.startedAt, ...user.summary } }
      })
    : []

  const mergeCSVs = [
    {
      csv: json2csv([summary]),
      label: '全体',
    },
    {
      csv: json2csv(usersValues),
      label: 'ユーザー別',
    },
  ]
  return { data: joinCSVs(mergeCSVs), fetching, errors }
}
// for Data
const get = async (args: QueryGetOnboardingSummaryArgs, onFinish: OnFinish) => {
  return await queryOnboardingSummary({
    ...args,
  })
    .then((res) => {
      if (res) {
        const { data } = res
        onFinish({ data: data ? JSON.parse(data) : null, fetching: false, errors: null })
      }
    })
    .catch(({ data, errors }) => {
      onFinish({ data, fetching: false, errors })
    })
}

export const getOnboardingDailySummary = async (args: Args, onFinish: OnFinish) => {
  return await get(
    {
      ...args,
      type: OnboardingSummaryType.Daily,
    },
    onFinish
  )
}

export const getOnboardingActionSummary = async (args: Args, onFinish: OnFinish) => {
  return await get(
    {
      ...args,
      type: OnboardingSummaryType.Action,
    },
    onFinish
  )
}

export const getOnboardingPostSummary = async (args: Args, onFinish: OnFinish) => {
  return await get(
    {
      ...args,
      type: OnboardingSummaryType.Post,
    },
    onFinish
  )
}
