import 'date-fns'
import React, { useState, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, NativeSelect } from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { TeamMemberStatusUsage } from '@/stores/graphql'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useTeamsMembers } from '@/hooks/teamsMembers'
import { useTeamBuildingActionManage, useTeamBuildingsAction } from '@/hooks/teamsBuildingsActions'

type Inputs = {
  id: string
  assignedTeamMemberId: string
  assignedTeamMemberName: string
  name: string
  reason: string
  content: string
  timing: string
  editable: string
}

export const GeneralTeamsBuildingsActionsEdit: React.FC = () => {
  const { teamBuildingActionId, teamId } = useParams<{
    teamBuildingActionId?: string
    teamId: string
  }>()
  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)

  const { team } = useTeam(teamId)
  const { teamMembers } = useTeamsMembers(teamId)
  const { record, loading } = useTeamBuildingsAction(teamBuildingActionId)
  const { createTeamBuildingAction, updateTeamBuildingAction } = useTeamBuildingActionManage()

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)
      const teamMember = teamMembers.filter((t) => t.id === data.assignedTeamMemberId)[0]
      const editable = Number(data.editable)

      console.log('editable', editable)

      if (!teamBuildingActionId) {
        const [created, createdErrors] = await createTeamBuildingAction({
          ...data,
          teamId,
          assignedTeamMemberName: teamMember.fullName,
          editable,
        })
        if (created) {
          openSnackbar({ type: 'success', message: '作成しました' })
          history.push(replacePathParams(Paths.TeamBuildingsActions, { teamId }))
        } else {
          openSnackbar({
            type: 'error',
            message: get(createdErrors, '[0].message', 'エラーが発生しました'),
          })
        }
      } else {
        const [updated, updatedErrors] = await updateTeamBuildingAction({
          ...data,
          assignedTeamMemberName: teamMember.fullName,
          editable,
        })
        if (updated) {
          openSnackbar({ type: 'success', message: '更新しました' })
        } else {
          openSnackbar({
            type: 'error',
            message: get(updatedErrors, '[0].message', 'エラーが発生しました'),
          })
        }
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  // React.useEffect(() => {
  //   if (errors && errors.length > 0) {
  //     const message = get(errors, '[0].message', 'エラーが発生しました')
  //     openSnackbar({ type: 'error', message })
  //   }
  // }, [errors, openSnackbar])

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {teamBuildingActionId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={teamBuildingActionId}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <NativeSelect
            label="ユーザー"
            readOnly={isProcessing}
            name="assignedTeamMemberId"
            defaultValue={get(record, 'assignedTeamMemberId') || ''}
            options={teamMembers
              .filter((t) => t.statusUsage === TeamMemberStatusUsage.Active)
              .map((teamMember) => ({
                value: teamMember.id,
                label: teamMember.fullName,
              }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            required={true}
            label="アクション名"
            name="name"
            defaultValue={get(record, 'name')}
            inputRef={register({ required: true })}
            error={errors.name && errors.name.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="理由"
            name="reason"
            defaultValue={get(record, 'reason')}
            inputRef={register({})}
            error={errors.reason && errors.reason.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="内容"
            name="content"
            defaultValue={get(record, 'content')}
            inputRef={register({})}
            error={errors.content && errors.content.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="推奨タイミング"
            name="timing"
            defaultValue={get(record, 'timing')}
            inputRef={register({})}
            error={errors.timing && errors.timing.message}
          />
        </div>

        <div className="mt-6">
          <NativeSelect
            label="編集可否"
            readOnly={isProcessing}
            name="editable"
            defaultValue={get(record, 'editable')?.toString() || ''}
            options={[
              { value: '0', label: '不可' },
              { value: '1', label: '可能' },
            ]}
            selectRef={register({ required: true })}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              handleClick={() => history.goBack()}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!teamBuildingActionId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          title={
            <>
              <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
                チーム一覧
              </Link>
              &nbsp;/&nbsp;
              {team.name}
              <span>
                &nbsp;/&nbsp;チームビルディングアクション{!teamBuildingActionId ? '作成' : '編集'}
              </span>
            </>
          }
        >
          <div className="text-right ">
            <Link
              className="text-primary underline "
              to={replacePathParams(Paths.TeamBuildingsActionsDates, {
                teamId,
                teamBuildingActionId,
              })}
            >
              スケジュール一覧
            </Link>
          </div>
          {loading ? <RenderProgress /> : renderForm()}
        </Panel>
      )}
    </div>
  )
}
