import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { QueryGetAdminList, MutationCreateAdmin } from './graphql'
import { QueryGetOnboardingAdminListArgs, MutationCreateOnboardingAdminArgs } from '../graphql'

export const getAdminList = async ({ after = null }: QueryGetOnboardingAdminListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetAdminList,
    variables: { after },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('QueryGetAdminList', response)

  return {
    data: [...response.data.getOnboardingAdminList.items],
    nextToken: response?.data?.getOnboardingAdminList?.nextToken,
  }
}

export const createAdmin = async ({ input }: MutationCreateOnboardingAdminArgs) => {
  const response: any = await API.graphql({
    query: MutationCreateAdmin,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationCreateAdmin response', response)

  return {
    data: { ...response.data.createOnboardingAdmin },
  }
}
