import * as React from 'react'

function LogoWhiteComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="\u30EC\u30A4\u30E4\u30FC 2"
      xmlns="http://www.w3.org/2000/svg"
      // width={232}
      // height={29}
      viewBox="0 0 232 29"
      {...props}
    >
      <g data-name="\u30EC\u30A4\u30E4\u30FC 1">
        <path
          data-name="Path 126"
          d="M47.317 14.5c0 8.006-6.763 14.489-15.1 14.489a15.421 15.421 0 01-8.56-2.555 14.405 14.405 0 006.51-11.034.447.447 0 00-.44-.47h-4.374a.432.432 0 00-.425.4 9.272 9.272 0 01-5.216 7.324 14 14 0 010-16.338A10.372 10.372 0 0015.1 5.231c-5.436 0-9.856 4.147-9.856 9.257s4.428 9.265 9.856 9.265a10.288 10.288 0 004.617-1.1 14.526 14.526 0 003.92 3.791A15.459 15.459 0 0115.065 29C6.763 28.985 0 22.495 0 14.488S6.763 0 15.1 0a15.467 15.467 0 018.567 2.563A15.361 15.361 0 0132.222 0c8.332 0 15.095 6.49 15.095 14.5z"
          fill="#fff"
        />
        <path
          data-name="Path 127"
          d="M30.168 15.398a14.405 14.405 0 01-6.505 11.032 14.527 14.527 0 01-3.92-3.787 9.272 9.272 0 005.186-7.324.432.432 0 01.425-.4h4.375a.447.447 0 01.44.485z"
          fill="#fff"
        />
        <path
          data-name="Path 128"
          d="M30.168 15.398a14.405 14.405 0 01-6.505 11.032 14.527 14.527 0 01-3.92-3.787 9.272 9.272 0 005.186-7.324.432.432 0 01.425-.4h4.375a.447.447 0 01.44.485z"
          fill="#231815"
        />
        <path
          data-name="Path 129"
          d="M69.443 28.849c-7.87 0-14.648-5.413-14.648-14.238S61.573.372 69.443.372a16.6 16.6 0 019.348 2.661.364.364 0 01.159.311v4.049a.379.379 0 01-.607.3 13.207 13.207 0 00-8.34-2.866c-5.413 0-9.416 3.639-9.416 9.78s4 9.78 9.416 9.78a13.207 13.207 0 008.34-2.866.379.379 0 01.607.3v4.049a.364.364 0 01-.159.311 16.6 16.6 0 01-9.348 2.668z"
          fill="#fff"
        />
        <path
          data-name="Path 130"
          d="M92.548 28.987c-6.369 0-11.054-4.594-11.054-10.554S86.179 7.879 92.548 7.879s11.009 4.64 11.009 10.554-4.686 10.554-11.009 10.554zm0-17.241c-3.548 0-5.868 2.729-5.868 6.687s2.365 6.687 5.868 6.687 5.861-2.684 5.861-6.687-2.358-6.689-5.861-6.689z"
          fill="#fff"
        />
        <path
          data-name="Path 131"
          d="M116.253 28.81a10.048 10.048 0 01-10.372-10.417 9.974 9.974 0 0110.372-10.326 12.131 12.131 0 016.877 1.842.364.364 0 01.174.318v3.116a.372.372 0 01-.591.3 9.007 9.007 0 00-5.307-1.577 6.02 6.02 0 00-6.232 6.323 6.019 6.019 0 006.232 6.414 8.8 8.8 0 005.307-1.592.372.372 0 01.6.3v3.138a.4.4 0 01-.167.326 11.843 11.843 0 01-6.893 1.835z"
          fill="#fff"
        />
        <path
          data-name="Path 132"
          d="M136.682 28.987c-6.369 0-11.054-4.594-11.054-10.554s4.69-10.556 11.054-10.556 11.009 4.64 11.009 10.554-4.686 10.556-11.009 10.556zm0-17.241c-3.548 0-5.868 2.729-5.868 6.687s2.365 6.687 5.868 6.687 5.823-2.684 5.823-6.687-2.32-6.689-5.823-6.689z"
          fill="#fff"
        />
        <path
          data-name="Path 133"
          d="M151.121 27.878V1.342a.379.379 0 01.379-.379h4.551a.379.379 0 01.379.379v26.536a.379.379 0 01-.379.379h-4.549a.379.379 0 01-.381-.379z"
          fill="#fff"
        />
        <path
          data-name="Path 134"
          d="M176.673 27.901l-.1-1.782a.379.379 0 00-.66-.227 9.1 9.1 0 01-6.725 2.919c-5.307 0-9.234-4.458-9.234-10.372s3.913-10.372 9.231-10.372a9.1 9.1 0 016.725 2.911.379.379 0 00.66-.22l.1-1.789a.387.387 0 01.379-.356h4.041a.379.379 0 01.379.379v18.886a.379.379 0 01-.379.379h-4.041a.387.387 0 01-.379-.356zm-5.845-15.921c-3.093 0-5.6 2.411-5.6 6.46s2.5 6.46 5.6 6.46 5.6-2.411 5.6-6.46-2.5-6.467-5.6-6.467z"
          fill="#fff"
        />
        <path
          data-name="Path 135"
          d="M198.39 28.811a9.1 9.1 0 01-6.725-2.911.379.379 0 00-.66.227l-.1 1.782a.387.387 0 01-.379.356h-4.041a.379.379 0 01-.379-.379V1.35a.379.379 0 01.379-.379h4.549a.379.379 0 01.379.379v8.954a.379.379 0 00.644.265 9.1 9.1 0 016.361-2.51c5.307 0 9.234 4.458 9.234 10.372s-3.939 10.38-9.262 10.38zm-1.66-16.839c-3.093 0-5.6 2.411-5.6 6.46s2.5 6.46 5.6 6.46 5.6-2.411 5.6-6.46-2.484-6.46-5.6-6.46z"
          fill="#fff"
        />
        <path
          data-name="Path 136"
          d="M220.991 28.987c-6.369 0-11.054-4.594-11.054-10.554s4.693-10.554 11.054-10.554S232 12.519 232 18.433s-4.683 10.554-11.009 10.554zm0-17.241c-3.548 0-5.868 2.729-5.868 6.687s2.365 6.687 5.868 6.687 5.823-2.684 5.823-6.687-2.313-6.689-5.823-6.689z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

const LogoWhite = React.memo(LogoWhiteComponent)
export default LogoWhite
