import 'date-fns'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { CircularProgress, Breadcrumbs, Typography } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import {
  createOnboardingTeam,
  updateOnboardingTeam,
  getOnboardingTeam,
  createOnboardingTeamMember,
} from '@/stores/teams/api'
import { OnboardingTeamMemberRole, OnboardingUsageStatus, OnboardingTeam } from '@/stores/graphql'
import { createOnboardingActionsFromMaster } from '@/stores/actions/api'
import { Panel, InputText, Button, NativeSelect } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { replacePathParams } from '@/utils/history'
import { getOnboardingUsageStatusLabel } from '@/utils/labels'
import { Link } from 'react-router-dom'

type Inputs = {
  id: string
  title: string
  managerName: string
  managerId: string
  startedAt: string
  usageStatus: OnboardingUsageStatus
}

export const OnboardingTeamsEdit: React.FC = () => {
  const {
    teamId,
    // logId
  } = useParams<{ teamId: string; logId?: string }>()
  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  // const teamId = useMemo(() => match.params.teamId, [match])
  const [record, setRecord] = useState<OnboardingTeam | null | 'create'>(null)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const isFetching = useMemo(() => record === null, [record])
  const isCreate = useMemo(() => record === 'create', [record])

  const fetchRecord = async (id: string) => {
    const fetched = await getOnboardingTeam({ id })
    setRecord(fetched.data)
  }

  useEffect(() => {
    if (teamId) {
      console.log('fad?S')
      fetchRecord(teamId)
    } else {
      setRecord('create')
    }
  }, [teamId])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: any) => {
    console.log(data)
    setIsProcessing(true)

    if (isCreate) {
      try {
        const id = uuid()
        const input = {
          id,
          title: data.title,
          managerName: data.managerName,
          managerId: data.managerId,
        }

        const { data: createdTeam } = await createOnboardingTeam(input)
        console.log('createdTeam', createdTeam)

        const onboardingTeamMemberCreateInput = {
          teamId: createdTeam.id,
          email: createdTeam.managerId,
          role: OnboardingTeamMemberRole.Admin,
        }
        const { data: createdTeamMember } = await createOnboardingTeamMember(
          onboardingTeamMemberCreateInput
        )
        console.log('createdTeamMember', createdTeamMember)

        const { data: createdActions } = await createOnboardingActionsFromMaster(id)
        console.log('createdActions', createdActions)

        setIsProcessing(false)
        openSnackbar({ type: 'success', message: 'チームを作成しました' })
        history.push(replacePathParams(Paths.OnboardingTeamsActions, { teamId: id }))
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    } else {
      try {
        await updateOnboardingTeam(data)
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: 'チームを編集しました' })
        history.push(Paths.OnboardingTeams)
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isCreate && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}

        {!isCreate ? (
          <div className="mt-6">
            <NativeSelect
              label="利用状況"
              readOnly={isProcessing}
              name="usageStatus"
              defaultValue={get(record, 'usageStatus') || ''}
              options={[
                { value: '', label: '選択してください' },
                ...Object.entries(OnboardingUsageStatus).map(([, value]) => ({
                  value,
                  label: getOnboardingUsageStatusLabel(value),
                })),
              ]}
              selectRef={register({})}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="チーム名"
            name="title"
            defaultValue={get(record, 'title')}
            inputRef={register({
              required: ERRORS.inputRequired('チーム名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.title && errors.title.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="担当者名"
            name="managerName"
            defaultValue={get(record, 'managerName')}
            inputRef={register({
              required: ERRORS.inputRequired('担当者名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.managerName && errors.managerName.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="CocolaboID(メールアドレス)"
            name="managerId"
            defaultValue={get(record, 'managerId')}
            inputRef={register({
              required: ERRORS.inputRequired('CocolaboID(メールアドレス)'),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: ERRORS.inputMail,
              },
            })}
            error={errors.managerId && errors.managerId.message}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.OnboardingTeams}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {isCreate ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          <Link to={Paths.OnboardingTeams} className="hover:underline">
            オンボーディングチーム一覧
          </Link>
          <Typography color="primary">{get(record, 'title')}</Typography>
        </Breadcrumbs>
      </div>

      <div className="m-10 mt-5 ">
        <Panel title={`オンボーディング - ${isCreate ? 'チーム作成' : 'チーム編集'}`}>
          {isFetching ? renderProgress() : renderForm()}
        </Panel>
      </div>
    </>
  )
}
