import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Workbook } from 'exceljs'
import * as iconv from 'iconv-lite'
import { isString, isFunction } from 'lodash-es'
import {
  faPen,
  faPlus,
  faChevronDown,
  faFileCsv,
  faFileDownload,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt, faEye, faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { Button, SingleCheckBox } from '@/components/atoms'

export interface Column {
  label: string
  field?: any
  onSort?: any
  thClass?: string
  tdClass?: string
}
interface IProps {
  fetching: boolean
  title?: string | React.ReactElement
  data?: any[]
  columns: Column[]
  createPath?: string
  csvImportPath?: string
  csvExport?: CsvExport
  headerLink?: React.ReactElement
  editPath?(id: string): string | undefined
  previewPath?(id: string): string
  handleDelete?(id: string): void
  handleDeleteChecked?(ids: string[]): void
  otherHandlers?(record: any): React.ReactElement
  filters?: React.ReactElement
  onClickReadMore?(): Promise<void>
}

type CsvExport = {
  fileName: string
  columns: CsvExportColumn[]
  data: any[]
}

type CsvExportColumn = {
  header: string // ラベル名
  key: string // キー
}

const IndexLayout: React.FC<IProps> = ({
  fetching,
  title,
  columns,
  data,
  createPath,
  csvImportPath,
  csvExport,
  headerLink,
  filters,
  editPath,
  previewPath,
  handleDelete,
  handleDeleteChecked,
  otherHandlers,
  onClickReadMore,
}) => {
  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )

  const [deleteIds, setDeleteIds] = React.useState<string[]>([])
  const someChecked = React.useMemo(() => {
    return deleteIds.length > 0 && data && deleteIds.length !== data.length
  }, [data, deleteIds])

  const disabledDeleteButton = React.useMemo(() => {
    return deleteIds.length === 0
  }, [deleteIds])
  React.useEffect(() => {
    setDeleteIds([])
  }, [data])

  const handleDeleteFlagAll = (e: any) => {
    const checked = e.target.checked ?? false
    setDeleteIds((old) => {
      if (!data) {
        return old
      }
      return checked ? data.map((record) => record.id) : []
    })
  }

  const toggleDeleteFlag = (actionId: string) => {
    setDeleteIds((old) => {
      const foundIndex = old.findIndex((id) => id === actionId)
      if (foundIndex >= 0) {
        old.splice(foundIndex, 1)
        return [...old]
      }
      return [...old, actionId]
    })
  }

  const isDeleteTarget = React.useCallback(
    (id: string) => {
      return deleteIds.indexOf(id) >= 0
    },
    [deleteIds]
  )

  const handleDeleteCheckedAll = React.useCallback(() => {
    if (handleDeleteChecked) {
      handleDeleteChecked(deleteIds)
    }
  }, [deleteIds, handleDeleteChecked])

  const handleCsvExport = async (charCode: 'UTF8' | 'SJIS') => {
    if (csvExport) {
      const workbook = new Workbook()
      workbook.addWorksheet('sheet1')
      const worksheet = workbook.getWorksheet('sheet1')

      worksheet.columns = csvExport.columns
      worksheet.addRows(csvExport.data)

      const buffer = await workbook.csv.writeBuffer()
      const uint8Array = new Uint8Array(buffer)
      const utf8Buffer = Buffer.from(uint8Array)
      const convertedArray =
        charCode === 'UTF8'
          ? uint8Array
          : new Uint8Array(iconv.encode(iconv.decode(utf8Buffer, 'utf8'), 'Shift_JIS'))
      const blob = new Blob([convertedArray], { type: 'application/octet-binary' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${csvExport.fileName}.csv`
      a.click()
      a.remove()
    }
  }

  return (
    <div>
      <div className="m-10 mt-5 bg-white shadow rounded-lg">
        <div className="flex items-center justify-between px-6 py-8">
          {title && <h2 className="text-xl font-bold">{title}</h2>}
          <div className="flex gap-4">
            {headerLink && headerLink}
            {handleDeleteChecked && (
              <Button
                fit
                size="small"
                buttonType="caution"
                handleClick={handleDeleteCheckedAll}
                disabled={disabledDeleteButton}
              >
                削除
              </Button>
            )}
            {csvExport && (
              <>
                <Button
                  fit
                  size="small"
                  iconLeft={<FontAwesomeIcon className="text-white" icon={faFileDownload} />}
                  handleClick={() => handleCsvExport('SJIS')}
                  className="whitespace-pre-wrap leading-none"
                >
                  CSVエクスポート{'\n'}
                  <span className="text-xs">Windows用（Shift-JIS）</span>
                </Button>
                <Button
                  fit
                  size="small"
                  iconLeft={<FontAwesomeIcon className="text-white" icon={faFileDownload} />}
                  handleClick={() => handleCsvExport('UTF8')}
                  className="whitespace-pre-wrap leading-none"
                >
                  CSVエクスポート{'\n'}
                  <span className="text-xs">MacOS用（UTF-8）</span>
                </Button>
              </>
            )}
            {csvImportPath && (
              <Button
                fit
                size="small"
                iconLeft={<FontAwesomeIcon className="text-white" icon={faFileCsv} />}
                link={csvImportPath}
              >
                CSV一括追加
              </Button>
            )}
            {createPath && (
              <Button
                fit
                size="small"
                iconLeft={<FontAwesomeIcon className="text-white" icon={faPlus} />}
                link={createPath}
              >
                新規作成
              </Button>
            )}
          </div>
        </div>
        {filters && filters}
        <div className="overflow-scroll">
          <table className="border-collapse w-full break-all">
            <thead className="border-b border-gray-400">
              <tr className="w-full">
                {handleDeleteChecked && (
                  <th className="px-4 py-2 text-left text-xs font-normal">
                    <SingleCheckBox
                      value="active"
                      name="isPublished"
                      onChangeHandler={handleDeleteFlagAll}
                      defaultChecked={false}
                      someChecked={someChecked}
                    />
                  </th>
                )}
                {columns.map((column: any, i) => (
                  <th
                    key={i}
                    className={classNames(
                      'px-4 py-2 text-left text-xs font-normal text-gray-600 whitespace-no-wrap',
                      column.thClass,
                      isFunction(column.onSort) && 'cursor-pointer'
                    )}
                    onClick={() => {
                      if (isFunction(column.onSort)) {
                        column.onSort()
                      }
                    }}
                  >
                    {column.label}
                    {isFunction(column.onSort) && (
                      <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                    )}
                  </th>
                ))}
                {(editPath || previewPath || handleDelete || otherHandlers) && (
                  <th className="px-4 py-2 text-left text-xs font-normal text-gray-600">操作</th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-400 border-b border-gray-400">
              {data &&
                data.map((record: any) => (
                  <tr key={record.id} className="w-full">
                    {handleDeleteChecked && (
                      <td
                        className="w-24 px-4 py-3 text-sm"
                        onClick={() => {
                          toggleDeleteFlag(record.id)
                        }}
                      >
                        {isDeleteTarget(record.id) ? (
                          <FontAwesomeIcon
                            className="filled text-lg text-primary"
                            icon={faCheckSquare}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="empty text-lg text-neutral-600"
                            icon={faSquare}
                          />
                        )}
                      </td>
                    )}
                    {columns.map((column, i) => (
                      <td
                        key={`${record.id}-${i}`}
                        className={classNames([
                          'px-4 py-3 text-sm',
                          column.tdClass,
                          column.field === 'id' && 'block truncate',
                        ])}
                        style={{
                          maxWidth: column.field === 'id' ? 150 : 'auto',
                        }}
                      >
                        {isString(column.field) && record[column.field]}
                        {isFunction(column.field) && column.field(record)}
                      </td>
                    ))}
                    {(editPath || previewPath || handleDelete || otherHandlers) && (
                      <td className="w-24 px-4 py-3 text-sm">
                        <div className="flex items-center gap-2">
                          {editPath && editPath(record.id) && (
                            <Tooltip title="編集">
                              <Link
                                to={editPath(record.id) || ''}
                                className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-primary hover:bg-opacity-10 cursor-pointer"
                              >
                                <FontAwesomeIcon className="text-primary" icon={faPen} />
                              </Link>
                            </Tooltip>
                          )}
                          {previewPath && (
                            <Link
                              to={previewPath(record.id)}
                              className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-primary hover:bg-opacity-10 cursor-pointer"
                            >
                              <FontAwesomeIcon className="text-primary" icon={faEye} />
                            </Link>
                          )}
                          {handleDelete && (
                            <div
                              onClick={() => {
                                if (window.confirm('本当に削除しますか？')) {
                                  handleDelete(record.id)
                                }
                              }}
                              className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-red hover:bg-opacity-10 cursor-pointer"
                            >
                              <FontAwesomeIcon className="text-red" icon={faTrashAlt} />
                            </div>
                          )}
                          {otherHandlers && otherHandlers(record)}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {!fetching && onClickReadMore && (
          <div className="py-6 flex justify-center">
            <Button handleClick={onClickReadMore} buttonType="secondary" size="small">
              もっと見る
            </Button>
          </div>
        )}
        {fetching && renderProgress()}
      </div>
    </div>
  )
}

export default IndexLayout
