import 'date-fns'
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Invoice, InvoiceDetailTeam, InvoiceStatus, InvoiceType } from '@/stores/graphql'
import { Panel, InputText, Button, RenderProgress, NativeSelect } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { getInvoiceStatusLabel, getDiscountTypeLabel } from '@/utils/labels'
import { useInvoice, useInvoiceManage } from '@/hooks/invoices'

type Inputs = {
  id: string
  amount?: number
  cost?: number
  discount?: number
  status: InvoiceStatus
}

export const GeneralInvoicesEdit: React.FC = () => {
  const { invoiceId } = useParams<{ invoiceId?: string }>()
  const history = useHistory()
  const { openSnackbar } = React.useContext(SnackbarContext)
  const [record, setRecord] = React.useState<Invoice | undefined>(undefined)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { invoice, invoiceDetails } = useInvoice(invoiceId)
  const { updateInvoice } = useInvoiceManage()

  React.useEffect(() => {
    if (invoice) {
      setRecord(invoice)
    }
  }, [invoice])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    console.log(data)
    setIsProcessing(true)

    if (invoiceId) {
      // const input = {
      //   id: data.id,
      //   title: data.title,
      //   managerName: data.managerName,
      //   managerId: data.managerId,
      // }

      try {
        await updateInvoice(data)
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: '請求情報を編集しました' })
        history.push(Paths.Invoices)
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {invoiceId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly
            label="メールアドレス"
            name="userEmail"
            defaultValue={get(record, 'userEmail')}
          />
        </div>

        <hr className="mt-6 " />

        <div className="mt-6">
          <NativeSelect
            label="請求状況"
            readOnly={isProcessing}
            name="status"
            defaultValue={get(record, 'status') || ''}
            options={[
              { value: '', label: '選択してください' },
              ...Object.entries(InvoiceStatus).map(([, value]) => ({
                value,
                label: getInvoiceStatusLabel(value),
              })),
            ]}
            selectRef={register({})}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="請求額"
            name="amount"
            defaultValue={get(record, 'amount')?.toString()}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.amount && errors.amount.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="価格"
            name="cost"
            defaultValue={get(record, 'cost')?.toString()}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.cost && errors.cost.message}
          />
        </div>
        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="割引"
            name="discount"
            defaultValue={get(record, 'discount')?.toString()}
            inputRef={register({
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.discount && errors.discount.message}
          />
        </div>

        <div>
          {(() => {
            if (record && record.type === InvoiceType.Team) {
              const details = invoiceDetails as InvoiceDetailTeam[]

              return (
                <div className="mt-6">
                  <p className="text-sm">詳細</p>

                  <table className="border-collapse w-full break-all">
                    <thead className="border-b border-gray-400">
                      <tr className="w-full text-left text-xs font-normal text-gray-600 whitespace-no-wrap">
                        <th className={'px-4 py-2 w-10/12'}>チーム名</th>
                        <th className={'px-4 py-2 w-2/12'}>価格</th>
                        <th className={'px-4 py-2 w-2/12'}>割引</th>
                        <th className={'px-4 py-2 w-2/12'}>割引種別</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-400 border-b border-gray-400">
                      {details.map((t, index) => (
                        <tr key={`invoice-optional-teams-${index}`} className="w-full text-sm">
                          <td className="px-4 py-3">{t.teamName}</td>
                          <td className="px-4 py-3 whitespace-no-wrap">
                            {t.cost.toLocaleString()}
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap">
                            {t.discount.toLocaleString()}
                          </td>
                          <td className="px-4 py-3 whitespace-no-wrap">
                            {getDiscountTypeLabel(t.discountType)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }
          })()}
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.Invoices}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!invoiceId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      <Panel title={`請求情報${!invoiceId ? '作成' : '編集'}`}>
        {!record ? <RenderProgress /> : renderForm()}
      </Panel>
    </div>
  )
}
