import React from 'react'
import { OnboardingTeamMember } from '@/stores/graphql'
import { queryOnboardingTeamMemberList } from './api'

export const useOnboardingTeamMemberList = (teamId?: string) => {
  const limit = 1000
  const [items, setItems] = React.useState<OnboardingTeamMember[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      return
    }

    setLoading(true)
    setError(undefined)
    try {
      const response = await queryOnboardingTeamMemberList({ teamId, first: limit })
      if (response && response.items) {
        setItems(
          response.items.reduce((acc: OnboardingTeamMember[], cur) => {
            if (cur) {
              return [...acc, cur]
            } else {
              return acc
            }
          }, [])
        )
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!teamId || !nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryOnboardingTeamMemberList({
        teamId,
        first: limit,
        after: nextToken,
      })
      if (response && response.items) {
        setItems([
          ...items,
          ...response.items.reduce((acc: OnboardingTeamMember[], cur) => {
            if (cur) {
              return [...acc, cur]
            } else {
              return acc
            }
          }, []),
        ])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId, items, nextToken])

  return { items, nextToken, loading, error, refresh, more } as const
}
