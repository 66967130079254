import gql from 'graphql-tag'

export const QueryGetOnboardingActionList = gql`
  query GetOnboardingActionList(
    $filter: OnboardingActionFilter
    $first: Int
    $after: String
    $teamId: String
  ) {
    getOnboardingActionList(filter: $filter, first: $first, after: $after, teamId: $teamId) {
      items {
        id
        teamId
        mission
        category
        createdAt
        how
        publishAt
        updatedAt
        importance
        what
        why
        period
        deadline
        isPublished
        onboardingTeam {
          id
          title
        }
      }
      nextToken
    }
  }
`

export const QueryGetOnboardingAction = `query GetOnboardingAction($id: ID!) {
  getOnboardingAction(id: $id) {
    id
    teamId
    category
    mission
    why
    what
    how
    importance
    period
    deadline
    isPublished
    publishAt
    updatedAt
  }
}`

export const MutationCreateOnboardingAction = `mutation CreateOnboardingAction($input: OnboardingActionCreateInput!) {
  createOnboardingAction(input: $input) {
    id
    teamId
    category
    mission
    why
    what
    how
    importance
    publishAt
    updatedAt
  }
}`

export const MutationCreateOnboardingActionsFromMaster = `mutation CreateOnboardingActionsFromMaster($teamId: ID!) {
  createOnboardingActionsFromMaster(teamId: $teamId)
}`

export const MutationUpdateOnboardingAction = `mutation UpdateOnboardingAction($input: OnboardingActionUpdateInput!) {
  updateOnboardingAction(input: $input) {
    id
    teamId
    category
    mission
    why
    what
    how
    importance
    publishAt
    updatedAt
  }
}`

export const QueryGetAdmin = `query GetAdmin($id: ID!) {
  getAdmin(id: $id) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`

export const MutationUpdateAdmin = `mutation UpdateAdmin($input: AdminUpdateInput!) {
  updateAdmin(input: $input) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`

export const MutationDeleteAdmin = `mutation deleteAdmin($id: ID!) {
  deleteAdmin(id: $id) {
    id
    email
    nickname
    createdAt
    updatedAt
  }
}`
