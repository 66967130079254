import 'date-fns'
import React, { useState, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, NativeSelect } from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { TeamBuildingActionDateStatus } from '@/stores/graphql'
import { getTeamBuildingActionDateStatusLabel } from '@/utils/labels'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import {
  useTeamBuildingActionDateManage,
  useTeamBuildingsActionDate,
} from '@/hooks/teamsBuildingsActionsDates'

type Inputs = {
  id: string
  date: string
  status: TeamBuildingActionDateStatus
}

export const GeneralTeamsBuildingsActionsDatesEdit: React.FC = () => {
  const { teamBuildingActionDateId, teamBuildingActionId, teamId } = useParams<{
    teamBuildingActionDateId?: string
    teamBuildingActionId: string
    teamId: string
  }>()

  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)

  const { team } = useTeam(teamId)
  const { record, loading } = useTeamBuildingsActionDate(teamBuildingActionDateId)
  const {
    createTeamBuildingActionDate,
    updateTeamBuildingActionDate,
  } = useTeamBuildingActionDateManage()

  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)

      if (!teamBuildingActionDateId) {
        const [created, createdErrors] = await createTeamBuildingActionDate({
          teamBuildingActionId,
          ...data,
        })
        if (created) {
          openSnackbar({ type: 'success', message: '作成しました' })
          history.push(
            replacePathParams(Paths.TeamBuildingsActionsDates, { teamId, teamBuildingActionId })
          )
        } else {
          openSnackbar({
            type: 'error',
            message: get(createdErrors, '[0].message', 'エラーが発生しました'),
          })
        }
      } else {
        const [updated, updatedErrors] = await updateTeamBuildingActionDate({
          ...data,
        })
        if (updated) {
          openSnackbar({ type: 'success', message: '更新しました' })
        } else {
          openSnackbar({
            type: 'error',
            message: get(updatedErrors, '[0].message', 'エラーが発生しました'),
          })
        }
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {teamBuildingActionDateId && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={teamBuildingActionDateId}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <NativeSelect
            label="ステータス"
            readOnly={isProcessing}
            name="status"
            defaultValue={get(record, 'status') || ''}
            options={Object.entries(TeamBuildingActionDateStatus).map(([, value]) => ({
              value,
              label: getTeamBuildingActionDateStatusLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            required={true}
            label="日付"
            name="date"
            defaultValue={get(record, 'date')}
            inputRef={register({ required: true })}
            error={errors.date && errors.date.message}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              handleClick={() => history.goBack()}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!teamBuildingActionDateId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          title={
            <>
              <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
                チーム一覧
              </Link>
              &nbsp;/&nbsp;
              {team.name}
              <span>
                &nbsp;/&nbsp;チームビルディングアクション - スケジュール
                {!teamBuildingActionDateId ? '作成' : '編集'}
              </span>
            </>
          }
        >
          {loading ? <RenderProgress /> : renderForm()}
        </Panel>
      )}
    </div>
  )
}
