import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import {
  QueryGetOnboardingTeamList,
  QueryGetOnboardingTeam,
  MutationCreateOnboardingTeam,
  MutationUpdateOnboardingTeam,
  MutationCreateOnboardingTeamMember,
} from './graphql'
import {
  OnboardingTeam,
  OnboardingTeamCreateInput,
  OnboardingTeamUpdateInput,
  OnboardingTeamMemberCreateInput,
  QueryGetOnboardingTeamListArgs,
} from '../graphql'
import { DEFAULT_PER_PAGE } from '@/constants/api'

export const getOnboardingTeamList = async ({
  filter,
  first = DEFAULT_PER_PAGE,
  after = null,
}: QueryGetOnboardingTeamListArgs) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingTeamList,
    variables: { filter, first, after },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('QueryGetOnboardingTeamList', response)

  return {
    data: [...response.data.getOnboardingTeamList.items],
    nextToken: response?.data?.getOnboardingTeamList?.nextToken,
  }
}

export const getOnboardingTeam = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: QueryGetOnboardingTeam,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('QueryGetOnboardingTeam', response)

  return {
    data: { ...response.data.getOnboardingTeam },
  }
}

export const createOnboardingTeam = async (input: OnboardingTeamCreateInput) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingTeam,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationCreateOnboardingTeam response', response)

  return {
    data: { ...(response.data.createOnboardingTeam as OnboardingTeam) },
  }
}

export const createOnboardingTeamMember = async (input: OnboardingTeamMemberCreateInput) => {
  const response: any = await API.graphql({
    query: MutationCreateOnboardingTeamMember,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationCreateOnboardingTeamMember response', response)

  return {
    data: { ...response.data.createOnboardingTeamMember },
  }
}

export const updateOnboardingTeam = async (input: OnboardingTeamUpdateInput) => {
  const response: any = await API.graphql({
    query: MutationUpdateOnboardingTeam,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  console.log('MutationUpdateOnboardingTeam response', response)

  return {
    data: { ...response.data.updateOnboardingTeam },
  }
}
