import React, { useContext } from 'react'
import classNames from 'classnames'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { SnackbarContext } from '@/components/Snackbar'
import { Paths } from '@/constants'
import { LogoWhite } from '@/components/atoms'
import { connector, ContainerProps } from './index.container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { startsWith } from 'lodash-es'

interface NavItem {
  label: string
  path?: string
  disabled?: boolean
}

const NAV_ITEMS: NavItem[] = [
  { label: '一般' },
  { label: 'ユーザー', path: Paths.Users },
  { label: '法人', path: Paths.Companies },
  { label: 'チーム', path: Paths.Teams },
  { label: '請求情報', path: Paths.Invoices },
  { label: 'クレジットエラー', path: Paths.Errors, disabled: true },

  { label: 'オンボーディング' },
  { label: 'チーム', path: Paths.OnboardingTeams },
  { label: 'アクション', path: Paths.OnboardingActions },

  { label: '管理' },
  { label: 'マスター', path: Paths.Masters },
  { label: 'メンテナンス', path: Paths.Maintain },
  { label: '管理者', path: Paths.Admins },
]

const Sidebar: React.FC<ContainerProps & RouteComponentProps> = ({
  children,
  signOut,
  location,
  // ...props
}) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const handleSignOut = () => {
    const callbacks = {
      success: () => {
        openSnackbar({ type: 'success', message: 'ログアウトしました' })
      },
      error: () => {
        openSnackbar({ type: 'error', message: 'エラーが発生しました' })
      },
    }
    signOut({ callbacks })
  }

  const renderNavItem = (item: NavItem, index: number) => {
    const isActive = startsWith(location.pathname, item.path)

    return (
      <li key={index} className={`relative`}>
        {item.path ? (
          <Link
            className="flex items-center px-8 py-3 hover:text-opacity-100"
            to={!item.disabled ? item.path : '#'}
          >
            <span
              className={classNames('font-bold text-sm text-white', {
                'text-opacity-75': !isActive,
              })}
              style={{
                opacity: item.disabled ? 0.3 : 1,
              }}
            >
              {item.label}
            </span>
            {isActive && <span className="w-1 absolute inset-y-0 left-0 bg-primary rounded-r" />}
          </Link>
        ) : (
          <span
            className={classNames('flex items-center px-4 py-3 font-bold text-sm text-white', {
              'text-opacity-75': !isActive,
            })}
            style={{
              backgroundColor: '#0E1A1A',
            }}
          >
            {item.label}
          </span>
        )}
      </li>
    )
  }

  return (
    <div className="flex w-full">
      <div
        className="w-56 min-h-screen shadow bg-black-sidebar"
        style={{
          maxWidth: '14rem',
        }}
      >
        <div className="sticky top-0 py-8">
          <div className="flex justify-center">
            <div className="w-40">
              <LogoWhite />
            </div>
          </div>
          <ul className="mt-10">{NAV_ITEMS.map((item, i) => renderNavItem(item, i))}</ul>
          <div className="mt-4 px-4">
            <div className="pt-4 border-t border-white border-opacity-25">
              <span
                className="font-bold text-sm text-white text-opacity-75 cursor-pointer"
                onClick={handleSignOut}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span className="inline-block ml-2">サインアウト</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-1 min-h-screen"
        style={{
          width: 'calc(100vw - 14rem)',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default withRouter(connector(Sidebar))
