import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash-es'
import { Link } from 'react-router-dom'
import { getOnboardingTeamList } from '@/stores/teams/api'
import { OnboardingTeam, OnboardingTeamFilter } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Batch, Search } from '@/components/atoms'
import usePrevious from '@/utils/usePrevious'
import moment from 'moment'
import { replacePathParams } from '@/utils/history'
import { getOnboardingUsageStatusColor, getOnboardingUsageStatusLabel } from '@/utils/labels'
import { IconButton, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faHandPointRight, faUser } from '@fortawesome/free-solid-svg-icons'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '利用状況',
    // eslint-disable-next-line react/display-name
    field: (record: OnboardingTeam) => (
      <Batch
        title={getOnboardingUsageStatusLabel(record.usageStatus)}
        backgroundColor={getOnboardingUsageStatusColor(record.usageStatus)}
      />
    ),
  },
  {
    label: 'チーム名',
    field: 'title',
  },
  {
    label: '担当者名',
    field: 'managerName',
  },
  {
    label: 'メールアドレス',
    field: 'managerId',
  },
  {
    label: 'メンバー数',
    field: 'memberCount',
  },
  {
    label: '作成日',
    // eslint-disable-next-line react/display-name
    field: (record: OnboardingTeam) => (
      <span className="whitespace-no-wrap">{moment(record.createdAt).format('YYYY/MM/DD')}</span>
    ),
  },
]

interface IRecords {
  data: OnboardingTeam[]
  fetching: boolean
}

export const OnboardingTeams: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [records, setRecords] = useState<IRecords>({
    data: [],
    fetching: false,
  })
  const [nextToken, setNextToken] = useState<null | string>(null)
  const [filter, setFilter] = useState<OnboardingTeamFilter>({})
  const prevFilter = usePrevious(filter)

  const getData = async (
    filter: OnboardingTeamFilter,
    token: null | string = null,
    replaceRecords = false
  ) => {
    console.log('getData args', { token, filter, replaceRecords })
    setRecords((current: IRecords) => ({
      data: replaceRecords ? [] : [...current.data],
      fetching: true,
    }))

    const { data, nextToken: newNextToken } = await getOnboardingTeamList({
      filter,
      after: token,
    })

    console.log('getData', { data, newNextToken })
    setRecords({ data: replaceRecords ? data : [...records.data, ...data], fetching: false })
    setNextToken(newNextToken)
  }

  // const handleDelete = async (id: string) => {
  //   // try {
  //   //   await deleteAdmin({ id })
  //   //   setRecords((current: IRecords) => ({
  //   //     ...current,
  //   //     data: current.data.filter((item: any) => item.id !== id),
  //   //   }))
  //   //   openSnackbar({ type: 'success', message: 'アドミンを削除しました' })
  //   // } catch {
  //   //   openSnackbar({ type: 'error', message: 'エラーが発生しました' })
  //   // }
  // }

  const onApplyKeywordSearch = async (title: string) => {
    console.log('onApplyKeywordSearch', title)
    const nextFilter = { ...filter }
    if (title) {
      nextFilter.title = title
    } else {
      delete nextFilter.title
    }
    setFilter(nextFilter)
  }

  const onApplyManagerIdSearch = async (managerId: string) => {
    console.log('onApplyKeywordSearch', managerId)
    const nextFilter = { ...filter }
    if (managerId) {
      nextFilter.managerId = managerId
    } else {
      delete nextFilter.managerId
    }
    setFilter(nextFilter)
  }

  useEffect(() => {
    const isFilterUpdated = !isEqual(filter, prevFilter)

    console.log('useEffect [filter]', {
      filter,
      prevFilter,
      isFilterUpdated,
    })

    if (isFilterUpdated) {
      getData(filter, null, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const onClickReadMore = React.useCallback(async () => {
    getData(filter, nextToken, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, nextToken])

  return (
    <IndexLayout
      fetching={records.fetching}
      title="オンボーディング - チーム"
      data={records.data}
      columns={COLUMNS}
      createPath={Paths.OnboardingTeamsNew}
      editPath={(id: string) => replacePathParams(Paths.OnboardingTeamsEdit, { teamId: id })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? onClickReadMore : undefined}
      otherHandlers={(record: OnboardingTeam) => (
        <>
          <Tooltip title="ダッシュボード">
            <Link
              className="underline text-primary whitespace-no-wrap"
              to={replacePathParams(Paths.OnboardingTeamsDashboard, { teamId: record.id })}
            >
              <IconButton size="small">
                <FontAwesomeIcon className="text-primary" icon={faClipboardList} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="アクション一覧">
            <Link
              className="underline text-primary whitespace-no-wrap"
              to={replacePathParams(Paths.OnboardingTeamsActions, { teamId: record.id })}
            >
              <IconButton size="small">
                <FontAwesomeIcon className="text-primary" icon={faHandPointRight} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="ユーザー一覧">
            <Link
              className="underline text-primary whitespace-no-wrap"
              to={replacePathParams(Paths.OnboardingTeamsUsers, { teamId: record.id })}
            >
              <IconButton size="small">
                <FontAwesomeIcon className="text-primary" icon={faUser} />
              </IconButton>
            </Link>
          </Tooltip>
        </>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search handleSearch={onApplyKeywordSearch} placeholder="チーム名検索" />
            </div>
            <div className="ml-2">
              <Search handleSearch={onApplyManagerIdSearch} placeholder="メールアドレス検索" />
            </div>
          </div>
        </div>
      }
    />
  )
}
