import 'date-fns'
import React, { useState, useContext } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, Select } from '@/components/atoms'
import { Paths } from '@/constants'
import { get } from 'lodash-es'
import { TeamBuildingReviewStatus, SelectOption } from '@/stores/graphql'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import {
  mutationCreateTeamBuildingReview,
  mutationDeleteTeamBuilding,
  mutationUpdateTeamBuildingReview,
} from '@/hooks/graphql'
import { useTeamBuildingsReview } from '@/hooks/teamsBuildingsReviews'

type Inputs = {
  iceBreak: string
  iceBreakMinute: number
  iceBreakNumber: number
  iceBreakUserIds: string
  nextAt: string
  status: SelectOption
}

export const GeneralTeamsBuildingReviewEdit: React.FC = () => {
  const { teamId, reviewId } = useParams<{ teamId: string; reviewId?: string }>()

  const { team } = useTeam(teamId)

  const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { register, handleSubmit, control } = useForm<Inputs>()

  const { item, loaded } = useTeamBuildingsReview(reviewId)

  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)

      if (!reviewId) {
        // Create
        const created = await mutationCreateTeamBuildingReview({
          input: {
            teamId,
          },
        })
        if (created) {
          openSnackbar({ type: 'success', message: '作成しました' })
        }
      } else {
        // Update
        const updated = await mutationUpdateTeamBuildingReview({
          input: {
            id: reviewId,
            iceBreak: data.iceBreak,
            iceBreakMinute: data.iceBreakMinute,
            iceBreakNumber: data.iceBreakNumber,
            iceBreakUserIds: data.iceBreakUserIds.split(','),
            nextAt: data.nextAt,
            status: data.status.value as TeamBuildingReviewStatus,
          },
        })
        if (updated) {
          openSnackbar({ type: 'success', message: '更新しました' })
        }
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  const [hiddenCommand, setHiddenCommand] = React.useState(false)
  const keyDownFunction = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.metaKey && e.ctrlKey && e.keyCode === 40) {
        setHiddenCommand(!hiddenCommand)
      }
    },
    [hiddenCommand]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', keyDownFunction, false)

    return () => {
      document.removeEventListener('keydown', keyDownFunction)
    }
  }, [keyDownFunction])

  const handleDelete = async () => {
    if (window.confirm('本当に削除しますか?')) {
      const deleted = await mutationDeleteTeamBuilding({
        id: teamId,
      })

      if (deleted) {
        openSnackbar({ type: 'success', message: '削除しました' })
        window.location.reload()
      } else {
        openSnackbar({ type: 'error', message: '削除に失敗しました' })
      }
    }
  }

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          title={
            <>
              <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
                チーム一覧
              </Link>
              &nbsp;/&nbsp;
              {team.name}
              <span>&nbsp;/&nbsp;振り返り{reviewId ? '編集' : '追加'}</span>
            </>
          }
        >
          <div className="text-right ">
            <Link
              className="text-primary underline "
              to={replacePathParams(Paths.TeamBuildingsReview, { teamId })}
            >
              振り返り一覧
            </Link>

            {item && (
              <>
                <span className="px-2">/</span>
                <Link
                  className="text-primary underline "
                  to={replacePathParams(Paths.TeamBuildingsReviewMembers, { teamId, reviewId })}
                >
                  回答一覧
                </Link>
              </>
            )}
          </div>

          {!loaded ? (
            <RenderProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {item && (
                <>
                  <div className="mt-6">
                    <InputText
                      readOnly
                      label="ID"
                      name="id"
                      defaultValue={item.id}
                      inputRef={register()}
                    />
                  </div>

                  <div className="mt-6">
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={{ value: item.status, label: item.status }}
                      render={({ onChange, name, value }) => (
                        <>
                          <Select
                            label="ステータス"
                            name={name}
                            value={value}
                            options={Object.entries(TeamBuildingReviewStatus).map(([, value]) => ({
                              value,
                              label: value,
                            }))}
                            onChange={onChange}
                          />
                        </>
                      )}
                    />
                  </div>

                  <div className="mt-6">
                    <InputText
                      label="アイスブレイク"
                      name="iceBreak"
                      defaultValue={item.iceBreak}
                      inputRef={register()}
                    />
                  </div>

                  <div className="mt-6">
                    <InputText
                      label="アイスブレイク人数"
                      name="iceBreakMinute"
                      defaultValue={item.iceBreakMinute}
                      type={'number'}
                      inputRef={register()}
                    />
                  </div>

                  <div className="mt-6">
                    <InputText
                      label="アイスブレイク時間（分）"
                      name="iceBreakNumber"
                      defaultValue={item.iceBreakNumber}
                      type={'number'}
                      inputRef={register()}
                    />
                  </div>

                  <div className="mt-6">
                    <InputText
                      label="アイスブレイク発表者"
                      name="iceBreakUserIds"
                      defaultValue={item.iceBreakUserIds?.join(',')}
                      inputRef={register()}
                    />
                  </div>

                  <div className="mt-6">
                    <InputText
                      label="次回予定日"
                      name="nextAt"
                      defaultValue={item.nextAt}
                      inputRef={register()}
                    />
                  </div>
                </>
              )}

              {isProcessing ? (
                <div className="flex justify-center mt-10 mb-10">
                  <CircularProgress />
                </div>
              ) : (
                <div className="relative flex justify-center mt-10 gap-4">
                  <Button
                    className="absolute left-0"
                    buttonType="muted"
                    type="button"
                    fit={true}
                    handleClick={() => history.goBack()}
                  >
                    キャンセル
                  </Button>
                  <Button buttonType="primary" type="submit" fit={false}>
                    {!reviewId ? '作成' : '更新'}
                  </Button>
                </div>
              )}
            </form>
          )}
        </Panel>
      )}

      {hiddenCommand && (
        <div className="flex justify-center p-4">
          <div onClick={handleDelete} className="text-red font-bold underline cursor-pointer">
            削除する
          </div>
        </div>
      )}
    </div>
  )
}
