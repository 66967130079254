import 'date-fns'
import React from 'react'
// import { v4 as uuid } from 'uuid'
import { CircularProgress } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button, RenderProgress, NativeSelect, Select } from '@/components/atoms'
import { ERRORS, Paths } from '@/constants'
import { get } from 'lodash-es'
import { useTeam, useTeamManage } from '@/hooks/teams'
import { useUser } from '@/hooks/users'
import {
  MasterType,
  PaymentMethod,
  RelatedTeamPlan,
  RelatedTeamPlanStatus,
  SelectOption,
  Team,
  TeamStatusUsage,
} from '@/stores/graphql'
import { getPaymentMethodLabel, getTeamStatusUsageLabel } from '@/utils/labels'
import { replacePathParams } from '@/utils/history'
import { useMasters } from '@/hooks/masters'

type Inputs = {
  id: string
  email?: string
  name: string
  paymentMethod: PaymentMethod
  statusUsage: TeamStatusUsage
  teamPlanIds: SelectOption[]
  userLimit: number
}

export const GeneralTeamsEdit: React.FC = () => {
  const { teamId } = useParams<{ teamId: string; logId?: string }>()
  const history = useHistory()
  const { openSnackbar } = React.useContext(SnackbarContext)
  const [record, setRecord] = React.useState<Team | undefined>(undefined)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { register, handleSubmit, errors, control, setValue } = useForm<Inputs>()

  const { team, loading } = useTeam(teamId)

  const { user: ownerUser } = useUser(team?.ownerUserId)

  const { masters: teamPlans } = useMasters(MasterType.TeamPlan)

  const { createTeam, updateTeam, errors: updateTeamErrors } = useTeamManage()

  React.useEffect(() => {
    setRecord(team)
  }, [team])

  React.useEffect(() => {
    if (teamPlans.length > 0) {
      const stringPlans = get(record, 'plans')
      const plans = stringPlans ? (JSON.parse(stringPlans) as RelatedTeamPlan[]) : []

      setValue(
        'teamPlanIds',
        plans.reduce((acc: { label: string; value: string }[], cur) => {
          if (cur.status === RelatedTeamPlanStatus.Enable) {
            return [
              ...acc,
              {
                label: cur.name,
                value: cur.id,
              },
            ]
          }
          return acc
        }, [])
      )
    }
  }, [teamPlans, team, setValue, record])
  // { value: plan.id, label: plan.name ?? '未設定' }
  const onSubmit = async (data: Inputs) => {
    try {
      console.log(data)
      setIsProcessing(true)

      if (!teamId) {
        if (!data.email) {
          return
        }

        const [response] = await createTeam({
          ...data,
          email: data.email!,
          paymentMethod: PaymentMethod.Invoice,
          teamPlanIds: data.teamPlanIds.map((teamPlanId) => teamPlanId.value),
        })

        if (response) {
          openSnackbar({ type: 'success', message: '作成しました' })
          history.push(replacePathParams(Paths.Teams))
        }
      } else {
        delete data.email

        const response = await updateTeam({
          ...data,
          teamPlanIds: data.teamPlanIds.map((teamPlanId) => teamPlanId.value),
        })

        if (response) {
          openSnackbar({ type: 'success', message: '更新しました' })
          history.push(Paths.Teams)
        }
      }
    } catch (error) {
      console.log(error)
      const message = get(error, 'errors[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    } finally {
      setIsProcessing(false)
    }
  }

  React.useEffect(() => {
    if (updateTeamErrors) {
      console.log('updateTeamErrors', updateTeamErrors)
      const message = get(updateTeamErrors, '[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    }
  }, [openSnackbar, updateTeamErrors])

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {ownerUser ? (
          <>
            <div className="mt-6">
              <InputText
                readOnly={true}
                label={`管理者名`}
                defaultValue={get(ownerUser, 'fullName')}
              />
            </div>

            <div className="mt-6">
              <InputText
                readOnly={true}
                label={`管理者メールアドレス`}
                defaultValue={get(ownerUser, 'email')}
              />
            </div>

            <hr className="mt-6" />
          </>
        ) : (
          <></>
        )}

        {teamId ? (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        ) : (
          <div className="mt-6">
            <InputText
              required
              label="管理者メールアドレス"
              name="email"
              // defaultValue={get(record, 'id')}
              inputRef={register({
                required: ERRORS.inputMail,
              })}
              error={errors.email && errors.email.message}
            />
          </div>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="チーム名"
            name="name"
            defaultValue={get(record, 'name')}
            inputRef={register({
              required: ERRORS.inputRequired('チーム名'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.name && errors.name.message}
          />
        </div>

        <div className="mt-6">
          <NativeSelect
            label="決済方法"
            readOnly={isProcessing}
            name="paymentMethod"
            defaultValue={get(record, 'paymentMethod') || ''}
            options={Object.entries(PaymentMethod).map(([, value]) => ({
              value,
              label: getPaymentMethodLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <NativeSelect
            label="利用状況"
            readOnly={isProcessing}
            name="statusUsage"
            defaultValue={get(record, 'statusUsage') || ''}
            options={Object.entries(TeamStatusUsage).map(([, value]) => ({
              value,
              label: getTeamStatusUsageLabel(value),
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="人数上限"
            name="userLimit"
            defaultValue={get(record, 'userLimit')}
            inputRef={register({
              required: ERRORS.inputRequired('人数上限'),
            })}
            type="number"
            error={errors.userLimit && errors.userLimit.message}
          />
        </div>

        <div className="mt-6">
          <Controller
            name="teamPlanIds"
            control={control}
            render={({ onChange, name, value }) => (
              <>
                <Select
                  label="プラン（編集不可）"
                  // readOnly={isProcessing}
                  name={name}
                  value={value}
                  options={teamPlans.map((teamPlan) => ({
                    value: teamPlan.id,
                    label: teamPlan.name,
                  }))}
                  onChange={onChange}
                  isMulti={true}
                  isDisabled={true}
                />
              </>
            )}
          />
        </div>

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={Paths.Teams}
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {!teamId ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <div className="p-8">
      <Panel
        title={
          <>
            <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
              チーム一覧
            </Link>
            <span> / チーム{!teamId ? '作成' : '編集'}</span>
          </>
        }
      >
        {loading ? <RenderProgress /> : renderForm()}
      </Panel>
    </div>
  )
}
