import React from 'react'
import './styles/tailwind.css'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { Paths } from './constants'
import Sidebar from './layouts/Sidebar/index.component'
import SignIn from './pages/signin/index.component'
import Restore from './pages/signin/restore.component'
import ResetPassword from './pages/signin/resetPassword.component'
import ForceChangePassword from './pages/signin/forceChangePassword.component'

import {
  GeneralTeams,
  GeneralTeamsEdit,
  OnboardingTeams,
  OnboardingTeamsEdit,
  OnboardingTeamsActions,
  OnboardingActions,
  OnboardingActionsEdit,
  ManageAdmins,
  ManageAdminEdit,
  GeneralUsers,
  GeneralUsersEdit,
  GeneralUsersTeams,
  GeneralTeamsMembers,
  GeneralTeamsMembersEdit,
  GeneralTeamsMembersCsv,
  GeneralTeamsCsv,
  GeneralInvoicesEdit,
  GeneralInvoices,
  OnboardingTeamsUsers,
  OnboardingTeamsUsersEdit,
  OnboardingTeamsUsersCsv,
  OnboardingActionsCsv,
} from './pages'

import PrivateRoute from './components/PrivateRoute/index.component'
import SnackbarProvider from './components/Snackbar'
import ModalProvider from './components/Modal'
import { GeneralTeamsBuildingsEdit } from './pages/generals/teamsBuildings/edit.component'
import { ManageMasters } from './pages/manages/masters/index.component'
import { ManageMasterEdit } from './pages/manages/masters/edit.component'
import { ManageMasterCsv } from './pages/manages/masters/csv.component'
import { GeneralTeamsBuildingsActions } from './pages/generals/teamsBuildings/teamsBuildingsActions/index.component'
import { GeneralTeamsBuildingsActionsEdit } from './pages/generals/teamsBuildings/teamsBuildingsActions/edit.component'
import { GeneralTeamsBuildingsActionsDates } from './pages/generals/teamsBuildings/teamsBuildingsActions/teamsBuildingsActionsDates/index.component'
import { GeneralTeamsBuildingsActionsDatesEdit } from './pages/generals/teamsBuildings/teamsBuildingsActions/teamsBuildingsActionsDates/edit.component'
import { GeneralTeamsPlans } from './pages/generals/teamPlans/index.component'
import { GeneralCompanies } from './pages/generals/companies/index.component'
import { OnboardingTeamsDashboard } from './pages/onboardings/teams/dashboard/index.component'
import { GeneralTeamsBuildingsReview } from './pages/generals/teamsBuildingsReview/index.component'
import { GeneralTeamsBuildingReviewEdit } from './pages/generals/teamsBuildingsReview/edit.component'
import { GeneralTeamsBuildingsReviewMembers } from './pages/generals/teamsBuildingsReview/members.component'
import { ManageMaintain } from './pages/manages/maintain/index.component'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#28AFE1',
    },
  },
})

interface Props {}

const App: React.FC<Props> = () => (
  <ThemeProvider theme={theme}>
    <div className="App min-h-screen">
      <div className="flex items-center min-h-screen h-full">
        <SnackbarProvider>
          <ModalProvider>
            <BrowserRouter>
              <Switch>
                <Route exact path={Paths.SignIn} component={SignIn} />
                <Route exact path={Paths.Restore} component={Restore} />
                <Route exact path={Paths.ResetPassword} component={ResetPassword} />
                <Route exact path={Paths.ForceChangePassword} component={ForceChangePassword} />

                <PrivateRoute>
                  <Sidebar>
                    <Route
                      exact
                      path={Paths.Root}
                      render={() => <Redirect to={Paths.DashBoard} />}
                    />
                    {/* Generals */}
                    <Route exact path={Paths.Users} component={GeneralUsers} />
                    <Route exact path={Paths.UsersEdit} component={GeneralUsersEdit} />
                    <Route exact path={Paths.UsersTeams} component={GeneralUsersTeams} />

                    <Route exact path={Paths.Companies} component={GeneralCompanies} />

                    <Route exact path={Paths.Teams} component={GeneralTeams} />
                    <Route exact path={Paths.TeamsNew} component={GeneralTeamsEdit} />
                    <Route exact path={Paths.TeamsEdit} component={GeneralTeamsEdit} />
                    <Route exact path={Paths.TeamsCsv} component={GeneralTeamsCsv} />

                    <Route exact path={Paths.TeamMembers} component={GeneralTeamsMembers} />
                    <Route exact path={Paths.TeamMembersEdit} component={GeneralTeamsMembersEdit} />
                    <Route exact path={Paths.TeamMembersNew} component={GeneralTeamsMembersEdit} />
                    <Route exact path={Paths.TeamMembersCsv} component={GeneralTeamsMembersCsv} />

                    <Route exact path={Paths.TeamPlans} component={GeneralTeamsPlans} />

                    <Route exact path={Paths.TeamBuildings} component={GeneralTeamsBuildingsEdit} />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActions}
                      component={GeneralTeamsBuildingsActions}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActionsNew}
                      component={GeneralTeamsBuildingsActionsEdit}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActionsEdit}
                      component={GeneralTeamsBuildingsActionsEdit}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActionsDates}
                      component={GeneralTeamsBuildingsActionsDates}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActionsDatesNew}
                      component={GeneralTeamsBuildingsActionsDatesEdit}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsActionsDatesEdit}
                      component={GeneralTeamsBuildingsActionsDatesEdit}
                    />
                    {/* 振り返り */}
                    <Route
                      exact
                      path={Paths.TeamBuildingsReview}
                      component={GeneralTeamsBuildingsReview}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsReviewNew}
                      component={GeneralTeamsBuildingReviewEdit}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsReviewEdit}
                      component={GeneralTeamsBuildingReviewEdit}
                    />
                    <Route
                      exact
                      path={Paths.TeamBuildingsReviewMembers}
                      component={GeneralTeamsBuildingsReviewMembers}
                    />
                    {/* 振り返り --- ここまで */}
                    <Route exact path={Paths.Invoices} component={GeneralInvoices} />
                    <Route exact path={Paths.InvoicesEdit} component={GeneralInvoicesEdit} />

                    {/* Onboardings */}
                    <Route
                      exact
                      path={Paths.OnboardingTeamsDashboard}
                      component={OnboardingTeamsDashboard}
                    />
                    <Route exact path={Paths.OnboardingTeams} component={OnboardingTeams} />
                    <Route exact path={Paths.OnboardingTeamsNew} component={OnboardingTeamsEdit} />
                    <Route exact path={Paths.OnboardingTeamsEdit} component={OnboardingTeamsEdit} />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsActions}
                      component={OnboardingTeamsActions}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsActionsNew}
                      component={OnboardingActionsEdit}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsActionsEdit}
                      component={OnboardingActionsEdit}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingActionsCsv}
                      component={OnboardingActionsCsv}
                    />

                    <Route
                      exact
                      path={Paths.OnboardingTeamsUsers}
                      component={OnboardingTeamsUsers}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsUsersNew}
                      component={OnboardingTeamsUsersEdit}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsUsersEdit}
                      component={OnboardingTeamsUsersEdit}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingTeamsUsersCsv}
                      component={OnboardingTeamsUsersCsv}
                    />

                    {/* 単体 */}
                    <Route exact path={Paths.OnboardingActions} component={OnboardingActions} />
                    <Route
                      exact
                      path={Paths.OnboardingActionsNew}
                      component={OnboardingActionsEdit}
                    />
                    <Route
                      exact
                      path={Paths.OnboardingActionsEdit}
                      component={OnboardingActionsEdit}
                    />
                    {/* Manages */}
                    <Route exact path={Paths.Masters} component={ManageMasters} />
                    <Route exact path={Paths.MastersNew} component={ManageMasterEdit} />
                    <Route exact path={Paths.MastersEdit} component={ManageMasterEdit} />
                    <Route exact path={Paths.MastersCsv} component={ManageMasterCsv} />

                    <Route exact path={Paths.Maintain} component={ManageMaintain} />

                    <Route exact path={Paths.Admins} component={ManageAdmins} />
                    <Route exact path={Paths.AdminsNew} component={ManageAdminEdit} />
                    <Route exact path={Paths.AdminsEdit} component={ManageAdminEdit} />
                  </Sidebar>
                </PrivateRoute>
                {/* <Route path="*" component={NotFoundPage} /> */}
              </Switch>
            </BrowserRouter>
          </ModalProvider>
        </SnackbarProvider>
      </div>
    </div>
  </ThemeProvider>
)

export default App
