import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { get, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import { Panel, Button, DownloadLink, DropZoneUploader } from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { Paths } from '@/constants'
import { useCsv } from '@/hooks/csv'
import { Column, CsvDataList } from '@/layouts/csvList/index.component'
import { createOnboardingAction } from '@/stores/actions/api'
import {
  OnboardingActionCategory,
  OnboardingActionCreateInput,
  OnboardingTeam,
} from '@/stores/graphql'
import { getOnboardingTeam } from '@/stores/teams/api'
import { replacePathParams } from '@/utils/history'

enum CSVStatus {
  processing = 'Processing',
  done = 'Done',
  error = 'Error',
}
interface ITeamState {
  data: OnboardingTeam | null
  fetching: boolean
}

interface CSVOnboardingTeamAction {
  status?: CSVStatus
  error?: string

  teamId: string
  タイトル: string
  目的: string
  アクション: string
  達成基準: string
  カテゴリ: string
  'メンバー参加から何日後に追加しますか？': string
  'アクション追加から何日後を期限としますか？': string
  publishAt: string
  updatedAt: string
  isPublished: boolean
}

type Inputs = {
  id: string
  teamId: string
  mission: string
  category: string
  why: string
  what: string
  how: string
  importance: string
  publishAt: string
  updatedAt: string
  period: string
  isPublished: boolean
}

const hasAllRequiredValue = (data: CSVOnboardingTeamAction) => {
  const {
    タイトル: mission,
    目的: why,
    アクション: what,
    達成基準: how,
    カテゴリ: category,
    'メンバー参加から何日後に追加しますか？': period,
  } = data
  return Boolean(mission && why && what && how && category && period)
}

const categoryNames: { [k: string]: string } = {
  [OnboardingActionCategory.Category1]: 'チーム理解行動',
  [OnboardingActionCategory.Category2]: '関係構築行動',
  [OnboardingActionCategory.Category3]: '社会人自律行動',
  [OnboardingActionCategory.Category4]: '自己成長行動',
  [OnboardingActionCategory.Category5]: '意味づけ行動',
}
const getCategoryByValue = (value: string) => {
  return (
    Object.keys(categoryNames).find((key) => categoryNames[key] === value) ??
    OnboardingActionCategory.Category1
  )
}

const COLUMNS: Column[] = [
  {
    label: '結果',
    // eslint-disable-next-line react/display-name
    field: (record: CSVOnboardingTeamAction) => (
      <div>
        {record.status === CSVStatus.processing ? (
          <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.done ? (
          <FontAwesomeIcon className="text-primary" icon={faCheck} />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.error ? (
          <Tooltip title={record.error || 'エラー'}>
            <div>
              <FontAwesomeIcon className="text-red" icon={faExclamationCircle} />
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    ),
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    label: 'タイトル',
    field: 'タイトル',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '目的',
    field: '目的',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'アクション',
    field: 'アクション',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '達成基準',
    field: '達成基準',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'カテゴリ',
    field: 'カテゴリ',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'メンバー参加から何日後に追加しますか？',
    field: 'メンバー参加から何日後に追加しますか？',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'アクション追加から何日後を期限としますか？',
    field: 'アクション追加から何日後を期限としますか？',
    tdClass: 'whitespace-no-wrap',
  },
]

const CSVTemplate: React.FC = () => (
  <div className="font-bold">
    <DownloadLink
      filePath="/csv-templates/sjis-onboarding-add-action.csv"
      text="CSVテンプレート Windows用（Shift-JIS）"
    />
    <DownloadLink
      filePath="/csv-templates/onboarding-add-action.csv"
      text="CSVテンプレート MacOS用（UTF-8）"
    />
  </div>
)

export const OnboardingActionsCsv: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const history = useHistory()
  const { handleSubmit } = useForm<Inputs>()

  const { openSnackbar } = React.useContext(SnackbarContext)
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [csvData, setCsvData] = React.useState<CSVOnboardingTeamAction[]>([])
  const { onDrop, removeDataAtIndex } = useCsv<CSVOnboardingTeamAction>(csvData, setCsvData)

  const [team, setTeam] = React.useState<ITeamState>({
    data: null,
    fetching: false,
  })

  const getTeam = async (id: string) => {
    setTeam({ data: null, fetching: true })
    const { data: team } = await getOnboardingTeam({ id })
    setTeam({ data: team, fetching: false })
  }

  React.useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])

  const onSubmit = async () => {
    setProcessing(true)
    const t = Array.from(csvData)

    for (const [index, d] of csvData.entries()) {
      t[index].status = CSVStatus.processing
      setCsvData(t)

      if (hasAllRequiredValue(d)) {
        try {
          const input: OnboardingActionCreateInput = {
            id: uuid(),
            teamId: teamId,
            category: getCategoryByValue(d.カテゴリ) as OnboardingActionCategory,
            mission: d.タイトル,
            why: d.目的,
            what: d.アクション,
            how: d.達成基準,
            importance: 0,
            period: Number(d['メンバー参加から何日後に追加しますか？']) || 0,
            deadline: Number(d['アクション追加から何日後を期限としますか？']) || undefined, // deadline is optional
            isPublished: true,
            point: 3, // together with front end csv import
            publishAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }

          const created = await createOnboardingAction(input)

          if (created) {
            t[index].status = CSVStatus.done
            setCsvData(t)
          }
        } catch (e) {
          t[index].status = CSVStatus.error
          t[index].error = get(e, 'errors[0].message', 'エラーが発生しました')
          setCsvData(t)
        }
      } else {
        t[index].status = CSVStatus.error
        t[index].error = '情報が不足しています'

        setCsvData(t)
      }
    }

    t.some((value) => value.status === CSVStatus.error)
      ? openSnackbar({ type: 'error', message: 'エラーが発生しました' })
      : openSnackbar({ type: 'success', message: '処理が完了しました' })
    setProcessing(false)
  }

  return (
    <div className="mt-5 px-10">
      <Breadcrumbs>
        {teamId && (
          <Link to={Paths.OnboardingTeams} className="hover:underline">
            オンボーディングチーム一覧
          </Link>
        )}
        {team.data && (
          <Link
            to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId })}
            className="hover:underline"
          >
            {team.data.title}
          </Link>
        )}
        {teamId && (
          <Link
            to={replacePathParams(Paths.OnboardingTeamsActions, { teamId })}
            className="hover:underline"
          >
            アクション一覧
          </Link>
        )}
        {!teamId && (
          <Link to={Paths.OnboardingActions} className="hover:underline">
            アクション一覧
          </Link>
        )}
        <Typography color="primary">アクションCSV一括追加</Typography>
      </Breadcrumbs>

      <div className="p-8">
        <Panel wrapperFull contentFull>
          <div className="flex justify-end mb-5">
            <CSVTemplate />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DropZoneUploader className="h-32" onDrop={onDrop} type="csv" />

            <div className="mt-4 pt-4">
              {csvData.length > 0 ? (
                <div className="h-full border border-gray-400 p-1 overflow-scroll">
                  <CsvDataList columns={COLUMNS} data={csvData} handleDelete={removeDataAtIndex} />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center text-sm font-bold text-neutral-600">
                  データはありません
                </div>
              )}
            </div>

            {processing ? (
              <div className="flex justify-center mt-10 mb-10">
                <CircularProgress />
              </div>
            ) : (
              <div className="relative flex justify-center mt-10 gap-4">
                <Button
                  className="absolute left-0"
                  buttonType="muted"
                  type="button"
                  fit={true}
                  handleClick={() => history.goBack()}
                >
                  キャンセル
                </Button>
                <Button
                  buttonType="primary"
                  type="submit"
                  fit={false}
                  disabled={csvData.length === 0}
                >
                  インポート
                </Button>
              </div>
            )}
          </form>
        </Panel>
      </div>
    </div>
  )
}
