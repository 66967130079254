import React from 'react'

import { parse } from 'papaparse'
import * as chardet from 'chardet'
import * as iconv from 'iconv-lite'

import { SnackbarContext } from '@/components/Snackbar'

export const useCsv = <T extends unknown>(
  csvData: T[],
  setCsvData: React.Dispatch<React.SetStateAction<T[]>>
) => {
  const { openSnackbar } = React.useContext(SnackbarContext)

  const updateData = (result: any) => {
    const data = result.data as T[]
    setCsvData(data)
  }

  const onDrop = (files: FileList | null) => {
    if (files && files.length > 0) {
      const fileReader = new FileReader()

      fileReader.onload = (e) => {
        if (e && e.target?.result && typeof e.target.result !== 'string') {
          const codes = new Uint8Array(e.target.result)
          const encode = chardet.detect(codes)?.toString()

          if (encode) {
            const decodedString = iconv.decode(Buffer.from(e.target.result), encode)
            parse(decodedString, {
              complete: updateData,
              header: true,
            })
          } else {
            openSnackbar({
              type: 'error',
              message: 'Shift-JIS または UTF-8 の形式の csv をアップロードしてください',
            })
          }
        }
      }

      fileReader.readAsArrayBuffer(files[0])
    }
  }

  const removeDataAtIndex = (index: number) => {
    const targetData = Array.from(csvData)
    targetData.splice(index, 1)
    setCsvData(targetData)
  }

  return { onDrop, removeDataAtIndex }
}
