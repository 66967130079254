import * as React from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from '@/utils/history'

const ITEM_HEIGHT = 48

interface IMoreMenus {
  options: Option[]
}

interface Option {
  title: string
  link?: string
  onClick?: () => void
}

export const MoreMenus: React.FC<IMoreMenus> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { route } = useHistory()

  const onClickItem = ({ link, onClick }: { link?: string; onClick?: () => void }) => {
    if (link) {
      routing(link)
    }

    if (onClick) {
      onClick()
    }
  }

  const routing = (link: string) => {
    route.push(link)
  }

  return (
    <div>
      <div
        className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-primary hover:bg-opacity-10 cursor-pointer"
        onClick={handleClick}
      >
        <FontAwesomeIcon className="text-primary" icon={faEllipsisV} />
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={`link-option-${index}`}
            onClick={() => onClickItem({ link: option.link, onClick: option.onClick })}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
