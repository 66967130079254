import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import 'date-fns'
import { get } from 'lodash-es'
import { faCheck, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Panel,
  Button,
  DownloadLink,
  DropZoneUploader,
  RenderProgress,
  Batch,
} from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { Paths } from '@/constants'
import { useCsv } from '@/hooks/csv'
import { useTeam } from '@/hooks/teams'
import { useTeamMemberManage } from '@/hooks/teamsMembers'
import { Column, CsvDataList } from '@/layouts/csvList/index.component'
import { SelectOption, TeamMemberRole } from '@/stores/graphql'
import { getTeamMemberRoleColor, getTeamMemberRoleLabel } from '@/utils/labels'
import { replacePathParams } from '@/utils/history'

enum CSVStatus {
  processing = 'Processing',
  done = 'Done',
  error = 'Error',
}

interface CSVTeamMember {
  status?: CSVStatus
  error?: string
  fullName: string
  username: string
  organization: string
  position: string
  email: string
  role: TeamMemberRole
}

type Inputs = {
  id: string
  fullName: string
  username: string
  email?: string
  organization: string
  position: string
  role: SelectOption[]
}

const COLUMNS: Column[] = [
  {
    label: '結果',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <div>
        {record.status === CSVStatus.processing ? (
          <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.done ? (
          <FontAwesomeIcon className="text-primary" icon={faCheck} />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.error ? (
          <Tooltip title={record.error || 'エラー'}>
            <div>
              <FontAwesomeIcon className="text-red" icon={faExclamationCircle} />
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    ),
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    label: '氏名',
    field: 'fullName',
  },
  {
    label: 'ユーザー名',
    field: 'username',
  },
  {
    label: '組織',
    field: 'organization',
  },
  {
    label: '役職',
    field: 'position',
  },
  {
    label: 'メールアドレス',
    field: 'email',
  },
  {
    label: '権限',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <Batch
        title={getTeamMemberRoleLabel(record.role)}
        backgroundColor={getTeamMemberRoleColor(record.role)}
      />
    ),
  },
]

const CSVTemplate: React.FC = () => (
  <div>
    <DownloadLink
      filePath="/csv-templates/sjis-add-team-member.csv"
      text="CSVテンプレート Windows用（Shift-JIS）"
    />
    <DownloadLink
      filePath="/csv-templates/add-team-member.csv"
      text="CSVテンプレート MacOS用（UTF-8）"
    />
  </div>
)

export const GeneralTeamsMembersCsv: React.FC = () => {
  const { teamId } = useParams<{ teamMemberId?: string; teamId?: string }>()
  const history = useHistory()
  const { handleSubmit } = useForm<Inputs>()

  const { openSnackbar } = React.useContext(SnackbarContext)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { team } = useTeam(teamId)
  const { createTeamMember } = useTeamMemberManage()

  const [csvData, setCsvData] = React.useState<CSVTeamMember[]>([])
  const { onDrop, removeDataAtIndex } = useCsv<CSVTeamMember>(csvData, setCsvData)

  const onSubmit = async () => {
    if (teamId) {
      setIsProcessing(true)
      const t = Array.from(csvData)

      for (const [index, d] of csvData.entries()) {
        t[index].status = CSVStatus.processing
        setCsvData(t)

        const [created, createdErrors] = await createTeamMember({
          teamId,
          fullName: d.fullName,
          username: d.username,
          email: d.email,
          organization: d.organization,
          position: d.position,
          role: [d.role],
        })

        if (created) {
          t[index].status = CSVStatus.done
          setCsvData(t)
        } else {
          t[index].status = CSVStatus.error
          t[index].error = get(createdErrors, '[0].message', 'エラーが発生しました')
          setCsvData(t)
        }
      }

      t.some((value) => value.status === CSVStatus.error)
        ? openSnackbar({ type: 'error', message: 'エラーが発生しました' })
        : openSnackbar({ type: 'success', message: '処理が完了しました' })
      setIsProcessing(false)
    }
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DropZoneUploader className="h-32" onDrop={onDrop} type="csv" />

      <div className="mt-4 pt-4">
        {csvData.length > 0 ? (
          <div className="h-full border border-gray-400 p-1 overflow-scroll">
            <CsvDataList columns={COLUMNS} data={csvData} handleDelete={removeDataAtIndex} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-sm font-bold text-neutral-600">
            データはありません
          </div>
        )}
      </div>

      {isProcessing ? (
        <div className="flex justify-center mt-10 mb-10">
          <CircularProgress />
        </div>
      ) : (
        <div className="relative flex justify-center mt-10 gap-4">
          <Button
            className="absolute left-0"
            buttonType="muted"
            type="button"
            fit={true}
            handleClick={() => history.goBack()}
          >
            キャンセル
          </Button>
          <Button buttonType="primary" type="submit" fit={false} disabled={csvData.length === 0}>
            インポート
          </Button>
        </div>
      )}
    </form>
  )

  return (
    <div className="p-8">
      {!team ? (
        <RenderProgress />
      ) : (
        <Panel
          wrapperFull
          contentFull
          title={
            <>
              <Link
                className="text-primary underline"
                to={replacePathParams(Paths.TeamMembers, { teamId: team.id })}
              >
                {team.name}
              </Link>
              <span> / チームメンバーCSV一括追加</span>
            </>
          }
          titleRight={<CSVTemplate />}
        >
          {renderForm()}
        </Panel>
      )}
    </div>
  )
}
