import React from 'react'
import { __RouterContext, RouteComponentProps } from 'react-router'
import * as H from 'history'

export const useRouter = <T>(): RouteComponentProps<T> =>
  React.useContext(__RouterContext) as RouteComponentProps<T>

interface PlainObject {
  [key: string]: string | undefined
}

export const replacePathParams = (path: H.Path, pathParams?: PlainObject) => {
  let targetPath = path

  if (pathParams) {
    Object.keys(pathParams).forEach((param) => {
      targetPath = targetPath.replace(`:${param}`, pathParams[param] || '')
    })
  }

  return targetPath
}

export const route = (history: H.History) => ({
  replace: (path: H.Path) => {
    history.replace(path)
    window.scrollTo(0, 0)
  },
  push: (path: H.Path, pathParams?: PlainObject) => {
    history.push(replacePathParams(path, pathParams))
    window.scrollTo(0, 0)
  },
})

export const useHistory = () => {
  const { history } = useRouter()
  const result = React.useMemo(() => ({ route: route(history) }), [history])
  return result
}
