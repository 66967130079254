import React, { useEffect, useState, useContext, useMemo } from 'react'
import { isEqual } from 'lodash-es'
import { SnackbarContext } from '@/components/Snackbar'
import { TypeFilter } from '@/components/atoms'
import { SingleCheckBox } from '@/components/atoms'
import { useParams } from 'react-router-dom'
import { getOnboardingActionList, updateOnboardingAction } from '@/stores/actions/api'
import { getOnboardingTeam } from '@/stores/teams/api'
import { OnboardingTeam, OnboardingAction, OnboardingActionFilter } from '@/stores/graphql'
import { ONBOARDING_ACTION_CATEGORIES, Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import usePrevious from '@/utils/usePrevious'
import { get } from 'lodash-es'
import { replacePathParams } from '@/utils/history'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { mutationDeleteOnboardingAction } from './api'
import { format } from 'date-fns'

interface IRecords {
  data: OnboardingAction[]
  fetching: boolean
}

interface ITeamState {
  data: OnboardingTeam | null
  fetching: boolean
}

export const OnboardingTeamsActions: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  // const history = useHistory()
  const { openSnackbar } = useContext(SnackbarContext)
  const [records, setRecords] = useState<IRecords>({
    data: [],
    fetching: false,
  })
  const [team, setTeam] = useState<ITeamState>({
    data: null,
    fetching: false,
  })
  const [nextToken, setNextToken] = useState<null | string>(null)
  const [filter, setFilter] = useState<OnboardingActionFilter>({})
  const prevFilter = usePrevious(filter)

  const onChangeIsPublished = React.useCallback(
    async (isPublished: boolean, record: OnboardingAction) => {
      await updateOnboardingAction({ id: record.id, isPublished })
      openSnackbar({
        type: 'success',
        message: 'アクションを更新しました',
      })
    },
    [openSnackbar]
  )

  const COLUMNS = useMemo(
    () => [
      {
        label: 'アクションID',
        field: 'id',
      },
      {
        label: '分類',
        field: (record: OnboardingAction) =>
          ONBOARDING_ACTION_CATEGORIES.find(({ id }) => id === record.category)?.name || '',
      },
      {
        label: 'タイトル',
        field: 'mission',
      },
      // {
      //   label: '重要度',
      //   field: 'importance',
      // },
      {
        label: '日数',
        field: 'period',
      },
      {
        label: '期限',
        field: 'deadline',
      },
      {
        label: '利用する',
        // eslint-disable-next-line react/display-name
        field: (record: OnboardingAction) => (
          <SingleCheckBox
            required
            value="active"
            name="isPublished"
            onChangeHandler={(event: any) => onChangeIsPublished(event.target.checked, record)}
            defaultChecked={get(record, 'isPublished', false) ? true : false}
          />
        ),
      },
    ],
    [onChangeIsPublished]
  )

  const getData = async (
    filter: OnboardingActionFilter,
    token: null | string = null,
    replaceRecords = false
  ) => {
    console.log('getData args', { token, filter, replaceRecords })
    setRecords((current: IRecords) => ({
      data: replaceRecords ? [] : [...current.data],
      fetching: true,
    }))

    const { data, nextToken: newNextToken } = await getOnboardingActionList({
      teamId,
      filter,
      after: token,
    })

    console.log('getData', { data, newNextToken })
    setRecords({ data: replaceRecords ? data : [...records.data, ...data], fetching: false })
    setNextToken(newNextToken)
  }

  // const handleDelete = async (id: string) => {
  //   // try {
  //   //   await deleteAdmin({ id })
  //   //   setRecords((current: IRecords) => ({
  //   //     ...current,
  //   //     data: current.data.filter((item: any) => item.id !== id),
  //   //   }))
  //   //   openSnackbar({ type: 'success', message: 'アドミンを削除しました' })
  //   // } catch {
  //   //   openSnackbar({ type: 'error', message: 'エラーが発生しました' })
  //   // }
  // }

  const onApplyCategoryFilter = async (value: any) => {
    const { category = [] } = value
    const hasValue = !!category.length
    const nextFilter = { ...filter }
    if (hasValue) {
      nextFilter.category = [...category]
    } else {
      delete nextFilter.category
    }
    setFilter(nextFilter)
  }

  const onClickReadMore = React.useCallback(async () => {
    getData(filter, nextToken, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, nextToken])

  useEffect(() => {
    const isFilterUpdated = !isEqual(filter, prevFilter)
    // const token = isFilterUpdated ? null : nextToken

    console.log('useEffect [filter]', {
      filter,
      prevFilter,
      isFilterUpdated,
    })

    if (teamId && isFilterUpdated) {
      getData(filter, null, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const getTeam = async (id: string) => {
    setTeam({ data: null, fetching: true })
    const { data: team } = await getOnboardingTeam({ id })
    setTeam({ data: team, fetching: false })
  }

  useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])

  const handleDelete = async (id: string, singleDelete = true) => {
    return await mutationDeleteOnboardingAction({ id }).then((result) => {
      if (singleDelete) {
        openSnackbar({ type: 'success', message: `削除しました` })
        window.location.reload()
      } else {
        return result
      }
    })
  }
  const handleDeleteChecked = async (ids: string[]) => {
    if (window.confirm(`${ids.length}件のアクションを削除します。本当に削除しますか?`)) {
      try {
        const promises = ids.map(async (id) => await handleDelete(id, false))
        Promise.all(promises).then(() => {
          openSnackbar({ type: 'success', message: `${ids.length}件のアクションを削除しました` })
          window.location.reload()
        })
      } catch {
        openSnackbar({ type: 'error', message: '削除に失敗しました' })
      }
    }
  }

  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          <Link to={Paths.OnboardingTeams} className="hover:underline">
            オンボーディングチーム一覧
          </Link>
          {team.data?.title && (
            <Link
              to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId: team.data.id })}
              className="hover:underline"
            >
              {team.data.title}
            </Link>
          )}
          <Typography color="primary">アクション一覧</Typography>
        </Breadcrumbs>
      </div>

      <IndexLayout
        fetching={records.fetching}
        title={`アクション一覧`}
        data={records.data}
        columns={COLUMNS}
        createPath={replacePathParams(Paths.OnboardingTeamsActionsNew, { teamId })}
        csvImportPath={replacePathParams(Paths.OnboardingActionsCsv, { teamId })}
        csvExport={{
          fileName: `${format(new Date(), 'yyyy-MM-dd')}-onboarding-action-list`,
          columns: [
            { header: 'チームID', key: 'teamId' },
            { header: 'アクションID', key: 'id' },
            { header: 'タイトル', key: 'mission' },
            { header: '分類', key: 'category' },
            { header: '概要', key: 'what' },
            { header: '目的', key: 'why' },
            { header: '達成基準', key: 'how' },
            { header: '日数', key: 'period' },
            { header: '期限', key: 'deadline' },
            { header: '公開', key: 'isPublished' },
          ],
          data: records.data,
        }}
        editPath={(id: string) =>
          replacePathParams(Paths.OnboardingTeamsActionsEdit, { teamId, actionId: id })
        }
        handleDelete={handleDelete}
        handleDeleteChecked={handleDeleteChecked}
        onClickReadMore={nextToken ? onClickReadMore : undefined}
        filters={
          <div>
            <div className="flex px-6 mb-4">
              <div className="whitespace-no-wrap">
                <TypeFilter
                  name="分類"
                  field="category"
                  types={ONBOARDING_ACTION_CATEGORIES}
                  handleOnSubmit={onApplyCategoryFilter}
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  )
}
