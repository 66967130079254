import React, { useEffect, useState, useCallback } from 'react'
import { getAdminList } from '@/stores/admins/api'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { OnboardingAdmin } from '@/stores/graphql'
import moment from 'moment'

const COLUMNS = [
  {
    label: 'ID',
    field: 'sub',
  },
  {
    label: 'メールアドレス',
    field: 'email',
  },
  {
    label: '作成日',
    field: (record: OnboardingAdmin) => moment(record.createdAt).format('YYYY/MM/DD HH:mm'),
  },
  {
    label: '更新日',
    field: (record: OnboardingAdmin) => moment(record.updatedAt).format('YYYY/MM/DD HH:mm'),
  },
]

interface IRecords {
  data: OnboardingAdmin[]
  fetching: boolean
}

export const ManageAdmins: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [records, setRecords] = useState<IRecords>({
    data: [],
    fetching: false,
  })
  const [nextToken, setNextToken] = useState<null | string>(null)

  const getData = React.useCallback(
    async (token: null | string = null, replaceRecords = false) => {
      setRecords((current: any) => ({
        data: replaceRecords ? [] : [...current.data],
        fetching: true,
      }))
      const { data, nextToken: newNextToken } = await getAdminList({ after: token })
      console.log('data', { data, newNextToken })
      setRecords({ data: replaceRecords ? data : [...records.data, ...data], fetching: false })
      setNextToken(newNextToken)
    },
    [records.data]
  )

  useEffect(() => {
    getData(null, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickReadMore = useCallback(async () => {
    getData(nextToken, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken])

  return (
    <IndexLayout
      fetching={records.fetching}
      title="管理 - 管理者"
      data={records.data.map((record: OnboardingAdmin) => ({ ...record, id: record.sub }))}
      columns={COLUMNS}
      createPath={Paths.AdminsNew}
      // editPath={Paths.AdminsEdit}
      onClickReadMore={nextToken ? onClickReadMore : undefined}
    />
  )
}
