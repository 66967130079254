import React from 'react'
import { Team, TeamFilter } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout, { Column } from '@/layouts/index/index.component'
import { Batch, MoreMenus, Search } from '@/components/atoms'
import moment from 'moment'
import { replacePathParams } from '@/utils/history'
import { useTeams } from '@/hooks/teams'
import {
  getPaymentMethodLabel,
  getTeamStatusAdminColor,
  getTeamStatusAdminLabel,
  getTeamStatusUsageColor,
  getTeamStatusUsageLabel,
} from '@/utils/labels'

const COLUMNS: Column[] = [
  {
    label: 'ID',
    field: 'id',
  },

  {
    label: '管理者',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <a
        href={replacePathParams(Paths.UsersTeams, { userId: record.ownerUserId })}
        className="text-primary underline cursor-pointer"
      >
        {record.ownerFullName}
      </a>
    ),
  },
  {
    label: 'チーム名',
    field: 'name',
  },
  {
    label: 'メンバー数',
    field: (record: Team) =>
      `${record.countMember?.toLocaleString() || '0'}/${record.userLimit ?? '20'}`,
    thClass: 'text-right',
    tdClass: 'text-right',
  },
  {
    label: '決済方法',
    field: (record: Team) => getPaymentMethodLabel(record.paymentMethod),
  },
  {
    label: '管理者状況',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <Batch
        title={getTeamStatusAdminLabel(record.statusAdmin)}
        backgroundColor={getTeamStatusAdminColor(record.statusAdmin)}
      />
    ),
  },
  {
    label: '利用状況',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <Batch
        title={getTeamStatusUsageLabel(record.statusUsage)}
        backgroundColor={getTeamStatusUsageColor(record.statusUsage)}
      />
    ),
  },
  {
    label: '作成日',
    field: (record: Team) => moment(record.createdAt).format('YYYY-MM-DD'),
  },
]

export const GeneralTeams: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [filter, setFilter] = React.useState<TeamFilter>({})

  const { teams, nextToken, loading, more } = useTeams(filter)

  const onApplySearch = async (value: string, type: 'name' | 'ownerEmail') => {
    const nextFilter = { ...filter }

    switch (type) {
      case 'name':
        if (value) {
          nextFilter.name = value
        } else {
          delete nextFilter.name
        }
        break
      case 'ownerEmail':
        if (value) {
          nextFilter.ownerEmail = value
        } else {
          delete nextFilter.ownerEmail
        }
        break
    }

    setFilter(nextFilter)
  }

  return (
    <IndexLayout
      fetching={loading}
      title="チーム"
      data={teams}
      columns={COLUMNS}
      createPath={replacePathParams(Paths.TeamsNew)}
      csvImportPath={replacePathParams(Paths.TeamsCsv)}
      editPath={(teamId: string) => replacePathParams(Paths.TeamsEdit, { teamId })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
      otherHandlers={(record: Team) => (
        <>
          <MoreMenus
            options={[
              {
                title: 'プラン',
                link: replacePathParams(Paths.TeamPlans, { teamId: record.id }),
              },
              {
                title: 'メンバー一覧',
                link: replacePathParams(Paths.TeamMembers, { teamId: record.id }),
              },
              {
                title: 'ビルディング',
                link: replacePathParams(Paths.TeamBuildings, { teamId: record.id }),
              },
              // {
              //   title: '振り返り',
              //   link: replacePathParams(Paths.TeamBuildingsReview, { teamId: record.id }),
              // },
            ]}
          />
        </>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'name')}
                placeholder="チーム名検索"
              />
            </div>
            <div className="ml-2">
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'ownerEmail')}
                placeholder="メールアドレス検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
