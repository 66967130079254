import 'date-fns'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { CircularProgress, Breadcrumbs, Typography } from '@material-ui/core'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { IAdmin } from '@/stores/admins/interface'
import {
  OnboardingActionCategory,
  OnboardingActionCreateInput,
  OnboardingActionUpdateInput,
  OnboardingTeam,
} from '@/stores/graphql'
import { SnackbarContext } from '@/components/Snackbar'
import {
  createOnboardingAction,
  updateOnboardingAction,
  getOnboardingAction,
} from '@/stores/actions/api'
import { getOnboardingTeamList } from '@/stores/teams/api'
import { Panel, InputText, Button, SingleCheckBox, NativeSelect } from '@/components/atoms'
import { ERRORS, ONBOARDING_ACTION_CATEGORIES, Paths } from '@/constants'
import { get } from 'lodash-es'
import { replacePathParams } from '@/utils/history'

type Inputs = {
  id: string
  mission: string
  teamId: string
  category: string
  why: string
  what: string
  how: string
  // importance: string
  publishAt: string
  updatedAt: string
  period: string
  deadline: string
  isPublished: boolean
}

export const OnboardingActionsEdit: React.FC = () => {
  const { actionId, teamId } = useParams<{ actionId: string; teamId?: string }>()
  const history = useHistory()

  const { openSnackbar } = useContext(SnackbarContext)
  const [record, setRecord] = useState<IAdmin | null | 'create'>(null)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const [teamOptions, setTeamOptions] = useState<{ value: string; label: string }[]>([])

  const getTeamOptions = async () => {
    const { data } = await getOnboardingTeamList({ first: -1, after: null })
    const options = data.map((team: OnboardingTeam) => ({
      value: team.id,
      label: team.title,
    }))
    setTeamOptions([
      {
        value: 'MASTER',
        label: 'MASTER',
      },
      ...options,
    ])
  }

  useEffect(() => {
    getTeamOptions()
  }, [])

  const isFetching = useMemo(() => record === null, [record])
  const isCreate = useMemo(() => record === 'create', [record])

  const fetchRecord = async (id: string) => {
    const fetched = await getOnboardingAction({ id })
    setRecord(fetched.data)
  }

  useEffect(() => {
    if (actionId) {
      fetchRecord(actionId)
    } else {
      setRecord('create')
    }
  }, [actionId])

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: any) => {
    console.log('submit data', data)
    setIsProcessing(true)

    if (isCreate) {
      try {
        const input: OnboardingActionCreateInput = {
          id: uuid(),
          teamId: data.teamId,
          category: data.category as OnboardingActionCategory,
          mission: data.mission,
          why: data.why,
          what: data.what,
          how: data.how,
          importance: data.importance || 0,
          period: data.period,
          deadline: data.deadline || null,
          point: 0, // TODO
          isPublished: true,
          publishAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }

        await createOnboardingAction(input)
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: 'アクションを作成しました' })
        history.push(
          teamId
            ? replacePathParams(Paths.OnboardingTeamsActions, { teamId })
            : Paths.OnboardingActions
        )
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    } else {
      const input: OnboardingActionUpdateInput = {
        id: data.id,
        teamId: data.teamId,
        category: data.category,
        mission: data.mission,
        why: data.why,
        what: data.what,
        how: data.how,
        isPublished: data.isPublished === 'active' ? true : false,
        importance: data.importance || 0,
        period: data.period,
        deadline: data.deadline || null,
      }

      try {
        await updateOnboardingAction(input)
        setIsProcessing(false)
        openSnackbar({ type: 'success', message: 'アクションを編集しました' })
        history.push(
          teamId
            ? replacePathParams(Paths.OnboardingTeamsActions, { teamId })
            : Paths.OnboardingActions
        )
      } catch (error) {
        console.log(error)
        const message = get(error, 'errors[0].message', 'エラーが発生しました')
        openSnackbar({ type: 'error', message })
      }
    }
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress />
    </div>
  )

  const renderForm = () => {
    if (record === null) {
      return null
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isCreate && (
          <div className="mt-6">
            <InputText
              readOnly
              label="ID"
              name="id"
              defaultValue={get(record, 'id')}
              inputRef={register()}
            />
          </div>
        )}

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="タイトル"
            name="mission"
            defaultValue={get(record, 'mission')}
            inputRef={register({
              required: ERRORS.inputRequired('タイトル'),
              maxLength: {
                value: 50,
                message: ERRORS.maxCount(50),
              },
            })}
            error={errors.mission && errors.mission.message}
          />
        </div>

        {/* <div className="mt-6">
          <InputText
            required
            label="チームID"
            name="teamId"
            defaultValue={get(record, 'teamId')}
            inputRef={register({ required: true })}
            error={errors.teamId && ERRORS.inputRequired('チームID')}
          />
        </div> */}

        {teamOptions.length > 0 && (
          <div className="mt-6">
            <NativeSelect
              readOnly={(teamId ?? get(record, 'teamId')) || isProcessing}
              label="チームID"
              name="teamId"
              defaultValue={teamId ?? get(record, 'teamId')}
              options={teamOptions}
              selectRef={register({ required: true })}
            />
          </div>
        )}

        {/* <div className="mt-6">
          <InputText
            required
            label="分類"
            name="category"
            defaultValue={get(record, 'category')}
            inputRef={register({ required: true })}
            error={errors.category && ERRORS.inputRequired('分類')}
          />
        </div> */}

        <div className="mt-6">
          <NativeSelect
            label="分類"
            readOnly={isProcessing}
            name="category"
            defaultValue={get(record, 'category')}
            options={ONBOARDING_ACTION_CATEGORIES.map((category) => ({
              value: category.id as OnboardingActionCategory,
              label: category.name,
            }))}
            selectRef={register({ required: true })}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="概要"
            name="what"
            defaultValue={get(record, 'what')}
            inputRef={register({
              required: ERRORS.inputRequired('概要'),
              maxLength: {
                value: 100,
                message: ERRORS.maxCount(100),
              },
            })}
            error={errors.what && errors.what.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="目的"
            name="why"
            defaultValue={get(record, 'why')}
            inputRef={register({
              required: ERRORS.inputRequired('目的'),
              maxLength: {
                value: 100,
                message: ERRORS.maxCount(100),
              },
            })}
            error={errors.why && errors.why.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="達成基準"
            name="how"
            defaultValue={get(record, 'how')}
            inputRef={register({
              required: ERRORS.inputRequired('達成基準'),
              maxLength: {
                value: 100,
                message: ERRORS.maxCount(100),
              },
            })}
            error={errors.how && errors.how.message}
          />
        </div>

        {/* <div className="mt-6">
          <NativeSelect
            readOnly={isProcessing}
            label="重要度"
            name="importance"
            defaultValue={get(record, 'importance')}
            options={[
              {
                value: '1',
                label: '1',
              },
              {
                value: '2',
                label: '2',
              },
              {
                value: '3',
                label: '3',
              },
              {
                value: '4',
                label: '4',
              },
              {
                value: '5',
                label: '5',
              },
            ]}
            selectRef={register({ required: true })}
          />
        </div> */}

        <div className="mt-6">
          <InputText
            required
            readOnly={isProcessing}
            label="日数"
            name="period"
            defaultValue={get(record, 'period')}
            isAllowedDefault0
            inputRef={register({
              required: ERRORS.inputRequired('日数'),
              pattern: {
                value: /^\d+$/,
                message: ERRORS.inputNumber,
              },
            })}
            error={errors.period && errors.period.message}
          />
        </div>

        <div className="mt-6">
          <InputText
            readOnly={isProcessing}
            label="期限"
            name="deadline"
            defaultValue={get(record, 'deadline')}
            isAllowedDefault0
            inputRef={register({
              pattern: {
                value: /^\d+$/,
                message: ERRORS.inputNumber,
              },
            })}
            error={errors.deadline && errors.deadline.message}
          />
        </div>

        {!isCreate && (
          <div className="mt-6">
            <SingleCheckBox
              required
              readOnly={isProcessing}
              value="active"
              label="公開"
              name="isPublished"
              defaultChecked={get(record, 'isPublished', false) ? true : false}
              inputRef={register()}
            />
          </div>
        )}

        {isProcessing ? (
          <div className="flex justify-center mt-10 mb-10">
            <CircularProgress />
          </div>
        ) : (
          <div className="relative flex justify-center mt-10 gap-4">
            <Button
              className="absolute left-0"
              buttonType="muted"
              type="button"
              fit={true}
              link={
                teamId
                  ? replacePathParams(Paths.OnboardingTeamsActions, { teamId })
                  : Paths.OnboardingActions
              }
            >
              キャンセル
            </Button>
            <Button buttonType="primary" type="submit" fit={false}>
              {isCreate ? '作成' : '更新'}
            </Button>
          </div>
        )}
      </form>
    )
  }

  return (
    <>
      <div className="mt-5 px-10">
        <Breadcrumbs>
          {teamId && (
            <Link to={Paths.OnboardingTeams} className="hover:underline">
              オンボーディングチーム一覧
            </Link>
          )}
          {teamId && (
            <Link
              to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId })}
              className="hover:underline"
            >
              {teamOptions.find((option) => option.value === teamId)?.label}
            </Link>
          )}

          {!teamId && (
            <Link to={Paths.OnboardingActions} className="hover:underline">
              アクション一覧
            </Link>
          )}
          <Typography color="primary">アクション{actionId ? '編集' : '作成'}</Typography>
        </Breadcrumbs>
      </div>
      <div className="p-8">
        <Panel title={`オンボーディング - ${isCreate ? 'アクション作成' : 'アクション編集'}`}>
          {isFetching ? renderProgress() : renderForm()}
        </Panel>
      </div>
    </>
  )
}
