/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { isBoolean } from 'lodash-es'

interface IProps {
  name: string
  value: string
  defaultChecked?: boolean
  checked?: boolean
  readOnly?: boolean
  someChecked?: boolean
  inputRef?: any
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = ({
  name,
  value,
  inputRef,
  defaultChecked,
  checked,
  readOnly = false,
  someChecked = false,
  onChangeHandler = () => {},
}) => {
  return (
    <label
      className="flex items-center py-2 cursor-pointer"
      css={css`
        input + .checkbox {
          .filled {
            display: none;
          }
          .empty {
            display: block;
          }
        }
        input:checked + .checkbox {
          .filled {
            display: block;
          }
          .empty {
            display: none;
          }
        }
      `}
    >
      {isBoolean(defaultChecked) ? (
        <input
          className="hidden"
          type="checkbox"
          name={name}
          value={value}
          onChange={onChangeHandler}
          defaultChecked={defaultChecked}
          ref={inputRef || null}
        />
      ) : (
        <input
          className="hidden"
          type="checkbox"
          name={name}
          value={value}
          onChange={onChangeHandler}
          checked={checked}
          readOnly={readOnly}
          ref={inputRef || null}
        />
      )}
      <span className="checkbox">
        {someChecked ? (
          <FontAwesomeIcon className="block text-lg text-primary" icon={faMinusSquare} />
        ) : (
          <React.Fragment>
            <FontAwesomeIcon className="filled text-lg text-primary" icon={faCheckSquare} />
            <FontAwesomeIcon className="empty text-lg text-neutral-600" icon={faSquare} />
          </React.Fragment>
        )}
      </span>
    </label>
  )
}

export default InputText
