import * as React from 'react'
import FormItem from './_layouts/FormItem'
import classNames from 'classnames'

interface IProps {
  name?: string
  defaultValue?: string | null | number
  isAllowedDefault0?: boolean
  readOnly?: boolean
  disabled?: boolean
  label?: null | string
  placeholder?: string
  error?: null | string
  required?: boolean
  type?: string
  inputRef?: any
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = ({
  name,
  defaultValue,
  isAllowedDefault0,
  readOnly,
  type,
  inputRef,
  error = null,
  label = '',
  required = false,
  disabled = false,
  placeholder = '',
  inputProps = {},
  onChangeHandler,
  onBlurHandler,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <input
        className={classNames([
          'block w-full px-3 py-2 bg-neutral-100 rounded outline-none',
          'border-2 border-transparent',
          !readOnly && 'focus:border-2 focus:border-primary focus:bg-white',
          readOnly && 'text-neutral-500 cursor-default',
          disabled && 'text-neutral-500 cursor-default',
        ])}
        id={name}
        type={type ?? 'text'}
        name={name}
        placeholder={placeholder}
        // required={required ? true : false}
        defaultValue={isAllowedDefault0 ? defaultValue ?? undefined : defaultValue || undefined}
        onChange={(event) => {
          if (typeof onChangeHandler === 'function') {
            onChangeHandler(event)
          }
        }}
        onBlur={(event) => {
          if (typeof onBlurHandler === 'function') {
            onBlurHandler(event)
          }
        }}
        ref={inputRef || null}
        readOnly={readOnly || false}
        disabled={disabled}
        {...inputProps}
      />
    </FormItem>
  )
}

export default InputText
