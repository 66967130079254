import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { faCheck, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { Panel, Button, DownloadLink, DropZoneUploader } from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { Paths } from '@/constants'
import { useCsv } from '@/hooks/csv'
import { Column, CsvDataList } from '@/layouts/csvList/index.component'
import { OnboardingTeam, OnboardingTeamMemberRole } from '@/stores/graphql'
import { getOnboardingTeam } from '@/stores/teams/api'
import { replacePathParams } from '@/utils/history'
import { mutationCreateOnboardingTeamMember } from './api'

interface ITeamState {
  data: OnboardingTeam | null
  fetching: boolean
}

enum CSVStatus {
  processing = 'Processing',
  done = 'Done',
  error = 'Error',
}

interface CSVOnboardingTeamUser {
  status?: CSVStatus
  error?: string

  メールアドレス?: string
  種別?: OnboardingTeamMemberRole
  参加日?: string
}

type Inputs = {
  id: string
  fullName: string
  username: string
  email?: string
  organization: string
  position: string
  // role: SelectOption[]
}

const CSVTemplate: React.FC = () => (
  <div className="font-bold">
    <DownloadLink
      filePath="/csv-templates/sjis-onboarding-add-team-member.csv"
      text="CSVテンプレート Windows用（Shift-JIS）"
    />
    <DownloadLink
      filePath="/csv-templates/onboarding-add-team-member.csv"
      text="CSVテンプレート MacOS用（UTF-8）"
    />
  </div>
)

const COLUMNS: Column[] = [
  {
    label: '結果',
    // eslint-disable-next-line react/display-name
    field: (record: CSVOnboardingTeamUser) => (
      <div>
        {record.status === CSVStatus.processing ? (
          <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.done ? (
          <FontAwesomeIcon className="text-primary" icon={faCheck} />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.error ? (
          <Tooltip title={record.error || 'エラー'}>
            <div>
              <FontAwesomeIcon className="text-red" icon={faExclamationCircle} />
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    ),
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    label: 'メールアドレス',
    field: 'メールアドレス',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '種別',
    field: '種別',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '参加日',
    field: '参加日',
    tdClass: 'whitespace-no-wrap',
  },
]

export const OnboardingTeamsUsersCsv: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const history = useHistory()
  const { openSnackbar } = React.useContext(SnackbarContext)
  const { handleSubmit } = useForm<Inputs>()
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [csvData, setCsvData] = React.useState<CSVOnboardingTeamUser[]>([])
  const { onDrop, removeDataAtIndex } = useCsv<CSVOnboardingTeamUser>(csvData, setCsvData)

  const [team, setTeam] = React.useState<ITeamState>({
    data: null,
    fetching: false,
  })

  const getTeam = async (id: string) => {
    setTeam({ data: null, fetching: true })
    const { data: team } = await getOnboardingTeam({ id })
    setTeam({ data: team, fetching: false })
  }

  React.useEffect(() => {
    if (teamId) {
      getTeam(teamId)
    }
  }, [teamId])

  const onSubmit = async () => {
    setProcessing(true)
    const t = Array.from(csvData)

    for (const [index, d] of csvData.entries()) {
      t[index].status = CSVStatus.processing
      setCsvData(t)

      if (d.メールアドレス && d.種別) {
        try {
          const created = await mutationCreateOnboardingTeamMember({
            input: {
              teamId,
              email: d.メールアドレス,
              role: d.種別,
              startedAt: (d.参加日 ? new Date(d.参加日) : new Date()).toISOString(),
            },
          })

          if (created) {
            t[index].status = CSVStatus.done
            setCsvData(t)
          }
        } catch (e) {
          console.log(e)
          t[index].status = CSVStatus.error
          t[index].error = get(e, 'errors[0].message', 'エラーが発生しました')
          setCsvData(t)
        }
      } else {
        t[index].status = CSVStatus.error
        t[index].error = '情報が不足しています'

        setCsvData(t)
      }
    }

    t.some((value) => value.status === CSVStatus.error)
      ? openSnackbar({ type: 'error', message: 'エラーが発生しました' })
      : openSnackbar({ type: 'success', message: '処理が完了しました' })
    setProcessing(false)
  }

  return (
    <div className="mt-5 px-10">
      <Breadcrumbs>
        <Link to={Paths.OnboardingTeams} className="hover:underline">
          オンボーディングチーム一覧
        </Link>
        {team.data?.title && (
          <Link
            to={replacePathParams(Paths.OnboardingTeamsEdit, { teamId })}
            className="hover:underline"
          >
            {team.data.title}
          </Link>
        )}
        <Link
          to={replacePathParams(Paths.OnboardingTeamsUsers, { teamId })}
          className="hover:underline"
        >
          ユーザー一覧
        </Link>
        <Typography color="primary">ユーザーCSV一括追加</Typography>
      </Breadcrumbs>

      <div className="p-8">
        <Panel wrapperFull contentFull>
          <div className="flex justify-end mb-5">
            <CSVTemplate />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DropZoneUploader className="h-32" onDrop={onDrop} type="csv" />

            <div className="mt-4 pt-4">
              {csvData.length > 0 ? (
                <div className="h-full border border-gray-400 p-1 overflow-scroll">
                  <CsvDataList columns={COLUMNS} data={csvData} handleDelete={removeDataAtIndex} />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center text-sm font-bold text-neutral-600">
                  データはありません
                </div>
              )}
            </div>

            {processing ? (
              <div className="flex justify-center mt-10 mb-10">
                <CircularProgress />
              </div>
            ) : (
              <div className="relative flex justify-center mt-10 gap-4">
                <Button
                  className="absolute left-0"
                  buttonType="muted"
                  type="button"
                  fit={true}
                  handleClick={() => history.goBack()}
                >
                  キャンセル
                </Button>
                <Button
                  buttonType="primary"
                  type="submit"
                  fit={false}
                  disabled={csvData.length === 0}
                >
                  インポート
                </Button>
              </div>
            )}
          </form>
        </Panel>
      </div>
    </div>
  )
}
