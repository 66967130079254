import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import rootReducer, { RootState } from './reducers'
import rootEpic from './epic'
import { ActionType } from 'typesafe-actions'
import actions from './actions'

export type RootStateType = RootState
export type ActionsType = ActionType<typeof actions>

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const epicMiddleware = createEpicMiddleware<ActionsType, ActionsType, RootState>()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function configureStore(initialState?: RootState) {
  const middlewares = [epicMiddleware]
  const enhancer = composeEnhancers(applyMiddleware(...middlewares))
  return createStore(rootReducer, initialState, enhancer)
}

const store = configureStore()

epicMiddleware.run(rootEpic)

export { store, actions }
