import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

const UPLOAD_FILE_MAX_SIZE = 20000000

interface IProps {
  className?: string
  onDrop: any
  type?: 'image' | 'csv'
}

export const DropZoneUploader = React.memo<IProps>(({ className = '', onDrop, type }) => {
  const accept = type === 'csv' ? 'text/csv,' : 'image/jpeg, image/png'
  const typeName = type === 'csv' ? 'CSV' : '画像'
  const typeFile = type === 'csv' ? '.csv' : '.jpegまたは.png'

  const [fileName, setFileName] = React.useState('')

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    // fileRejections,
    rootRef,
  } = useDropzone({
    accept,
    maxSize: UPLOAD_FILE_MAX_SIZE,
    minSize: 0,
    onDrop: (acceptedFiles, rejectedFiles, event) => {
      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].name)
      }
      onDrop(acceptedFiles, rejectedFiles, event)
      rootRef.current?.blur()
    },
  })
  // console.log('fileRejections', fileRejections)
  // const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > UPLOAD_FILE_MAX_SIZE
  const active = isDragActive && !isDragReject
  const rejected = isDragReject

  return (
    <div
      {...getRootProps()}
      className={`
        flex items-center justify-center p-2 border-dashed border-2 rounded-lg text-sm font-bold cursor-pointer text-center 
        transition duration-150 focus:border-primary focus:outline-none
        ${!active && !rejected && 'border-neutral-300 text-neutral-600'}
        ${active && 'border-primary text-primary'}
        ${rejected && 'border-red text-red'}
        file-drop-input ${className}
      `}
    >
      <input {...getInputProps()} />
      <div className="file-drop-input-text">
        {!isDragActive && (
          <>
            <FontAwesomeIcon icon={faFileCsv} className="text-center filled text-xl text-primary" />
            <span className="block text-primary">{typeName}をアップロード</span>
            <span className="block text-xs">ドラッグ＆ドロップで{typeName}をアップロード</span>
            <span className="block">({fileName || typeFile})</span>
          </>
        )}
        {isDragActive && !isDragReject && `${typeName}をドロップ`}
        {isDragReject && '対応していないファイル形式です'}
        {/* {isFileTooLarge && 'サイズが大きすぎます'} */}
      </div>
    </div>
  )
})
