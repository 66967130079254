// tailwind.config.js と合わせる
const colors = {
  primary: '#28AFE1',
  'primary-light': '#E7F8FF',
  'black-sidebar': '#25334F',
  red: '#FF6666',
  green: '#63C146',
  blue: '#4391ED',
  orange: '#FFA747',
  yellow: '#FBD300',
  'neutral-900': '#111111', // header / footer
  'neutral-800': '#333333', // text
  'neutral-700': '#2A2A2A', // footer
  'neutral-650': '#666666', // text-gray TODO: organize colors
  'neutral-600': '#ADB5BD', // icon fill
  'neutral-500': '#B5B5B5', // placeholder
  'neutral-400': '#D6D6D6', // muted button
  'neutral-300': '#EAEDEF', // border
  'neutral-200': '#F7F7FA', // sidebar
  'neutral-100': '#F7F7F7', // bg-body
}

export default colors
