import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { debounce } from 'lodash-es'

interface IProps {
  placeholder?: string
  handleSearch(value: string): void
}

const Panel: React.FC<IProps> = ({ placeholder = '検索', handleSearch }) => {
  const onSearch = debounce((value: string) => {
    handleSearch(value)
  }, 300)

  return (
    <div className="relative h-9">
      <input
        placeholder={placeholder}
        className={classNames([
          'block w-64 h-9 px-4 bg-neutral-100 rounded-full outline-none text-sm',
          'border-2 border-transparent',
          'focus:border-2 focus:border-primary focus:bg-white',
          'cursor-default',
        ])}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value)}
      />
      <FontAwesomeIcon
        className="absolute right-0 inset-y-0 m-auto mr-4 text-neutral-600"
        icon={faSearch}
      />
    </div>
  )
}

export default Panel
