import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { RootStateType } from '@/stores'
import { Actions as UserActions } from '@/stores/user/actions'

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    restore: (
      email: string,
      successCallback: (email: string) => void,
      errorCallback: (error: Error) => void
    ) => dispatch(UserActions.restore.started({ email, successCallback, errorCallback })),
  }
}

const mapStateToProps = (state: RootStateType) => {
  return {
    user: state.user,
  }
}

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type ContainerProps = ConnectedProps<typeof connector>
