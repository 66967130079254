import {
  DiscountType,
  InvoiceStatus,
  MasterType,
  OnboardingTeamMemberRole,
  OnboardingUsageStatus,
  PaymentMethod,
  TeamBuildingActionDateStatus,
  TeamBuildingStatus,
  TeamMemberRole,
  TeamMemberStatusUsage,
  TeamStatusAdmin,
  TeamStatusUsage,
  TeamTool,
} from '@/stores/graphql'

export const getPaymentMethodLabel = (value?: PaymentMethod | string | null): string => {
  switch (value) {
    case PaymentMethod.Card:
      return 'カード'
    case PaymentMethod.Invoice:
      return '請求書'
    case PaymentMethod.None:
      return '未設定'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusAdminLabel = (value?: TeamStatusAdmin | string | null): string => {
  switch (value) {
    case TeamStatusAdmin.Active:
      return '活動中'
    case TeamStatusAdmin.Confirming:
      return '確認中'
    case TeamStatusAdmin.NonActive:
      return '休止中'
    case TeamStatusAdmin.Banned:
      return '禁止中'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusAdminColor = (value?: TeamStatusAdmin | string | null): string => {
  switch (value) {
    case TeamStatusAdmin.Active:
      return '#87ceeb'
    case TeamStatusAdmin.Confirming:
      return '#90ee90'
    case TeamStatusAdmin.NonActive:
      return '#f3f3f3'
    case TeamStatusAdmin.Banned:
      return '#f08080'
    default:
      return '#fff'
  }
}

export const getTeamStatusUsageLabel = (value?: TeamStatusUsage | string): string => {
  switch (value) {
    case TeamStatusUsage.InUse:
      return '活動中'
    case TeamStatusUsage.NonUse:
      return '終了'
    default:
      return value?.toString() || ''
  }
}

export const getTeamStatusUsageColor = (value?: TeamStatusUsage | string): string => {
  switch (value) {
    case TeamStatusUsage.InUse:
      return '#87ceeb'
    case TeamStatusUsage.NonUse:
      return '#f3f3f3'
    default:
      return '#fff'
  }
}

export const getTeamToolLabel = (value?: TeamTool | string): string => {
  switch (value) {
    case TeamTool.Building:
      return 'チームビルディング'
    case TeamTool.Onboarding:
      return 'オンボーディング'
    case TeamTool.Ghost:
      return 'おばけ'
    case TeamTool.Karte:
      return 'カルテ'
    case TeamTool.Meeting:
      return 'ミーティング'
    default:
      return value?.toString() || ''
  }
}

export const getTeamToolColor = (value: TeamTool): string | undefined => {
  switch (value) {
    case TeamTool.Building:
      return '#b2fbc4'
    case TeamTool.Onboarding:
      return '#adcffa'
    case TeamTool.Ghost:
      return '#eea8fa'
    case TeamTool.Karte:
      return '#fad2ad'
  }
}

export const getTeamMemberRoleLabel = (value: TeamMemberRole): string => {
  switch (value) {
    case TeamMemberRole.Admin:
      return '管理者'
    case TeamMemberRole.Leader:
      return 'リーダー'
    case TeamMemberRole.Member:
      return 'メンバー'
  }
}

export const getTeamMemberRoleColor = (value: TeamMemberRole): string => {
  switch (value) {
    case TeamMemberRole.Admin:
      return '#f08080'
    case TeamMemberRole.Leader:
      return '#40e0d0'
    case TeamMemberRole.Member:
      return '#f5f5f5'
  }
}

export const getTeamMemberStatusUsageLabel = (value?: TeamMemberStatusUsage | string): string => {
  switch (value) {
    case TeamMemberStatusUsage.Invited:
      return '招待中'
    case TeamMemberStatusUsage.Active:
      return '利用中'
    case TeamMemberStatusUsage.Stop:
      return '停止中'
    default:
      return value?.toString() || ''
  }
}

export const getTeamMemberStatusUsageColor = (value?: TeamMemberStatusUsage | string): string => {
  switch (value) {
    case TeamMemberStatusUsage.Invited:
      return '#87ceeb'
    case TeamMemberStatusUsage.Active:
      return '#90ee90'
    case TeamMemberStatusUsage.Stop:
      return '#f08080'
    default:
      return '#f3f3f3'
  }
}

export const getOnboardingUsageStatusLabel = (
  value?: OnboardingUsageStatus | string | null
): string => {
  switch (value) {
    case OnboardingUsageStatus.Active:
      return '活動中'
    case OnboardingUsageStatus.Break:
      return '休止中'
    case OnboardingUsageStatus.Stop:
      return '停止中'
    default:
      return '未設定'
  }
}

export const getOnboardingUsageStatusColor = (
  value?: OnboardingUsageStatus | string | null
): string => {
  switch (value) {
    case OnboardingUsageStatus.Active:
      return '#87ceeb'
    case OnboardingUsageStatus.Break:
      return '#90ee90'
    case OnboardingUsageStatus.Stop:
      return '#f08080'
    default:
      return '#f3f3f3'
  }
}

export const getInvoiceStatusLabel = (value?: InvoiceStatus | string): string => {
  switch (value) {
    case InvoiceStatus.Created:
      return '仮発行'
    case InvoiceStatus.Published:
      return '発行済'
    case InvoiceStatus.Confirmed:
      return '確認済'
    case InvoiceStatus.Pending:
      return '保留'
    case InvoiceStatus.Completed:
      return '完了'
    case InvoiceStatus.Success:
      return '成功'
    case InvoiceStatus.Error:
      return 'エラー'
    default:
      return value ?? ''
  }
}

export const getInvoiceStatusColor = (value?: InvoiceStatus | string): string => {
  switch (value) {
    case InvoiceStatus.Created:
      return '#f3f3f3'
    case InvoiceStatus.Published:
      return '#87ceeb'
    //
    default:
      return '#f3f3f3'
  }
}

export const getTeamBuildingStatusLabel = (value: TeamBuildingStatus): string => {
  switch (value) {
    case TeamBuildingStatus.Created:
      return '開始前'
    case TeamBuildingStatus.Completed:
      return '完了'
    default:
      return value
  }
}

export const getMasterTypeLabel = (value?: MasterType | string | null): string => {
  switch (value) {
    case MasterType.TeamPlan:
      return 'チームプラン'
    case MasterType.TeamBuildingQ2Keyword:
      return 'チームビルディングQ2キーワード'
    case MasterType.TeamBuildingQ4Keyword:
      return 'チームビルディングQ4キーワード'
    case MasterType.TeamBuildingQ5Action:
      return 'チームビルディングQ5アクション'
    case MasterType.TeamBuildingReviewIceBreakCategory:
      return '振り返り: アイスブレイク/カテゴリ'
    case MasterType.TeamBuildingReviewIceBreak:
      return '振り返り: アイスブレイク'
    default:
      return value?.toString() || ''
  }
}

export const getTeamBuildingActionDateStatusLabel = (
  value?: TeamBuildingActionDateStatus | string | null
): string => {
  switch (value) {
    case TeamBuildingActionDateStatus.Created:
      return '作成'
    case TeamBuildingActionDateStatus.Completed:
      return '完了'
    default:
      return value?.toString() || ''
  }
}

export const getDiscountTypeLabel = (value?: DiscountType | string | null): string => {
  switch (value) {
    case DiscountType.FirstMonth:
      return '初月無料'
    case DiscountType.TenPercent:
      return '10%割引'
    default:
      return value?.toString() || ''
  }
}

export const getMasterPlanPublicRangeLabel = (value?: number | null): string => {
  switch (value) {
    case -1:
      return '非公開'
    case 0:
      return '限定'
    case 1:
      return '公開'
    default:
      return value?.toString() || ''
  }
}

export const getMaintainPublicRangeLabel = (value?: number | null): string => {
  switch (value) {
    case 0:
      return '公開中'
    case 1:
      return 'メンテナンス中'
    default:
      return value?.toString() || ''
  }
}

export const getOnboardingTeamMemberRoleLabel = (
  value?: OnboardingTeamMemberRole | string
): string => {
  switch (value) {
    case OnboardingTeamMemberRole.Admin:
      return 'トレーナー'
    case OnboardingTeamMemberRole.Supporter:
      return 'サポーター'
    case OnboardingTeamMemberRole.Member:
      return 'メンバー'
    default:
      return value?.toString() || ''
  }
}

export const getOnboardingTeamMemberRoleColor = (
  value?: OnboardingTeamMemberRole | string
): string => {
  switch (value) {
    case OnboardingTeamMemberRole.Admin:
      return '#f08080'
    case OnboardingTeamMemberRole.Supporter:
      return '#40e0d0'
    case OnboardingTeamMemberRole.Member:
      return '#f3f3f3'
    default:
      return '#f3f3f3'
  }
}
