import React, { useContext } from 'react'
import { Team, TeamFilter, TeamMember, TeamMemberStatusUsage } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Batch, Search } from '@/components/atoms'
import moment from 'moment'
import { replacePathParams } from '@/utils/history'
import { useTeam } from '@/hooks/teams'
import { useParams, Link } from 'react-router-dom'
import { useTeamsMembers, useTeamMemberManage } from '@/hooks/teamsMembers'
import {
  getTeamMemberRoleColor,
  getTeamMemberRoleLabel,
  getTeamMemberStatusUsageColor,
  getTeamMemberStatusUsageLabel,
} from '@/utils/labels'
import { SnackbarContext } from '@/components/Snackbar'
import { get } from 'lodash-es'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { mutationDeleteTeamMember } from '@/hooks/graphql'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '利用状況',
    // eslint-disable-next-line react/display-name
    field: (record: TeamMember) => (
      <Batch
        title={getTeamMemberStatusUsageLabel(record.statusUsage)}
        backgroundColor={getTeamMemberStatusUsageColor(record.statusUsage)}
      />
    ),
  },
  {
    label: '氏名',
    field: 'fullName',
  },
  {
    label: 'ユーザー名',
    field: 'username',
  },
  {
    label: '組織',
    field: 'organization',
  },
  {
    label: '役職',
    field: 'position',
  },
  {
    label: 'メールアドレス',
    field: 'email',
  },
  {
    label: '権限',
    // eslint-disable-next-line react/display-name
    field: (record: TeamMember) => (
      <>
        {record.role.sort().map((role, index) => (
          <Batch
            key={`team-member-index-role-${index}-${record.id}`}
            title={getTeamMemberRoleLabel(role)}
            backgroundColor={getTeamMemberRoleColor(role)}
          />
        ))}
      </>
    ),
  },
  {
    label: '作成日',
    // eslint-disable-next-line react/display-name
    field: (record: Team) => (
      <span className="whitespace-no-wrap">{moment(record.createdAt).format('YYYY-MM-DD')}</span>
    ),
  },
]

export const GeneralTeamsMembers: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()

  const { openSnackbar } = useContext(SnackbarContext)
  const [filter, setFilter] = React.useState<TeamFilter>({})

  const { team } = useTeam(teamId)
  const { teamMembers, nextToken, loading, more, refresh } = useTeamsMembers(teamId, filter)
  const { reSendInvitation, errors } = useTeamMemberManage()

  const onApplySearch = async (value: string, type: 'name' | 'ownerEmail') => {
    const nextFilter = { ...filter }

    switch (type) {
      case 'name':
        if (value) {
          nextFilter.name = value
        } else {
          delete nextFilter.name
        }
        break
      case 'ownerEmail':
        if (value) {
          nextFilter.ownerEmail = value
        } else {
          delete nextFilter.ownerEmail
        }
        break
    }

    setFilter(nextFilter)
  }

  const handleReSendInvitation = async (id: string) => {
    const res = await reSendInvitation(id)

    if (res) {
      openSnackbar({ type: 'success', message: '再送しました' })
    }
  }

  const handleDelete = async (id: string) => {
    //
    console.log('id', id)
    await mutationDeleteTeamMember({ id })

    refresh()
  }

  React.useEffect(() => {
    if (errors && errors.length > 0) {
      const message = get(errors, '[0].message', 'エラーが発生しました')
      openSnackbar({ type: 'error', message })
    }
  }, [errors, openSnackbar])

  return (
    <IndexLayout
      fetching={loading}
      title={
        <>
          <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
            チーム一覧
          </Link>
          <span>/{`${team?.name || '-----'}/チームメンバー`}</span>
        </>
      }
      data={teamMembers}
      columns={COLUMNS}
      createPath={team ? replacePathParams(Paths.TeamMembersNew, { teamId: team.id }) : undefined}
      csvImportPath={
        team ? replacePathParams(Paths.TeamMembersCsv, { teamId: team.id }) : undefined
      }
      editPath={(teamMemberId: string) =>
        team
          ? replacePathParams(Paths.TeamMembersEdit, { teamId: team.id, teamMemberId })
          : undefined
      }
      handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
      otherHandlers={(record: TeamMember) => (
        <>
          <span
            className={classNames([
              'underline text-primary whitespace-no-wrap w-6 h-6 flex justify-center items-center hover:bg-primary-light p-1 rounded-full',
              record.statusUsage === TeamMemberStatusUsage.Invited && 'cursor-pointer',
            ])}
            onClick={
              record.statusUsage === TeamMemberStatusUsage.Invited
                ? () => handleReSendInvitation(record.id)
                : undefined
            }
          >
            <FontAwesomeIcon
              className={
                record.statusUsage === TeamMemberStatusUsage.Invited
                  ? 'text-primary'
                  : 'text-gray-500'
              }
              icon={faMailBulk}
            />
          </span>
        </>
      )}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'name')}
                placeholder="氏名検索"
              />
            </div>
            <div className="ml-2">
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'ownerEmail')}
                placeholder="メールアドレス検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
