import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import 'date-fns'
import { get } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { Panel, Button, DownloadLink, DropZoneUploader, Batch } from '@/components/atoms'
import { SnackbarContext } from '@/components/Snackbar'
import { Paths } from '@/constants'
import { useCsv } from '@/hooks/csv'
import { useMasters } from '@/hooks/masters'
import { useTeamManage } from '@/hooks/teams'
import { useTeamMemberManage } from '@/hooks/teamsMembers'
import { Column, CsvDataList } from '@/layouts/csvList/index.component'
import {
  SelectOption,
  Team,
  TeamMemberRole,
  TeamStatusUsage,
  PaymentMethod,
  MasterType,
  Master,
  TeamTool,
} from '@/stores/graphql'
import { replacePathParams } from '@/utils/history'
import {
  getTeamMemberRoleColor,
  getTeamMemberRoleLabel,
  getTeamStatusUsageLabel,
} from '@/utils/labels'

enum CSVStatus {
  processing = 'Processing',
  done = 'Done',
  error = 'Error',
}

interface CSVTeamMember {
  status?: CSVStatus
  error?: string

  adminEmail?: string
  teamName?: string

  teamStatusUsage?: TeamStatusUsage
  teamPlanCodes?: string
  useBuilding?: 'ON' | 'OFF'
  useTBCheck?: 'ON' | 'OFF'
  useKarte?: 'ON' | 'OFF'
  useGhost?: 'ON' | 'OFF'
  useMeeting?: 'ON' | 'OFF'

  fullName: string
  username: string
  organization: string
  position: string
  email: string
  role: TeamMemberRole
}

type Inputs = {
  id: string
  fullName: string
  username: string
  email?: string
  organization: string
  position: string
  role: SelectOption[]
}

const COLUMNS: Column[] = [
  {
    label: '結果',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <div>
        {record.status === CSVStatus.processing ? (
          <FontAwesomeIcon className="text-primary" icon={faSpinner} spin />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.done ? (
          <FontAwesomeIcon className="text-primary" icon={faCheck} />
        ) : (
          <></>
        )}
        {record.status === CSVStatus.error ? (
          <Tooltip title={record.error || 'エラー'}>
            <div>
              <FontAwesomeIcon className="text-red" icon={faExclamationCircle} />
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    ),
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    label: '管理者メールアドレス',
    field: 'adminEmail',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'チーム名',
    field: 'teamName',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '利用状況',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <Batch
        title={getTeamStatusUsageLabel(record.teamStatusUsage)}
        // backgroundColor={getTeamStatusUsage(record.teamStatusUsage)}
      />
    ),
  },
  {
    label: 'プラン',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <>
        {record.teamPlanCodes?.split(',').map((code, index) => (
          <Batch
            key={`general-teams-csv-column-teamPlanCodes-${index}`}
            title={getTeamStatusUsageLabel(code)}
            // backgroundColor={getTeamStatusUsage(record.teamStatusUsage)}
          />
        ))}
      </>
    ),
  },
  {
    label: 'キックオフ',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useBuilding)} />,
  },
  {
    label: 'TBチェック',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useTBCheck)} />,
  },
  {
    label: 'カルテ',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useKarte)} />,
  },
  {
    label: 'TBチェック',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useTBCheck)} />,
  },
  {
    label: 'オバケ',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useGhost)} />,
  },
  {
    label: 'ミーティング',
    tdClass: 'whitespace-no-wrap',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => <Batch title={getTeamStatusUsageLabel(record.useMeeting)} />,
  },
  {
    label: '氏名',
    field: 'fullName',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'ユーザー名',
    field: 'username',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '組織',
    field: 'organization',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '役職',
    field: 'position',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: 'メールアドレス',
    field: 'email',
    tdClass: 'whitespace-no-wrap',
  },
  {
    label: '権限',
    // eslint-disable-next-line react/display-name
    field: (record: CSVTeamMember) => (
      <Batch
        title={getTeamMemberRoleLabel(record.role)}
        backgroundColor={getTeamMemberRoleColor(record.role)}
      />
    ),
  },
]
const getTeamToolCodes = (d: CSVTeamMember) => {
  const result = []
  if (d.useBuilding === 'ON') {
    result.push(TeamTool.Building)
  }
  if (d.useTBCheck === 'ON') {
    result.push(TeamTool.BuildingCheck)
  }
  if (d.useKarte === 'ON') {
    result.push(TeamTool.Karte)
  }
  if (d.useGhost === 'ON') {
    result.push(TeamTool.Ghost)
  }
  if (d.useMeeting === 'ON') {
    result.push(TeamTool.Meeting)
  }

  return result
}

const CSVTemplate: React.FC = () => (
  <div>
    <DownloadLink
      filePath="/csv-templates/sjis-add-team.csv"
      text="CSVテンプレート Windows用（Shift-JIS）"
    />
    <DownloadLink filePath="/csv-templates/add-team.csv" text="CSVテンプレート MacOS用（UTF-8）" />
  </div>
)

export const GeneralTeamsCsv: React.FC = () => {
  const history = useHistory()
  const { handleSubmit } = useForm<Inputs>()

  const { openSnackbar } = React.useContext(SnackbarContext)
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)

  const { masters: teamPlans } = useMasters(MasterType.TeamPlan)
  const teamTools = React.useMemo(() => Object.entries(TeamTool).map(([, value]) => value), [])
  const { createTeam } = useTeamManage()
  const { createTeamMember } = useTeamMemberManage()

  const [csvData, setCsvData] = React.useState<CSVTeamMember[]>([])
  const { onDrop, removeDataAtIndex } = useCsv<CSVTeamMember>(csvData, setCsvData)

  const onSubmit = async () => {
    setIsProcessing(true)

    const t = Array.from(csvData)
    let team: Team | null = null

    for (const [index, d] of csvData.entries()) {
      t[index].status = CSVStatus.processing
      setCsvData(t)

      if (d.adminEmail && d.teamName && d.teamStatusUsage) {
        const [created, createdErrors] = await createTeam({
          email: d.adminEmail,
          name: d.teamName,
          statusUsage: d.teamStatusUsage,
          paymentMethod: PaymentMethod.Invoice,
          teamPlanIds: getTeamPlanIdsByCodes(teamPlans, d.teamPlanCodes?.split(', ') || []),
          teamToolIds: getTeamToolIdsByCodes(teamTools, getTeamToolCodes(d) || []),
        })

        if (created) {
          t[index].status = CSVStatus.done
          setCsvData(t)
          team = created
        } else {
          t[index].status = CSVStatus.error
          t[index].error = get(createdErrors, '[0].message', 'エラーが発生しました')
          setCsvData(t)
          team = null
        }
      } else {
        if (team) {
          const [created, createdErrors] = await createTeamMember({
            teamId: team.id,
            fullName: d.fullName,
            username: d.username,
            email: d.email,
            organization: d.organization,
            position: d.position,
            role: [d.role],
          })

          if (created) {
            t[index].status = CSVStatus.done
            setCsvData(t)
          } else {
            t[index].status = CSVStatus.error
            t[index].error = get(createdErrors, '[0].message', 'エラーが発生しました')
            setCsvData(t)
          }
        } else {
          t[index].status = CSVStatus.error
          t[index].error = 'チーム情報が不足しています'

          setCsvData(t)
        }
      }
    }
    t.some((value) => value.status === CSVStatus.error)
      ? openSnackbar({ type: 'error', message: 'エラーが発生しました' })
      : openSnackbar({ type: 'success', message: '処理が完了しました' })
    setIsProcessing(false)
  }

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DropZoneUploader className="h-32" onDrop={onDrop} type="csv" />

      <div className="mt-4 pt-4">
        {csvData.length > 0 ? (
          <div className="h-full border border-gray-400 p-1 overflow-scroll">
            <CsvDataList columns={COLUMNS} data={csvData} handleDelete={removeDataAtIndex} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center text-sm font-bold text-neutral-600">
            データはありません
          </div>
        )}
      </div>

      {isProcessing ? (
        <div className="flex justify-center mt-10 mb-10">
          <CircularProgress />
        </div>
      ) : (
        <div className="relative flex justify-center mt-10 gap-4">
          <Button
            className="absolute left-0"
            buttonType="muted"
            type="button"
            fit={true}
            handleClick={() => history.goBack()}
          >
            キャンセル
          </Button>
          <Button buttonType="primary" type="submit" fit={false} disabled={csvData.length === 0}>
            インポート
          </Button>
        </div>
      )}
    </form>
  )

  return (
    <div className="p-8">
      <Panel
        wrapperFull
        contentFull
        title={
          <>
            <Link className="text-primary underline" to={replacePathParams(Paths.Teams)}>
              チーム一覧
            </Link>
            <span> / チームCSV一括追加</span>
          </>
        }
        titleRight={<CSVTemplate />}
      >
        {renderForm()}
      </Panel>
    </div>
  )
}

const getTeamPlanIdsByCodes = (plans: Master[], code: string[]): string[] => {
  const result = []

  for (const c of code) {
    for (const p of plans) {
      if (p.code === c) {
        result.push(p.id)
      }
    }
  }

  return result
}

const getTeamToolIdsByCodes = (tools: string[], code: string[]): string[] => {
  const result = []

  for (const c of code) {
    for (const p of tools) {
      if (p === c) {
        result.push(p)
      }
    }
  }
  console.log(result)
  return result
}
