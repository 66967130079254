import * as React from 'react'

interface Props {
  title?: string
  backgroundColor?: string
}

export const Batch: React.FC<Props> = ({ title, backgroundColor = '#f3f3f3' }) => {
  return (
    <>
      {title ? (
        <div>
          <span
            className={
              'inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none rounded-xlg whitespace-no-wrap'
            }
            style={{
              backgroundColor,
            }}
          >
            {title}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
