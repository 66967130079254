import React from 'react'
import { AdminCreateTeamInput, AdminUpdateTeamInput, Team, TeamFilter } from '@/stores/graphql'
import {
  mutationCreateTeam,
  mutationUpdateTeam,
  queryGetTeam,
  queryGetTeams,
  queryGetTeamsByEmail,
} from './graphql'

export const useTeams = (filter?: TeamFilter) => {
  const limit = 1000
  const [teams, setTeams] = React.useState<Team[]>([])
  const [nextToken, setNextToken] = React.useState<string | null | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeams({ filter, limit })
      console.log('response', response)
      if (response) {
        setTeams(response.items)
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [filter])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const more = React.useCallback(async () => {
    if (!nextToken) {
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeams({ filter, limit, nextToken })
      if (response) {
        setTeams([...teams, ...response.items])
        setNextToken(response.nextToken)
      }
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teams, filter, nextToken])

  return { teams, nextToken, loading, error, refresh, more } as const
}

export const useTeamsByEmail = (email: string | undefined) => {
  const [teams, setTeams] = React.useState<Team[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const refresh = React.useCallback(async () => {
    setLoading(true)
    try {
      if (!email) {
        return
      }

      const response = await queryGetTeamsByEmail({ email })
      if (response) {
        setTeams(response.items)
      }
    } catch (e) {
      console.log('e', e)
    }
    setLoading(false)
  }, [email])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  return { teams, loading } as const
}

export const useTeam = (teamId?: string) => {
  const [team, setTeam] = React.useState<Team | undefined>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | undefined>()

  const refresh = React.useCallback(async () => {
    if (!teamId) {
      setLoading(false)
      return
    }
    setLoading(true)
    setError(undefined)
    try {
      const response = await queryGetTeam({ id: teamId })
      setTeam(response)
    } catch (e) {
      console.log('e', e)
      setError(e)
    }
    setLoading(false)
  }, [teamId])

  React.useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { team, loading, error, refresh } as const
}

export const useTeamManage = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errors, setErrors] = React.useState<Error[] | undefined>()

  const createTeam = React.useCallback(async (input: AdminCreateTeamInput): Promise<
    [Team | undefined, undefined | Error[]]
  > => {
    setLoading(true)
    try {
      const response = await mutationCreateTeam({ input })
      setLoading(false)

      return [response, undefined]
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors)
      setLoading(false)

      return [undefined, e.errors]
    }
  }, [])

  const updateTeam = React.useCallback(async (input: AdminUpdateTeamInput) => {
    setLoading(true)
    try {
      const response = await mutationUpdateTeam({ input })
      return response
    } catch (e) {
      console.log('e', e)
      setErrors(e)
    }
    setLoading(false)
  }, [])

  return {
    createTeam,
    updateTeam,
    errors,
    loading,
  } as const
}
