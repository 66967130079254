import React, { useState } from 'react'
import { Invoice, UserFilter } from '@/stores/graphql'
import { Paths } from '@/constants'
import IndexLayout from '@/layouts/index/index.component'
import { Batch, Search } from '@/components/atoms'
import { replacePathParams } from '@/utils/history'
import { useInvoices } from '@/hooks/invoices'
import { getInvoiceStatusColor, getInvoiceStatusLabel } from '@/utils/labels'

const COLUMNS = [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: '請求状況',
    // eslint-disable-next-line react/display-name
    field: (record: Invoice) => (
      <Batch
        title={getInvoiceStatusLabel(record.status)}
        backgroundColor={getInvoiceStatusColor(record.status)}
      />
    ),
  },
  {
    label: '請求額',
    field: (record: Invoice) => record.amount?.toLocaleString() || '0',
    thClass: 'text-right',
    tdClass: 'text-right',
  },
  {
    label: '価格',
    field: (record: Invoice) => record.cost?.toLocaleString() || '0',
    thClass: 'text-right',
    tdClass: 'text-right',
  },
  {
    label: '割引',
    field: (record: Invoice) => record.discount?.toLocaleString() || '0',
    thClass: 'text-right',
    tdClass: 'text-right',
  },
  {
    label: '法人名',
    field: (record: Invoice) => record.user?.companyName,
  },
  // {
  //   label: '利用開始日',
  //   field: (record: Invoice) => record.user?.create,
  // },
  {
    label: '氏名',
    field: 'userFullName',
  },
  {
    label: 'メールアドレス',
    field: 'userEmail',
  },
  {
    label: '電話番号',
    field: 'userPhone',
  },
]

export const GeneralInvoices: React.FC = () => {
  // const { openSnackbar } = useContext(SnackbarContext)
  const [filter, setFilter] = useState<UserFilter>({})

  const { invoices, nextToken, loading, more } = useInvoices(filter)

  const onApplySearch = async (value: string, type: 'name' | 'email') => {
    const nextFilter = { ...filter }

    switch (type) {
      case 'name':
        if (value) {
          nextFilter.name = value
        } else {
          delete nextFilter.name
        }
        break
      case 'email':
        if (value) {
          nextFilter.email = value
        } else {
          delete nextFilter.email
        }
        break
    }

    setFilter(nextFilter)
  }

  return (
    <IndexLayout
      fetching={loading}
      title="請求情報"
      data={invoices.filter((i) => i.amount > 0)}
      columns={COLUMNS}
      // createPath={Paths.OnboardingTeamsNew}
      editPath={(invoiceId: string) => replacePathParams(Paths.InvoicesEdit, { invoiceId })}
      // handleDelete={handleDelete}
      onClickReadMore={nextToken ? more : undefined}
      filters={
        <div>
          <div className="flex px-6 mb-4">
            <div>
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'name')}
                placeholder="ユーザー名検索"
              />
            </div>
            <div className="ml-2">
              <Search
                handleSearch={(value: string) => onApplySearch(value, 'email')}
                placeholder="メールアドレス検索"
              />
            </div>
          </div>
        </div>
      }
    />
  )
}
