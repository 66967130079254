import * as React from 'react'

const IconClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="#adb5bd" strokeLinecap="round" strokeWidth="2">
      <path d="m2 15.435 13.435-13.435" />
      <path d="m2 2 13.435 13.435" />
    </g>
  </svg>
)

export default IconClose
