import { API, GraphQLResult } from '@aws-amplify/api'
import {
  MutationCreateOnboardingTeamMemberArgs,
  MutationDeleteOnboardingTeamMemberArgs,
  MutationUpdateOnboardingTeamMemberArgs,
  OnboardingTeamMember,
  OnboardingTeamMemberConnection,
  QueryGetOnboardingTeamMemberArgs,
  QueryGetOnboardingTeamMemberListArgs,
} from '@/stores/graphql'
import gql from 'graphql-tag'

export const queryOnboardingTeamMemberList = async (
  args: QueryGetOnboardingTeamMemberListArgs
): Promise<OnboardingTeamMemberConnection | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getOnboardingTeamMemberList($teamId: ID!, $first: Int, $after: String) {
        getOnboardingTeamMemberList(teamId: $teamId, first: $first, after: $after) {
          items {
            id
            email
            role
            startedAt
            user {
              email
            }
          }
          nextToken
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getOnboardingTeamMemberList: OnboardingTeamMemberConnection }>

  return response?.data?.getOnboardingTeamMemberList
}

export const queryOnboardingTeamMember = async (
  args: QueryGetOnboardingTeamMemberArgs
): Promise<OnboardingTeamMember | undefined> => {
  const response = (await API.graphql({
    query: gql`
      query getOnboardingTeamMember($teamId: ID!, $userId: ID) {
        getOnboardingTeamMember(teamId: $teamId, userId: $userId) {
          id
          email
          role
          startedAt
          user {
            email
          }
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ getOnboardingTeamMember: OnboardingTeamMember }>

  return response?.data?.getOnboardingTeamMember
}

export const mutationCreateOnboardingTeamMember = async (
  args: MutationCreateOnboardingTeamMemberArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation createOnboardingTeamMember($input: OnboardingTeamMemberCreateInput!) {
        createOnboardingTeamMember(input: $input) {
          id
          email
          role
          startedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ createOnboardingTeamMember: OnboardingTeamMember }>

  return response?.data?.createOnboardingTeamMember
}

export const mutationUpdateOnboardingTeamMember = async (
  args: MutationUpdateOnboardingTeamMemberArgs
) => {
  console.log('aaa', args)
  const response = (await API.graphql({
    query: gql`
      mutation updateOnboardingTeamMember($input: OnboardingTeamMemberUpdateInput!, $userId: ID) {
        updateOnboardingTeamMember(input: $input, userId: $userId) {
          id
          email
          role
          startedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ updateOnboardingTeamMember: OnboardingTeamMember }>

  return response?.data?.updateOnboardingTeamMember
}

export const mutationDeleteOnboardingTeamMember = async (
  args: MutationDeleteOnboardingTeamMemberArgs
) => {
  const response = (await API.graphql({
    query: gql`
      mutation deleteOnboardingTeamMember($teamId: ID!, $userId: ID!) {
        deleteOnboardingTeamMember(teamId: $teamId, userId: $userId) {
          id
          email
          role
          startedAt
        }
      }
    `,
    variables: { ...args },
  })) as GraphQLResult<{ deleteOnboardingTeamMember: OnboardingTeamMember }>

  return {
    data: { ...response?.data?.deleteOnboardingTeamMember },
  }
}
