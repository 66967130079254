/* eslint-disable no-unused-vars */
import { actionCreatorFactory } from 'typescript-fsa'
import { SIGN_IN, SIGN_OUT, GET_CURRENT_USER, UPDATE_CURRENT_USER } from './types'
import {
  SignInActionInput,
  SignInActionOutput,
  SignOutActionInput,
  getCurrentUserOutput,
  UpdateCurrentUserInput,
  IResetPassword,
} from './interface'

const actionCreator = actionCreatorFactory('USER')

interface Empty {}

export const Actions = {
  signIn: actionCreator.async<SignInActionInput, SignInActionOutput>(SIGN_IN),

  signOut: actionCreator.async<SignOutActionInput, Empty>(SIGN_OUT),
  getCurrentUser: actionCreator.async<Empty, getCurrentUserOutput>(GET_CURRENT_USER),
  updateCurrentUser: actionCreator.async<UpdateCurrentUserInput, Empty>(UPDATE_CURRENT_USER),
  restore: actionCreator.async<
    {
      email: string
      successCallback: (email: string) => void
      errorCallback: (error: Error) => void
    },
    Empty
  >('RESTORE'),
  resetPassword: actionCreator.async<
    {
      data: IResetPassword
      successCallback: (email: string) => void
      errorCallback: (error: Error) => void
    },
    Empty
  >('RESET_PASSWORD'),
}
