import React, { useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { SnackbarContext } from '@/components/Snackbar'
import { Panel, InputText, Button } from '@/components/atoms'
import { useForm } from 'react-hook-form'
import { connector, ContainerProps } from './resetPassword.container'
import { IResetPassword } from '@/stores/user/interface'

import { Auth } from 'aws-amplify'
import { Paths } from '@/constants'

import { get } from 'lodash'

type Props = RouteComponentProps & ContainerProps
const ResetPassword: React.FC<Props> = (props) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const [processing, setProcessing] = React.useState(false)
  const [errorMsg] = React.useState<string | null>(null)

  const { register, handleSubmit, errors } = useForm<IResetPassword>()
  const onSubmit = async (data: IResetPassword) => {
    setProcessing(true)
    const email = get(props.user, 'cognitoUser.challengeParam.userAttributes.email', '')

    try {
      console.log('ARGS', props.user.cognitoUser, data.password, { email })
      await Auth.completeNewPassword(props.user.cognitoUser, data.password, {
        email,
        nickname: 'rytfksm',
      })

      setProcessing(false)
      props.history.push(Paths.DashBoard)
      openSnackbar({ type: 'success', message: 'パスワードを変更しました' })
    } catch (e) {
      console.log(e)
      setProcessing(false)
    }
  }

  return (
    <Panel className="w-104">
      <h1 className="text-2xl text-center">パスワードを変更</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <InputText
            defaultValue=""
            name="password"
            inputRef={register({
              required: '新しいパスワードが入力されていません',
              pattern: {
                value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                message: '半角英数字8〜24文字で入力してください',
              },
            })}
            label="新しいパスワード"
            type="password"
            error={errors.password ? errors.password.message : null}
          />
        </div>

        {errorMsg && (
          <div style={{ padding: '16px 16px 0', fontSize: 12, color: 'red' }}>{errorMsg}</div>
        )}

        <div className="mt-12">
          <Button full type="submit" buttonType="primary" disabled={processing}>
            変更
          </Button>
        </div>
      </form>
    </Panel>
  )
}

export default withRouter(connector(ResetPassword))
